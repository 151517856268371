import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageAlertHttp = (props) => {
    
    const { formError,
            setFormError } = props;
    
    const handleClose = () => {
      setFormError({status: false, severity: "", message: ""});
    }

  return (
    <Snackbar 
      open={formError.status} 
      autoHideDuration={6000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={formError.severity} sx={{ width: '100%' }}>
        {formError.message}
      </Alert>
    </Snackbar>
  );
}

export default MessageAlertHttp;