import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import { IoMdRefresh } from "react-icons/io";
                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 400px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                display: flex;
                font-size: 45px;
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            p.aclaration{
                margin: 10px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
    }
`

const ActivateElement = (props) => {

    const { panColors } = useContext(ConfigurationContext);
    const { statusBenefitRedeem } = useContext(ContentContext);

    const { id,
            open, 
            setOpen,
            title,
            setIsModerated,
            statusToChange, } = props;
    
    const [ isLoading, setIsLoading ] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitToModerate = () => {
        let payload = {};
        let response;

        payload = {
            benefit_id: id,
            status: statusToChange
        };
        
        setIsLoading(true);
        response = statusBenefitRedeem(payload);
        response.then((res) => {
            setIsModerated(true);
        })
    }

    useEffect(() => {
      return () => {
        if(!open){
            setIsLoading(false);
        }
      }
    }, [open])
    

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="xs"
                pancolors={panColors}
            >
                <div className="inner_dialog">
                
                    
                    <div className="header_dialog">
                        <div className="icon">
                            <IoMdRefresh />
                        </div>
                        <p className="title" dangerouslySetInnerHTML={{__html:title}}></p>
                        <p className="aclaration">Esta acción no puede deshacerse.</p>
                    </div>

                    {isLoading ? 
                        <div className='loader'>
                            <LoaderComponent />
                        </div>
                    :
                        <div className="actions_dialog">
                            <ButtonCustom 
                                className="bt_submit"
                                text="Activar"
                                onClick={handleSubmitToModerate}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.dots, hover: panColors.white }}
                                border={{ normal: panColors.dots, hover: panColors.dots }}
                                textcolor={{ normal: panColors.white, hover: panColors.dots }}
                            />
                            <ButtonCustom 
                                className="bt_cancel"
                                text="Cancelar"
                                onClick={() => setOpen(false)}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                            />
                        </div>
                    }
                </div>
            </DialogStyled>
    );
}


export default ActivateElement;