import React from "react";
import AuditsContainer from "./AuditsContainer";

const Audits = (props) => {
  return (
      <AuditsContainer {...props} />
  );
};

export default Audits;
