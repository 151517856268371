import React, { useContext, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { useNavigate } from 'react-router-dom';

import { HiMagnifyingGlass } from "react-icons/hi2";


import ScheduleDateTimeRangeReporting from '../../components/elements/Dialogs/ScheduleDateTimeRangeReporting';
import LoaderComponent from '../../components/elements/LoaderComponent';
import UsersTable from '../../components/Users/UsersTable';
import ButtonCustom from '../../components/elements/ButtonCustom';
import InputCustom from '../../components/elements/InputCustom';
import SelectCustom from '../../components/elements/SelectCustom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { replaceSpecialCharacters,
         isJSON } from '../../helpers/functions';

dayjs.extend(utc);
dayjs.extend(timezone);

const WrapperStyled = styled.div`
    width: 100%;
    
  >.actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 35px;
    .field_country_report{
        margin-left: 10px;
    }
    .bt_create_user{
        margin-right: 10px;
    }
    .bt_export_user,
    .bt_export{
    }
    .loader_export{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width: 50px;
        background-color: ${props => `rgb(${props.pancolors.white})`};;
        border: 1px solid ${props => `rgb(${props.pancolors.dots})`};
        border-radius: 4px;
        margin-left: 10px;
        svg{
            color: ${props => `rgb(${props.pancolors.dots})`};
        }
    }
  }
  >.filter_actions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .field_keywords{
        width: 100%;
    }
    .search{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .filter_option{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;
        .label_title{
            width: 70px;
            font-size: 15px;
            margin-right: 0;
            margin-left: 15px;
            white-space: nowrap;
            &.order_by{
                width: 85px;
            }
        }
        .bt_send_filter{
            margin-left: 10px;
        }

        .field_alphabetical_order,
        .field_date_order,
        .field_status,
        .field_profile,
        .field_order_by{
            width: 145px;
            margin-left: 10px;
        }
        .field_area{
            margin-left: 10px;
        }
        .field_role{
            margin-left: 10px;
        }
        .field_region{
            margin-left: 10px;
        }
        .field_profile,
        .field_order_by{
            width: 220px;
        }
    }
    .bt_filter{
        align-self: flex-end;
        margin-left: 10px;
    }
  }
  >.content{
    margin-top: 20px;
  }
`

export default function EnhancedTable() {

    const navigate = useNavigate();

    const { basicData,
            exports_bq } = useContext(ContentContext);

    const { panColors,
            clientData } = useContext(ConfigurationContext);
    
    const [ orderBy, setOrderBy ] = useState("date|-1");
    const [ alphabeticalOrder, setAlphabeticalOrder ] = useState("0");
    const [ dateOrder, setDateOrder ] = useState("0");
    const [ status, setStatus ] = useState("0");
    const [ profile, setProfile ] = useState(null);
    const [ area, setArea ] = useState(null);
    const [ role, setRole ] = useState(null);
    const [ region, setRegion ] = useState(null);
    const [ profileList, setProfileList ] = useState([]);
    const [ areas, setAreas ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    //const [ filters, setFilters ] = useState({alphabetical_order: 0, date_order: -1, profile: "", text_filter: "", status: 0});
    const [ filters, setFilters ] = useState(null);
    const [ loadingReport, setLoadingReport ] = useState(false);
    const [ reportCode, setReportCode ] = useState('');

    const [ keywords, setKeywords ] = useState({ value: "", 
                                                 required: false, 
                                                 disabled: false, 
                                                 ref: "keywords", 
                                                 label: "Palabra / Frase Clave", 
                                                 placeholder: "Buscar....",
                                                 help: "",
                                                 error: false})

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const alphabeticalOrderRef = React.useRef("0");
    const dateOrderRef = React.useRef(dateOrder);
    const statusRef = React.useRef(status);
    const profileRef = React.useRef(profile);
    const areaRef = React.useRef(area); 
    const roleRef = React.useRef(role); 
    const regionRef = React.useRef(region);

    const keywordsRef = React.useRef(keywords);
                                       
    const alphabeticalDateOrderList = [{_id: "alphabetical|1", name: "Apellido ascendente A-Z"}, 
                                       {_id: "alphabetical|-1", name: "Apellido descendente Z-A"}, 
                                       {_id: "date|-1", name: "Fecha más reciente"}, 
                                       {_id: "date|1", name: "Fecha más antigua"}];
                                       
    const statusList = [{_id: "0", name: "Activos"}, {_id: "-2", name: clientData.name_clients === "one_sp" ? "Licenciados" : "Inactivos"}];
    const [ openScheduleDateTimeRangeReporting, setOpenScheduleDateTimeRangeReporting ] = useState(false);
    const [ endDate, setEndDate] = useState('');
    const [ startDate, setStartDate] = useState('');
    const [ timezone, setTimezone ] = useState(dayjs.tz.guess());
    
    useEffect(() => {
        if(basicData && basicData.user_profiles){
            const arr_user_profiles = [];
            basicData.user_profiles.map((profile, index) => {
                arr_user_profiles.push({_id: profile._id, name: profile.name});
            })
            setProfile(arr_user_profiles[0].value);
            setProfileList(arr_user_profiles);
        }
        if(basicData){
            setAreas(basicData.areas);


            let role_list = basicData.roles;
            if(clientData.name_clients === 'one_sp'){
                if(clientData.user_role.code !== '8'){
                    const roles_updates = {...basicData};
                    role_list = roles_updates.roles.filter(rol => rol._id !== "6494b12090c83a00089bde56" && rol._id !== "6494c5eaf8cf9900084250dd" && rol._id !== "6494c715f8cf9900084251c5" && rol._id !== "6494c299b63e0a0008de6617");
                }
            }

            setRoles(role_list);
            setRegions(basicData.regions);
        }

    }, [basicData])

    const handleCreate = () => {
        navigate(`/users/create`);
    }

    const handleFormElementChange = (name, value) => {

        switch (name) {
            case "alphabetical_order":
                setAlphabeticalOrder(value);
                alphabeticalOrderRef.current = value;
                break;
            case "date_order":
                setDateOrder(value);
                dateOrderRef.current = value;
                break;
            case "status":
                setStatus(value);
                statusRef.current = value;
                break;
            case "profile":
                setProfile(value);
                profileRef.current = value;
                break;
            case "area":
                setArea(value);
                areaRef.current = value;
                break;
            case "role":
                setRole(value);
                roleRef.current = value;
                break;
            case "region":
                setRegion(value);
                regionRef.current = value;
                break;
            case "keywords":
                let updateKeywords = {...keywords};
                updateKeywords.value = value;
                setKeywords(updateKeywords);
                keywordsRef.current = value;
                break;
            case "order_by":
                setOrderBy(value);
                const orderBy_splitted = value.split("|");
                if(orderBy_splitted[0] === "alphabetical"){
                    setAlphabeticalOrder(orderBy_splitted[1]);
                    setDateOrder(0);
                }
                if(orderBy_splitted[0] === "date"){
                    setAlphabeticalOrder(0);
                    setDateOrder(orderBy_splitted[1]);
                }
                break;
            default:
                break;
        }
    }

    const handleFilter = (e) => {
        const update_filters = {...filters};
        update_filters.alphabetical_order = alphabeticalOrder;
        update_filters.date_order = dateOrder;
        update_filters.area = area ? area : "";
        update_filters.role = role ? role : "";
        update_filters.region = region ? region : "";
        update_filters.status = status;
        update_filters.text_filter = replaceSpecialCharacters(keywords.value);
        setFilters(update_filters);
        localStorage.setItem('users_filters', JSON.stringify(update_filters));
    }

    const handleKeyDown = (key) => {
        if(key === "Enter"){
            handleFilter();
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuCountryReportEl, setMenuCountryReportEl] = React.useState(null);
    
    const open = Boolean(anchorEl);
    const openMenuCountryReport = Boolean(menuCountryReportEl);

    const handleClickMenuCountryReport = (event) => {
        setMenuCountryReportEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setMenuCountryReportEl(null);
    };
    
    const handleCountryReporting = (code, prefix) => {
        handleReporting(code, prefix)
    }

    const handleReporting = (code, country = '') => {

        setReportCode(code);

        if(code === 'income' || code === 'courses'){
            setOpenScheduleDateTimeRangeReporting(true);
        }else{
            setLoadingReport(true);
            const response = exports_bq(code, '', '', country);
            response.then(res => {
                if(res.data.status){
                    setLoadingReport(false);
                    window.open(res.data.single.location, '_blank').focus();
                }
            }).catch(error => {
                setLoadingReport(false);
                alert("Hubo un problema al intentar descargar el reporte.");
            })
        }
        setMenuCountryReportEl(null);
        setAnchorEl(null);

    }

    const handleScheduleDateTimeRangeSubmit = (_startDate, _endDate) => {
        
        setOpenScheduleDateTimeRangeReporting(false);
        setLoadingReport(true);

        let _date_finish = '';
        let _date_init = '';

        if(dayjs.tz.guess() === timezone){
            _date_finish = dayjs(dayjs(_endDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            _date_init = dayjs(dayjs(_startDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
        }else{
            _date_finish = dayjs.tz(dayjs(_endDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            _date_init = dayjs.tz(dayjs(_startDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
        }

        const response = exports_bq(reportCode, _date_init, _date_finish);
        response.then(res => {
            if(res.data.status){
                setLoadingReport(false);
                window.open(res.data.single.location, '_blank').focus();
            }
        }).catch(error => {
            setLoadingReport(false);
            alert("Hubo un problema al intentar descargar el reporte.");
        })

    }
    

    useEffect(() => {
        if(basicData){
            //localStorage.removeItem('users_filters');
            if(localStorage.getItem('users_filters') && isJSON(localStorage.getItem('users_filters'))){
                const storedFilter = JSON.parse(localStorage.getItem('users_filters'));

                // console.log("- - - - - - - - - - - - - - - - - - -")
                // console.log(JSON.parse(localStorage.getItem('users_filters')))
                // console.log("- - - - - - - - - - - - - - - - - - -")

                const update_filters = {...filters};
                update_filters.alphabetical_order = storedFilter.alphabetical_order;
                update_filters.date_order = storedFilter.date_order;
                update_filters.area = storedFilter.area;
                update_filters.role = storedFilter.role;
                update_filters.region = storedFilter.region;
                update_filters.status = storedFilter.status;
                update_filters.text_filter = storedFilter.text_filter;
                setFilters(update_filters);
                
                setArea(storedFilter.area);
                setStatus(storedFilter.status);
                setRegion(storedFilter.region);
                setRole(storedFilter.role);
                setDateOrder(storedFilter.date_order);
                setAlphabeticalOrder(storedFilter.alphabetical_order);

                if(storedFilter.date_order){
                    setOrderBy(`date|${storedFilter.date_order}`);
                }
                if(storedFilter.alphabetical_order){
                    setOrderBy(`alphabetical|${storedFilter.alphabetical_order}`);
                }

                if(storedFilter.text_filter){
                    setKeywords((prevKeywords) => ({ ...prevKeywords, value: storedFilter.text_filter }));
                }

                
            }else{

                const update_filters = {...filters};
                update_filters.alphabetical_order = 0;
                update_filters.date_order = -1;
                update_filters.area = area ? area : "";
                update_filters.role = role ? role : "";
                update_filters.region = region ? region : "";
                update_filters.status = 0;
                update_filters.text_filter = "";
                setFilters(update_filters);
                setOrderBy(`date|-1`);

            }
                
        }
      return () => {}
    }, [basicData])


    
    useEffect(() => {
        
        //console.log("----> ", basicData?.countries_reports)

        return () => {}
    }, [basicData])

    if(!basicData || !filters){
        return null;
    }
    
    return (
        <WrapperStyled pancolors={panColors}>

            {openScheduleDateTimeRangeReporting ? 
            <ScheduleDateTimeRangeReporting 
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                open={openScheduleDateTimeRangeReporting}
                setOpen={setOpenScheduleDateTimeRangeReporting}
                handleSubmit={handleScheduleDateTimeRangeSubmit}
                timezone={timezone}
                setTimezone={setTimezone}
            /> : ""}

            {basicData ?
            <Fragment>
            {basicData.settings.users.actions.length ?
            <div className='actions'>
                
                {((basicData.settings.users.actions.filter(action => action === 'create')).length) ? 
                <ButtonCustom 
                    className="bt_create_user"
                    text="Crear Usuario"
                    onClick={handleCreate}
                    style_type="normal"

                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                /> : ""}
                
                {clientData.name_clients === 'one_sp' ?
                    !loadingReport ?
                    <ButtonCustom 
                        className="bt_export_user"
                        text={`Reportes`}
                        onClick={(event) => { !loadingReport && handleClick(event) }}
                        style_type="invert"

                        icon={null}
                        background={{ normal: panColors.white, hover: panColors.primary }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        disabled={loadingReport}
                    />
                :
                    <div className='loader_export'>
                        <LoaderComponent size={20}  />
                    </div> 
                : ""
                }

                {(clientData.name_clients === 'one_salesbook' || clientData.name_clients === 'one_savencia') ?
                    !loadingReport ?
                    <ButtonCustom 
                        className="bt_export_user"
                        text={`Reportes`}
                        onClick={(event) => { !loadingReport && handleReporting('users') }}
                        style_type="invert"

                        icon={null}
                        background={{ normal: panColors.white, hover: panColors.primary }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        disabled={loadingReport}
                    />
                :
                    <div className='loader_export'>
                        <LoaderComponent size={20}  />
                    </div> 
                : ""
                }

                {clientData.name_clients === 'one_wts' ?
                    !loadingReport ?
                    <ButtonCustom 
                        className="bt_export"
                        text={`Exportar ${loadingReport ? <LoaderComponent /> : ''}`}
                        onClick={(event) => { !loadingReport && basicData?.countries_reports.length > 1 ? handleClickMenuCountryReport(event) : handleReporting('users') }}
                        style_type="invert"

                        icon={null}
                        background={{ normal: panColors.white, hover: panColors.primary }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        disabled={loadingReport}
                    />
                :
                    <div className='loader_export'>
                        <LoaderComponent size={20}  />
                    </div> 
                : ""
                }

                {clientData.name_clients === 'one_danone' ?
                    !loadingReport ?
                    <ButtonCustom 
                        className="bt_export"
                        text={`Exportar ${loadingReport ? <LoaderComponent /> : ''}`}
                        onClick={(event) => { !loadingReport && handleReporting('users_danone') }}
                        style_type="invert"

                        icon={null}
                        background={{ normal: panColors.white, hover: panColors.primary }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        disabled={loadingReport}
                    />
                :
                    <div className='loader_export'>
                        <LoaderComponent size={20}  />
                    </div> 
                : ""
                }

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => handleReporting('payroll')}>Nómina</MenuItem>
                    <MenuItem onClick={() => handleReporting('courses')}>Cursos</MenuItem>
                    <MenuItem onClick={() => handleReporting('income')}>Ingresos</MenuItem>
                </Menu>

                {basicData?.countries_reports.length ?
                <Menu
                    id="menu-country-report"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={menuCountryReportEl}
                    open={openMenuCountryReport}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    
                    {basicData?.countries_reports.length ?
                        basicData?.countries_reports.map((country, index) => {
                            return(
                                <MenuItem onClick={() => handleCountryReporting('users', country._id)}>{country.name}</MenuItem>
                            )
                        })
                    :
                    ""}
                </Menu> : ""}
                
            </div> : ""}

            
            
            <div className="filter_actions">

                <InputCustom 
                    name={keywords.ref}
                    label={""}//keywords.label
                    placeholder={keywords?.placeholder}
                    onChange={handleFormElementChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={null}
                    className={`field field_${keywords.ref}${keywords.error ? " error" : ""}`}
                    type="text"
                    value={keywords.value}
                    help={keywords.help}
                    isRequired={keywords.required}
                    styles={
                        {background_color: `rgba(${panColors.light_white}, 1)`,
                         border_color: `rgba(${panColors.light_white}, 1)`}
                    }
                    icon={<HiMagnifyingGlass />}
                    tooltip_content={keywords.tooltip}
                    haveError={keywords.error}
                />

                <div className="filter_option">
                    <div className="label_title">
                        Filtrar por:
                    </div>

                    {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'status')).length) ? 
                    <SelectCustom 
                        name={"status"}
                        label={""}//Estado
                        placeholder={null}
                        onChange={handleFormElementChange}
                        className={`field field_status`}
                        type="text"
                        value={status}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={statusList}
                    /> : ""} 

                    {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'area')).length) ?
                    <SelectCustom 
                        name={"area"}
                        label={""}//Perfíl
                        placeholder={`${(basicData.settings.users.section_list_filters.filter(filter => filter.id === 'area'))[0].label} ...`}
                        onChange={handleFormElementChange}
                        className={`field field_area`}
                        type="text"
                        value={area}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={areas}
                    /> : ""}

                    {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'role')).length) ?
                    <SelectCustom 
                        name={"role"}
                        label={""}//Perfíl
                        placeholder={"Rol ..."}
                        onChange={handleFormElementChange}
                        className={`field field_role`}
                        type="text"
                        value={role}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={roles}
                    /> : ""}

                    {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'region')).length) ? 
                    <SelectCustom 
                        name={"region"}
                        label={""}//Perfíl
                        placeholder={"Región ..."}
                        onChange={handleFormElementChange}
                        className={`field field_region`}
                        type="text"
                        value={region}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={regions}
                    /> : ""}

                    <div className="label_title order_by">
                        Ordenar por:
                    </div>

                    <SelectCustom 
                        name={"order_by"}
                        label={""}
                        placeholder={null}
                        onChange={handleFormElementChange}
                        className={`field field_order_by`}
                        type="text"
                        value={orderBy}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={alphabeticalDateOrderList}
                    />

                    <ButtonCustom 
                        className="bt_filter"
                        text="Filtrar"
                        onClick={handleFilter/*() => { !filtersError && handleKeyDown(); }*/}
                        style_type="normal"

                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        //disabled={filtersError}
                    />

                </div>
                

            </div>

            <div className="content">
                <UsersTable filters={filters} /> 
            </div>
            </Fragment> : ""
            }
        
        </WrapperStyled>
    );
}