import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { InHomeModeIcon } from '../../../helpers/icons';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import Form from '../../../components/Segmentation/Form';

const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 800px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 90px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            .title{
                margin: 20px 0 0 0;
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            .subtitle{
                margin: 10px 0 0 0;
                font-size: 18px;
                line-height: 22px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
        .label_no_segmentation{
            margin-bottom: 0;
        }
    }
`

const CreateContainer = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { panColors } = useContext(ConfigurationContext);

    const { open, 
            setOpen,
            segments,
            setSegments} = props;
    
    const [ countries, setCountries ] = useState([]);
    const [ areas, setAreas ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    const [ clusters, setClusters ] = useState([]);
    const [ retailers, setRetailers ] = useState([]);
    const [ formats, setFormats ] = useState([]);
    const [ idioms, setIdioms ] = useState([]);
    const [ achievements, setAchievements ] = useState([]);
    const [ departments, setDepartments ] = useState([]);
    const [ flags, setFlags ] = useState([]);
    const [ noSegmentation, setNoSegmentation ] = useState(null);
    const [ title, setTitle ] = useState("");
    
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        
        if(segments?.countries){
            setCountries(segments.countries);
        }
        if(segments?.countries){
            setAreas(segments.areas);
        }
        if(segments?.countries){
            setRoles(segments.roles);
        }
        if(segments?.countries){
            setRegions(segments.regions);
        }
        if(segments?.countries){
            setClusters(segments.clusters);
        }
        if(segments?.countries){
            setRetailers(segments.retailers);
        }
        if(segments?.countries){
            setFormats(segments.formats);
        }
        if(segments?.countries){
            setIdioms(segments.idioms);
        }
        if(segments?.countries){
            setDepartments(segments.departments);
        }
        if(segments?.achievements){
            setAchievements(segments.achievements);
        }
        if(segments?.flags){
            setFlags(segments.flags);
        }
        
    
      return () => {
        
      }
    }, [segments])

    const handleSubmit = () => {

        setSegments({ countries: countries,
                      areas: areas,
                      roles: roles,
                      regions: regions,
                      clusters: clusters,
                      retailers: retailers,
                      formats: formats,
                      idioms: idioms,
                      departments: departments,
                      flags: flags,
                      achievements: achievements })
                      
        setOpen(false);

    }

    return (
        <DialogStyled
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
            pancolors={panColors}
        >
            <div className="inner_dialog">

                <div className='header_dialog'>
                    <div className='icon'>
                        <InHomeModeIcon />
                    </div>
                    <div className='title'>Segmentación</div>
                    <div className='subtitle'>La segmentación, incluso si es “sin segmentar”, es obligatoria.</div>
                </div>
                <Form 
                    title={title}
                    setTitle={setTitle}
                    countries={countries} setCountries={setCountries}
                    areas={areas} setAreas={setAreas}
                    roles={roles} setRoles={setRoles}
                    regions={regions} setRegions={setRegions}
                    clusters={clusters} setClusters={setClusters}
                    retailers={retailers} setRetailers={setRetailers}
                    formats={formats} setFormats={setFormats}
                    idioms={idioms} setIdioms={setIdioms}
                    achievements={achievements} setAchievements={setAchievements}
                    departments={departments} setDepartments={setDepartments}
                    flags={flags} setFlags={setFlags}
                    noSegmentation={noSegmentation} setNoSegmentation={setNoSegmentation}
                    dataLoaded={true}
                />

                <div className="actions">
                    <ButtonCustom 
                        className="bt_submit"
                        text={"Guardar segmentación"}
                        onClick={handleSubmit}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                    <ButtonTextCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => setOpen(false)}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                    />
                </div>

            </div>
        </DialogStyled>
    );
}

export default CreateContainer;