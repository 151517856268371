import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import LabelCustom from '../../../components/elements/LabelCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import InputCustom from '../../../components/elements/InputCustom';

import { DeleteElementIcon,
         ActiveStoreIcon } from '../../../helpers/icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { PiPasswordBold } from "react-icons/pi";

                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 400px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                font-size: 40px;
                line-height: 40px;
                width: 75px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            p.aclaration{
                margin: 10px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .form{
            width: 100%;
            margin-top: 20px;
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
            .success{
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 15px;
                line-height: 15px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: ${props => `rgb(${props.pancolors.green})`};
            }
        }
    }
`

const ResetPassword = (props) => {

    const { panColors,
            clientData } = useContext(ConfigurationContext);

    const { resetPasword } = useContext(ContentContext);

    const { id,
            open, 
            setOpen, } = props;
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ newPasswordError, setNewPasswordError ] = useState(false);
    const [ saveStatus, setSaveStatus ] = useState(false);
    const [ newPassword, setNewPassword ] = useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormElementChange = (name, value) => {
        switch (name) {
            case 'new_password':
                setNewPassword(value);
                break;

            default:
                break;
        }
        
        return null;
    }

    const handleSubmitToActivate = () => {
        let response;

        let payload = {
            id_user: id,
            new_pass: newPassword
        };
        
        setIsLoading(true);
        response = resetPasword(payload);
        response.then((res) => {
            setSaveStatus(true);
            setIsLoading(false);
            //setOpen(false);
        })
        
    }

    useEffect(() => {

        if(open){
            setSaveStatus(false);
            if(clientData.name_clients === "one_sp"){
                setNewPassword("protagonistas2024");
            }
        }

        return () => {
            if(!open){
                setIsLoading(false);
            }
        }
    }, [open])
    

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="xs"
                pancolors={panColors}
            >
                <div className="inner_dialog">
                
                    
                    <div className="header_dialog">
                        <div className="icon">
                            <PiPasswordBold />
                        </div>
                        <p className="title">Modificar contraseña</p>
                    </div>

                    <div className='form'>
                        <InputCustom 
                            name={"new_password"}
                            label={"Nueva contraseña"}
                            className={`field field_new_password${newPasswordError ? " error" : ""}`}
                            value={newPassword}
                            isRequired={true}
                            placeholder={""}
                            onChange={handleFormElementChange}
                            onKeyUp={null}
                            type="text"
                            help={""}
                            styles={ formStyle }
                            label_icon={<PiPasswordBold />}
                            tooltip_content={""}
                            haveError={newPasswordError}
                            disabled={false}
                        />
                    </div>

                    {isLoading ? 
                        <div className='loader'>
                            <LoaderComponent />
                        </div>
                    :
                        <div className="actions_dialog">
                            {!saveStatus ?
                            <ButtonCustom 
                                className="bt_submit"
                                text="Resetear"
                                onClick={handleSubmitToActivate}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.dots, hover: panColors.white }}
                                border={{ normal: panColors.dots, hover: panColors.dots }}
                                textcolor={{ normal: panColors.white, hover: panColors.dots }}
                            /> : <div className='success'>La contraseña se modificó con éxito</div>}
                            <ButtonCustom 
                                className="bt_cancel"
                                text="Cerrar"
                                onClick={() => handleClose()}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                            />
                        </div>
                    }
                </div>
            </DialogStyled>
    );
}


export default ResetPassword;