import React, { useContext } from 'react';
import styled from 'styled-components';

import { ContentContext,
         ConfigurationContext } from '../../context';
    
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const Wrapper = styled.div`

`

const Map = (props) => {

    const { } = useContext(ContentContext);

    const { colors } = useContext(ConfigurationContext);

    const { location } = props;

    const [map, setMap] = React.useState(null)

    const containerStyle = {
        width: '100%',
        height: '600px'
      };
      
      const center = {
        lat: location.lat, lng: location.lng
      };

      const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD587bUFdloU5RJpvbAE0VGhEbOPdmc3zs"
      })

      const onLoad = React.useCallback(function callback(map) {
        map.setZoom(17);
        setMap(map)
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])
    

    return (
        <Wrapper {...colors}>
            
            {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        <Marker 
                            position={{ lat: location.lat, lng: location.lng }} 
                            icon={{
                                url: 'https://fabianhr.com/map_marker.svg',
                                size: {width: 40, height: 50}, 
                                anchor: {x: 20, y: 25}, 
                                scaledSize: {width: 40, height: 50},
                            }}
                        />
                    </GoogleMap>
                ) : <></>
            }

        
        </Wrapper>
    );
}

export default Map;



