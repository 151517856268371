import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectCustom from '../elements/SelectCustom';       
import ButtonTextCustom from '../elements/ButtonTextCustom';
import { BiSolidDownArrow } from "react-icons/bi";

import MultipleChoice from './MultipleChoice';
import MultipleChoiceWithImage from './MultipleChoiceWithImage';
import OpenAnswer from './OpenAnswer';

import { EditIcon,
         LanguageIcon,
         DragIndicatorIcon, } from '../../helpers/icons';

import { dataObjectMultipleChoice,
         getNameLanguage,
         dataObjectMultipleChoiceWithImage,
         dataObjectOpenAnswer,
         getTypeAssesment, } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    
    .question_type_container.show{
        display: flex;
        flex-direction: column;
    }
    .question_type_container.hide{
        display: none;
    }
    
    

    .box_lang{

        .question_type_container{
            padding: 35px 50px;
            box-sizing: border-box;
            background-color: ${props => `rgb(${props.pancolors.light_white})`};
            margin-top: 0;
            border-radius: 8px;
            .title{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    display: flex;
                    svg{
                        fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                    }
                }
                .text{
                    color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 600;
                    margin-left: 7px;
                }
            }
        }

        &.error{
            border: 1px solid ${props => `rgb(${props.pancolors.red})`}!important;

            .head_form_lang{
                
                >.title{
                    .icon{
                        svg{
                            fill: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                    .text{
                        color: ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        &.multilang{
            box-sizing: border-box;
            padding: 0px 30px 0px;
            background-color: ${props => `rgb(${props.pancolors.light_white})`};
            border: 1px solid ${props => `rgb(${props.pancolors.light_white})`};
            margin-bottom: 20px;
            .question_type_container{
                padding: 5px 50px;
            }
            &.open{
                padding: 0px 30px 20px;
            }
            .head_form_lang{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 20px 0 20px 0;
                
                >.title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon{
                        svg{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text{
                        font-size: 22px;
                        line-height: 22px;
                        font-weight: 700;
                        color: ${props => `rgb(${props.pancolors.dots})`};
                        margin-left: 10px;
                    }
                }
            }
        }
    }
`

const QuestionContainer = (props) => {

    const { panColors, 
            userCountrySelected } = useContext(ConfigurationContext);
    
    const { basicData } = useContext(ContentContext);

    const { assessmentType,
            blockAction,
            setBlockAction,
            contentByLang, 
            setContentByLang,
            evaluationData,
            questions,
            questionSelected,
            setQuestionSelected,
            questionSelectedOrder,
            langHaveError } = props;
            

    const [ dataQuestion, setDataQuestion ] = useState(null);
    const [ evaluationTypes, setEvaluationTypes ] = useState([]);
    //const [ langHaveError, setLangHaveError ] = useState([]);
    const [ showLanguage, setShowLanguage ] = useState(null);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};


    const handleFormElementChange = (name, value) => {
        
        const questionSelectedUpdate = {...questionSelected};

        if(name === "input_type"){

            const type = getTypeAssesment(basicData.assessment_type, evaluationData.type_assessment.value);
            
            if(type === questionSelected.input_type.value){

            }else{

                Object.keys(questionSelectedUpdate.translations).map(function(key) {
                    
                    questionSelectedUpdate.translations[key].description.value = "";
                    questionSelectedUpdate.translations[key].media.value = "";
                    questionSelectedUpdate.translations[key].media.cropped_image = "";
                    
                    questionSelectedUpdate.translations[key].title.value = "";
                    questionSelectedUpdate.translations[key].secondary_actions = [];

                })
                questionSelectedUpdate.penalty_score.value = "0";
                questionSelectedUpdate.score.value = "0";

            }

            switch (value) {

                case "single_choice":

                    Object.keys(questionSelectedUpdate.translations).map(function(key) {
                        questionSelectedUpdate.translations[key].secondary_actions = [dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type)];
                    })

                    break;
                case "multiple_choice":

                    Object.keys(questionSelectedUpdate.translations).map(function(key) {
                        questionSelectedUpdate.translations[key].secondary_actions = [dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type)];
                    })
                    
                    break;
                case "single_choice_image":

                    Object.keys(questionSelectedUpdate.translations).map(function(key) {
                        questionSelectedUpdate.translations[key].secondary_actions = [dataObjectMultipleChoiceWithImage(null, 1, type), dataObjectMultipleChoiceWithImage(null, 2, type), dataObjectMultipleChoiceWithImage(null, 3, type), dataObjectMultipleChoiceWithImage(null, 4, type)];
                    })
                    
                    break;
                case "multiple_choice_image":

                    Object.keys(questionSelectedUpdate.translations).map(function(key) {
                        questionSelectedUpdate.translations[key].secondary_actions = [dataObjectMultipleChoiceWithImage(null, 1, type), dataObjectMultipleChoiceWithImage(null, 2, type), dataObjectMultipleChoiceWithImage(null, 3, type), dataObjectMultipleChoiceWithImage(null, 4, type)];
                    })
                    dataObjectMultipleChoiceWithImage(null, 1, type)
                    dataObjectOpenAnswer()

                    break;
                case "comments":

                    Object.keys(questionSelectedUpdate.translations).map(function(key) {
                        questionSelectedUpdate.translations[key].secondary_actions_error = false;
                        questionSelectedUpdate.translations[key].secondary_actions = [];
                    })
                    break;
                default:
                    return null;
            }

        }
        
        questionSelectedUpdate[name].value = value;
        setQuestionSelected(questionSelectedUpdate);
        
    }    
    
    const handleShowLanguage = (lang) => {
        if(lang !== showLanguage){
            setShowLanguage(lang);
        }else{
            setShowLanguage(null);
        }
    }

    useEffect(() => {
        
        if(basicData){
            const arrQuestionType = [];
            basicData.questions_type.map((type, index) => {
                arrQuestionType.push({_id: type.title, name: type.label});
            })
            setEvaluationTypes(arrQuestionType);


        }
    
      return () => {}
    }, [basicData])

    useEffect(() => {
        if(userCountrySelected){
            setShowLanguage(userCountrySelected.languages[0].language_identifier);
        }
    return () => {}
    }, [userCountrySelected])

    useEffect(() => {
        setShowLanguage(userCountrySelected.languages[0].language_identifier);
    return () => {}
    }, [questionSelectedOrder])
    
    return (
        <WrapperStyled pancolors={panColors}>

                {questionSelected &&
                <>
                <div className="box_question">
                    <div className='question_number'>Pregunta {questionSelectedOrder + 1}</div>
                    <div className='question_type_box'>
                        <div className='icon'>
                            <DragIndicatorIcon />
                        </div>
                        {evaluationTypes ?
                            <SelectCustom 
                                name={questionSelected.input_type?.ref}
                                label={questionSelected.input_type?.label}
                                placeholder={questionSelected.input_type?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${questionSelected.input_type?.ref}${questionSelected.input_type?.error ? " error" : ""}`}
                                type="text"
                                value={questionSelected.input_type?.value}
                                help=""
                                isRequired={questionSelected.input_type?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={questionSelected.input_type?.error}
                                data={evaluationTypes}
                            /> : ""
                        }
                        <SelectCustom 
                            name={questionSelected.score?.ref}
                            label={questionSelected.score?.label}
                            //placeholder={questionSelected.score?.placeholder}
                            placeholder={null}
                            onChange={handleFormElementChange}
                            className={`field field_${questionSelected.score?.ref}${questionSelected.score?.error ? " error" : ""}`}
                            type="text"
                            value={questionSelected.score?.value}
                            help={questionSelected.score?.help}
                            isRequired={questionSelected.score?.required}
                            styles={ formStyle }
                            label_icon={null}
                            tooltip_content={questionSelected.score?.tooltip_content}
                            haveError={questionSelected.score?.error}
                            data={basicData?.value_points}
                        />
                        <SelectCustom 
                            name={questionSelected.penalty_score?.ref}
                            label={questionSelected.penalty_score?.label}
                            //placeholder={questionSelected.penalty_score?.placeholder}
                            placeholder={null}
                            onChange={handleFormElementChange}
                            className={`field field_${questionSelected.penalty_score?.ref}${questionSelected.penalty_score?.error ? " error" : ""}`}
                            type="text"
                            value={questionSelected.penalty_score?.value}
                            help={questionSelected.penalty_score?.help}
                            isRequired={questionSelected.penalty_score?.required}
                            styles={ formStyle }
                            label_icon={null}
                            tooltip_content={questionSelected.penalty_score?.tooltip_content}
                            haveError={questionSelected.penalty_score?.error}
                            data={basicData?.value_points}
                        />
                        <SelectCustom 
                            name={questionSelected.id_module?.ref}
                            label={questionSelected.id_module?.label}
                            placeholder={questionSelected.id_module?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${questionSelected.id_module?.ref}${questionSelected.id_module?.error ? " error" : ""}`}
                            type="text"
                            value={questionSelected.id_module?.value}
                            help={questionSelected.id_module?.help}
                            isRequired={questionSelected.id_module?.required}
                            styles={ formStyle }
                            label_icon={null}
                            tooltip_content={questionSelected.id_module?.tooltip_content}
                            haveError={questionSelected.id_module?.error}
                            data={basicData?.content}
                        />
                    </div>
                </div>
                
                {questionSelected.input_type?.value ?
                    userCountrySelected?.languages.map((lang, index) => {
                        return(
                            <div className={`box_lang ${userCountrySelected?.languages?.length > 1 ? " multilang" : ""}${(langHaveError.filter(langerror => langerror === lang.language_identifier).length ? ' error' : '')}${showLanguage === lang.language_identifier ? ' open' : ''}`}>
                                {userCountrySelected?.languages?.length > 1 &&
                                <div className="head_form_lang">
                                    <div className="title">
                                        <div className="icon">
                                            <LanguageIcon />
                                        </div>
                                        <div className="text">
                                            Post en { getNameLanguage(userCountrySelected?.languages, lang.language_identifier) }
                                        </div>
                                    </div>
                                    {userCountrySelected?.languages.length ?
                                    <ButtonTextCustom 
                                        className="bt_show_more_info"
                                        text={`${showLanguage === lang.language_identifier ? 'Cerrar' : 'Abrir'}`}
                                        onClick={() => handleShowLanguage(lang.language_identifier)}

                                        icon={<BiSolidDownArrow />}
                                        background={{ normal: "", hover: "" }}
                                        border={{ normal: "", hover: "" }}
                                        textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                    /> : "" }
                                </div>
                                }


                                <div className={`question_type_container content_form${showLanguage === lang.language_identifier ? ' show' : ' hide'}`}>
                                    <div className="title">
                                        <div className="icon">
                                            <EditIcon />
                                        </div>
                                        <div className="text">
                                            Editar pregunta
                                        </div>
                                    </div>
                                    
                                    {questionSelected.input_type.value === "single_choice" &&
                                        <MultipleChoice 
                                            data={dataQuestion}    
                                            questionSelected={questionSelected}
                                            setQuestionSelected={setQuestionSelected}
                                            questionSelectedOrder={questionSelectedOrder}
                                            contentByLang={contentByLang} 
                                            setContentByLang={setContentByLang}
                                            lang={lang.language_identifier}
                                            questions={(assessmentType != null && assessmentType === "evaluation_lineal") ? null : questions} 
                                            imageReference={true}
                                            response_multiple={false}
                                            blockAction={blockAction}
                                            setBlockAction={setBlockAction}
                                        />
                                    }

                                    {questionSelected.input_type.value === "multiple_choice" &&
                                        <MultipleChoice 
                                            data={dataQuestion}
                                            questionSelected={questionSelected}
                                            setQuestionSelected={setQuestionSelected}
                                            questionSelectedOrder={questionSelectedOrder}
                                            contentByLang={contentByLang} 
                                            setContentByLang={setContentByLang}
                                            lang={lang.language_identifier}
                                            questions={(assessmentType != null && assessmentType === "evaluation_lineal") ? null : questions} 
                                            imageReference={true}
                                            response_multiple={true}
                                            blockAction={blockAction}
                                            setBlockAction={setBlockAction}
                                        />
                                    }

                                    {questionSelected.input_type.value === "single_choice_image" &&
                                        <MultipleChoiceWithImage 
                                            data={dataQuestion} 
                                            questionSelected={questionSelected}
                                            setQuestionSelected={setQuestionSelected} 
                                            questionSelectedOrder={questionSelectedOrder}  
                                            contentByLang={contentByLang} 
                                            setContentByLang={setContentByLang}
                                            lang={lang.language_identifier}
                                            questions={(assessmentType != null && assessmentType === "evaluation_lineal") ? null : questions} 
                                            response_multiple={false}
                                            blockAction={blockAction}
                                            setBlockAction={setBlockAction}
                                        />
                                    }

                                    {questionSelected.input_type.value === "multiple_choice_image" &&
                                        <MultipleChoiceWithImage 
                                            data={dataQuestion}
                                            questionSelected={questionSelected}
                                            setQuestionSelected={setQuestionSelected}
                                            questionSelectedOrder={questionSelectedOrder}
                                            contentByLang={contentByLang} 
                                            setContentByLang={setContentByLang}
                                            lang={lang.language_identifier}
                                            questions={(assessmentType != null && assessmentType === "evaluation_lineal") ? null : questions} 
                                            response_multiple={true}
                                            blockAction={blockAction}
                                            setBlockAction={setBlockAction}
                                        />
                                    }

                                    {questionSelected.input_type.value === "comments" &&
                                        <OpenAnswer 
                                            data={dataQuestion}
                                            questionSelected={questionSelected}
                                            setQuestionSelected={setQuestionSelected}
                                            questionSelectedOrder={questionSelectedOrder}
                                            contentByLang={contentByLang} 
                                            setContentByLang={setContentByLang}
                                            lang={lang.language_identifier}
                                            questions={(assessmentType != null && assessmentType === "evaluation_lineal") ? null : questions} 
                                            blockAction={blockAction}
                                            setBlockAction={setBlockAction}
                                        />
                                    }


                                    

                                </div>

                                </div>




                        )
                    })
                
                :""}
                </>
                }


            
        </WrapperStyled>
    );
}

export default React.memo(QuestionContainer);