import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageAlertHttp = (props) => {
    
    const {haveResponseError, 
           setHaveResponseError, 
           haveResponseErrorMessage} = props;
    
    const handleClose = () => {
      setHaveResponseError(false);
    }

  return (
    <Snackbar 
      open={haveResponseError} 
      autoHideDuration={6000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {haveResponseErrorMessage}
      </Alert>
    </Snackbar>
  );
}

export default MessageAlertHttp;