import React from "react";
import StoresContainer from "./StoresContainer";

const Stores = (props) => {
  return (
      <StoresContainer {...props} />
  );
};

export default Stores;
