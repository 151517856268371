import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectCustom from '../elements/SelectCustom';
import InputCheckboxCustom from '../elements/InputCheckboxCustom';  
import LabelCustom from '../elements/LabelCustom';  
import ModalImage from '../elements/Modals/ModalImage';

import { FormatPaintIcon, 
         FormatListNumberedIcon,
         CountIcon,
         TaskIcon,
         CoursesMenuIcon} from '../../helpers/icons';

import { ContentContext,
         ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    
    .box_form{
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        .box_column{
            .action_box_evaluation{
                margin-top: 50px;
            }
            .media_slides{
                margin-top: 30px;
                .no_multimedia_content{
                    box-sizing: border-box;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    border: 1px dashed ${props => `rgb(${props.panColors.middle_gray})`};
                    color: ${props => `rgb(${props.panColors.dark_gray})`};
                    font-weight: 600;
                }
            }
        }
        &.show{
            display: grid;
        }
        &.hide{
            display: none;
        }
    }
    .box_evaluation{
        &.show{
            display: flex;
        }
        &.hide{
            display: none;
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        .retries{
            margin-bottom: 20px;
            .label_retries{
                margin-bottom: 15px;
            }
            .field_can_repeat{
                margin-bottom: 10px;
            }
            .field_can_repeat_failure{
                margin-bottom: 10px;
            }
            .field_can_repeat_success{
                margin-bottom: 10px;
            }
        }
        >.title{
            color: ${props => `rgb(${props.panColors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            :last-child{
                margin-bottom: 0;
            }
        }
        .field_description_long{
            margin-bottom: 0!important;
            textarea{
                height: 160px;
            }
        }
        .field_description_short{
            margin-bottom: 0!important;
            textarea{
                height: 120px;
            }
        }
        .bottom_textarea{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            font-size: 14px;
            margin-top: 5px;
            font-weight: 700;
            color: ${props => `rgb(${props.panColors.dark_gray})`};
            &.error{
                color: ${props => `rgb(${props.panColors.red})`};
            }
        }
        .box_field_description_long,
        .box_field_description_short{
            width: 100%;
            margin-bottom: 25px!important;

            .error{
                color: ${props => `rgb(${props.panColors.red})`};
            }
        }
        .field_double{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            .field{
                width: calc(50% - 10px);
            }
        }
    }
    .box_cover_image{
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        width: 100%;
        
        .image,
        .image_thumb{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 300px;
            overflow: hidden;
            img{
                float: left;
                width: 100%;
            }
            &.error{
                border: 2px solid ${props => `rgb(${props.panColors.red})`};
            }
        }
        .image_thumb{
            background-color: ${props => `rgb(${props.panColors.middle_gray})`};
            .icon{
                width: 120px;
                height: 120px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
        }
        
        .add_image{
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            align-items: flex-start;
            .bt_add_image{
                padding: 0;
                >.text{
                    margin-left: 0;
                }
            }
            .bt_delete_image{
                padding: 0;
                margin-top: 10px;
            }
            .aclaration_bt_add_image{
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: ${props => `rgb(${props.panColors.dark_gray})`};
                margin-top: 10px;
            }
        }
    }
    .add_aditional_information{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
        .bt_add_more_info{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            padding: 0;
            margin-bottom: 30px;
        }
        .aditional_information_el{ 
            margin-bottom: 10px;
        }
        &.show{
            display: flex;
        }
        &.hide{
            display: none;
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const Form = (props) => {

    const { panColors } = useContext(ConfigurationContext);
    
    const { basicData } = useContext(ContentContext);
            
    const { contentByLang, 
            setContentByLang } = props;

    const [ openModalImage, setOpenModalImage ] = useState(false);
    
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ assessmentType, setAssessmentType ] = useState([]);
    const [ questionQuantityEval, setQuestionQuantityEval ] = useState([]);
    const [ questionQuantityToload, setQuestionQuantityToload ] = useState([]);
    const [ valuePoints, setValuePoints ] = useState([]);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleFormElementChange = (name, value) => {

        let updateData = {...contentByLang};
        updateData[name].value = value;
        let contentByLangUpdate = {...contentByLang};
        contentByLangUpdate = updateData;
        setContentByLang(contentByLangUpdate);

    }
    
    useEffect(() => {
        
        if(basicData){
            const arrAssessmentType = [];
            basicData.assessment_type.map((item, index) => {
                arrAssessmentType.push({_id: item._id, name: item.label});
            })
            setAssessmentType(arrAssessmentType);

            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

            const arrQuestionQuantityEval = [];
            for(let i = 2; i <= 20; i++){
                arrQuestionQuantityEval.push({_id: i, name: i});
            }
            setQuestionQuantityEval(arrQuestionQuantityEval);

            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

            const arrQuestionQuantityToload = [];
            for(let i = 2; i <= 100; i++){
                arrQuestionQuantityToload.push({_id: i, name: i});
            }
            setQuestionQuantityToload(arrQuestionQuantityToload);

            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

            const arrValuePoints = [];
            basicData.value_points.map((item, index) => {
                arrValuePoints.push({_id: item, name: item});
            })
            setValuePoints(arrValuePoints);
        }
    
      return () => {}
    }, [basicData])
    

    return (
        <WrapperStyled panColors={panColors}>

            {openModalImage &&
                <ModalImage 
                    openModalImage={openModalImage} 
                    setOpenModalImage={setOpenModalImage} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                    scope={"cover_image"}
                    maxSize={""}
                />
            }

            <div className="box_form">
                <div className="box_column">
                    <div className="box_fields">
                        <div className="title">
                            Datos de la evaluación
                        </div>

                        <SelectCustom 
                            name={contentByLang?.type_assessment?.ref}
                            label={contentByLang?.type_assessment?.label}
                            placeholder={contentByLang?.type_assessment?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${contentByLang?.type_assessment?.ref}${contentByLang?.type_assessment?.error ? " error" : ""}`}
                            type="text"
                            value={contentByLang?.type_assessment?.value}
                            help={contentByLang?.type_assessment?.help}
                            isRequired={contentByLang?.type_assessment?.required}
                            styles={ formStyle }
                            label_icon={<FormatPaintIcon />}
                            tooltip_content={contentByLang?.type_assessment?.tooltip}
                            haveError={contentByLang?.type_assessment?.error}
                            data={assessmentType}
                        />

                        <SelectCustom 
                            name={contentByLang?.count_questions?.ref}
                            label={contentByLang?.count_questions?.label}
                            placeholder={contentByLang?.count_questions?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${contentByLang?.count_questions?.ref}${contentByLang?.count_questions?.error ? " error" : ""}`}
                            type="text"
                            value={contentByLang?.count_questions?.value}
                            help={contentByLang?.count_questions?.help}
                            isRequired={contentByLang?.count_questions?.required}
                            disabled={contentByLang?.question_quantity_toload?.disabled}
                            styles={ formStyle }
                            label_icon={<CoursesMenuIcon />}
                            tooltip_content={contentByLang?.count_questions?.tooltip}
                            haveError={contentByLang?.count_questions?.error}
                            data={questionQuantityEval}
                        />

                        <SelectCustom 
                            name={contentByLang?.question_quantity_toload?.ref}
                            label={contentByLang?.question_quantity_toload?.label}
                            placeholder={contentByLang?.question_quantity_toload?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${contentByLang?.question_quantity_toload?.ref}${contentByLang?.question_quantity_toload?.error ? " error" : ""}`}
                            type="text"
                            value={contentByLang?.question_quantity_toload?.value}
                            help={contentByLang?.question_quantity_toload?.help}
                            isRequired={contentByLang?.question_quantity_toload?.required}
                            disabled={contentByLang?.question_quantity_toload?.disabled}
                            styles={ formStyle }
                            label_icon={<CountIcon />}
                            tooltip_content={contentByLang?.question_quantity_toload?.tooltip}
                            haveError={contentByLang?.question_quantity_toload?.error}
                            data={questionQuantityToload}
                        />

                        <SelectCustom 
                            name={contentByLang?.approval_rate?.ref}
                            label={contentByLang?.approval_rate?.label}
                            placeholder={contentByLang?.approval_rate?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${contentByLang?.approval_rate?.ref}${contentByLang?.approval_rate?.error ? " error" : ""}`}
                            type="text"
                            value={contentByLang?.approval_rate?.value}
                            help={contentByLang?.approval_rate?.help}
                            isRequired={contentByLang?.approval_rate?.required}
                            styles={ formStyle }
                            label_icon={<TaskIcon />}
                            tooltip_content={contentByLang?.approval_rate?.tooltip}
                            haveError={contentByLang?.approval_rate?.error}
                            data={valuePoints}
                        />
                        <div className="retries">
                            <LabelCustom 
                                name={"retries"}
                                label={"Reintentos en esta evaluación *"}
                                styles={ formStyle }
                                label_icon={<FormatListNumberedIcon />}
                                isRequired={false}
                                tooltip_content={""}
                            />
                            <InputCheckboxCustom
                                name={contentByLang?.can_repeat?.ref}
                                label={contentByLang?.can_repeat?.label}
                                placeholder={contentByLang?.can_repeat?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.can_repeat?.ref}${contentByLang?.can_repeat?.error ? " error" : ""}`}
                                value={contentByLang?.can_repeat?.value}
                                help={contentByLang?.can_repeat?.help}
                                isRequired={contentByLang?.can_repeat?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={null}
                                haveError={contentByLang?.can_repeat?.error}
                            />

                            <InputCheckboxCustom
                                name={contentByLang?.can_repeat_failure?.ref}
                                label={contentByLang?.can_repeat_failure?.label}
                                placeholder={contentByLang?.can_repeat_failure?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.can_repeat_failure?.ref}${contentByLang?.can_repeat_failure?.error ? " error" : ""}`}
                                value={contentByLang?.can_repeat_failure?.value}
                                help={contentByLang?.can_repeat_failure?.help}
                                isRequired={contentByLang?.can_repeat_failure?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={null}
                                haveError={contentByLang?.can_repeat_failure?.error}
                            />

                            <InputCheckboxCustom
                                name={contentByLang?.can_repeat_success?.ref}
                                label={contentByLang?.can_repeat_success?.label}
                                placeholder={contentByLang?.can_repeat_success?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.can_repeat_success?.ref}${contentByLang?.can_repeat_success?.error ? " error" : ""}`}
                                value={contentByLang?.can_repeat_success?.value}
                                help={contentByLang?.can_repeat_success?.help}
                                isRequired={contentByLang?.can_repeat_success?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={null}
                                haveError={contentByLang?.can_repeat_success?.error}
                            />

                            <InputCheckboxCustom
                                name={contentByLang?.random?.ref}
                                label={contentByLang?.random?.label}
                                placeholder={contentByLang?.random?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.random?.ref}${contentByLang?.random?.error ? " error" : ""}`}
                                value={contentByLang?.random?.value}
                                help={contentByLang?.random?.help}
                                isRequired={contentByLang?.random?.required}
                                styles={ formStyle }
                                label_icon={null}
                                labelPosition={"right"}
                                tooltip_content={null}
                                haveError={contentByLang?.random?.error}
                            />
                        </div>
                        <div className="field_double">
                            <SelectCustom 
                                name={contentByLang?.points_success?.ref}
                                label={contentByLang?.points_success?.label}
                                placeholder={contentByLang?.points_success?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.points_success?.ref}${contentByLang?.points_success?.error ? " error" : ""}`}
                                type="text"
                                value={contentByLang?.points_success?.value}
                                help={contentByLang?.points_success?.help}
                                isRequired={contentByLang?.points_success?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={contentByLang?.points_success?.tooltip}
                                haveError={contentByLang?.points_success?.error}
                                data={valuePoints}
                            />
                            <SelectCustom 
                                name={contentByLang?.points_failure?.ref}
                                label={contentByLang?.points_failure?.label}
                                placeholder={contentByLang?.points_failure?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${contentByLang?.points_failure?.ref}${contentByLang?.points_failure?.error ? " error" : ""}`}
                                type="text"
                                value={contentByLang?.points_failure?.value}
                                help={contentByLang?.points_failure?.help}
                                isRequired={contentByLang?.points_failure?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={contentByLang?.points_failure?.tooltip}
                                haveError={contentByLang?.points_failure?.error}
                                data={valuePoints}
                            />
                        </div>

                    </div>
                </div>

            </div>
            
        </WrapperStyled>
    );
}

export default React.memo(Form);