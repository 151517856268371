import React from "react";
import CreateContainer from "./CreateContainer";

const Create = (props) => {
  return (
      <CreateContainer {...props} />
  );
};

export default Create;
