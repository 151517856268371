import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorIcon,
         CheckIcon } from '../../helpers/icons';

const Wrapper = styled.div`

    position: absolute;
    right: 7px;
    top: 8px;
    z-index: 999;
    
    .validate_button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: ${props => `rgb(${props.panColors.white})`};
        font-weight: 700;
        font-size: 13px;
        border: 1px solid ${props => `rgb(${props.panColors.dots})`};
        background-color: ${props => `rgb(${props.panColors.dots})`};
        padding: 0 10px 0 10px;
        height: 27px;
        cursor: pointer;
        p{
            margin: 0;
        }
        :hover{
            background-color: ${props => `rgb(${props.panColors.white})`};
            color: ${props => `rgb(${props.panColors.dots})`};
        }
    }
    
    .loading{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid ${props => `rgb(${props.panColors.dots})`};
        background-color: ${props => `rgb(${props.panColors.dots})`};
        width: 27px;
        height: 27px;
        svg{
            color: ${props => `rgb(${props.panColors.white})`};
        }
    }
    .id_ok{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid ${props => `rgb(${props.panColors.green})`};
        background-color: ${props => `rgb(${props.panColors.green})`};
        width: 27px;
        height: 27px;

        svg{
            width: 12px;
            height: 12px;
            fill: ${props => `rgb(${props.panColors.white})`};
        }
    }
    .id_error{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid ${props => `rgb(${props.panColors.red})`};
        background-color: ${props => `rgb(${props.panColors.red})`};
        width: 27px;
        height: 27px;

        svg{
            width: 12px;
            height: 12px;
            fill: ${props => `rgb(${props.panColors.white})`};
        }
    }
    
`

const ValidateID = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { status } = props;

    return (
        <Wrapper
            panColors={panColors}
        >
            {status === "loading" ?
                <div className='loading'>
                    <CircularProgress size={15} />
                </div>
            :
            status == null ?

                <></>
            :
                status === true ?
                <div className='id_ok'>
                    <CheckIcon />
                </div>
                :
                <div className='id_error'>
                    <ErrorIcon />
                </div>
            
            }
        </Wrapper>
    )

}

export default ValidateID;