import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Wrapper = styled.div`

    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;

`

const OverlayWaiting = () => {

  return (
    <Wrapper>
      <CircularProgress size={45} />
    </Wrapper>

  )
  
}

export default OverlayWaiting;
