import React, { Suspense } from 'react';
import CreateContainer from "./CreateContainer";

const CreateUser = (props) => {
    return (
        <CreateContainer {...props} />
    );
};

export default CreateUser;


