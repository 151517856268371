import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import Form from '../../../components/Segmentation/Form';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';

const WrapperStyled = styled.div`
    width: 100%;
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const CreateContainer = (props) => {

    const navigate = useNavigate();

    const { panColors } = useContext(ConfigurationContext);

    const {isDialog = false,
           setSegmentationOpen = null,setSegments}  = props;

    const [ loadingForm, setLoadingForm ] = useState(false);

    const [ countries, setCountries ] = useState([]);
    const [ areas, setAreas ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    const [ clusters, setClusters ] = useState([]);
    const [ retailers, setRetailers ] = useState([]);
    const [ formats, setFormats ] = useState([]);
    const [ idioms, setIdioms ] = useState([]);
    const [ departments, setDepartments ] = useState([]);
    const [ flags, setFlags ] = useState([]);
    const [ noSegmentation, setNoSegmentation ] = useState(null);
    const [ title, setTitle ] = useState("");
    
    const handleSubmit = () => {

        let _segments = {
            countries: countries,
            areas: areas,
            roles: roles,
            regions: regions,
            clusters: clusters,
            retailers: retailers,
            formats: formats,
            idioms: idioms,
            flags: flags
        }

        setSegments(_segments);
    }


    return (
        <WrapperStyled pancolors={panColors}>
            
            <Form 
                title={title}
                countries={countries} setCountries={setCountries}
                areas={areas} setAreas={setAreas}
                roles={roles} setRoles={setRoles}
                regions={regions} setRegions={setRegions}
                clusters={clusters} setClusters={setClusters}
                retailers={retailers} setRetailers={setRetailers}
                formats={formats} setFormats={setFormats}
                idioms={idioms} setIdioms={setIdioms}
                departments={departments} setDepartments={setDepartments}
                flags={flags} setFlags={setFlags}
                noSegmentation={noSegmentation} setNoSegmentation={setNoSegmentation}
                dataLoaded={true}
            />
            
            <div className="actions">
                <ButtonCustom 
                    className="bt_submit"
                    text={loadingForm ? <LoaderComponent 
                            size={25}
                            color={panColors.white}
                            /> : "Continuar y Guardar segmentación"}
                    onClick={handleSubmit}
                    style_type="normal"
                    
                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />
                <ButtonTextCustom 
                    className="bt_cancel"
                    text="Cancelar"
                    onClick={() => isDialog ? setSegmentationOpen(false) : navigate(-1)}

                    icon={null}
                    background={{ normal: "", hover: "" }}
                    border={{ normal: "", hover: "" }}
                    textcolor={{ normal: panColors.red, hover: panColors.red }}
                />
            </div>
            

        </WrapperStyled>
    );
}

export default CreateContainer;