import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ContentContext,
         ConfigurationContext } from '../../context';
    
import TableWallInteractions from './../elements/DataTable/TableWallInteractions';


const Wrapper = styled.div`
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.fontColorError.normal})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.fontColorError.hover})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.buttonIconColor.normal})`};
      :hover{
        color: ${props => `rgb(${props.buttonIconColor.hover})`};
      }
    }
  }
`

const WallInteractionsTable = () => {

    const { getUsers,
            users, 
            setUsers } = useContext(ContentContext);

    const { colors } = useContext(ConfigurationContext);

    const headCells = [
        {
            id: 'image',
            numeric: false,
            disablePadding: false,
            label: 'Imágen',
            align: 'left',
        },
        {
            id: 'post',
            numeric: false,
            disablePadding: false,
            label: 'Posteo',
            align: 'left',
        },
        {
            id: 'date',
            numeric: true,
            disablePadding: false,
            label: 'Fecha de Interacción',
            align: 'left',
        },
        {
            id: 'like',
            numeric: false,
            disablePadding: false,
            label: 'Like',
            align: 'left',
        },
        {
            id: 'comment',
            numeric: false,
            disablePadding: false,
            label: 'Comentario',
            align: 'left',
        }
    ];
    
    const courses = {
        total: 200,
        courses: [
            {
                id: 1,
                image: 'https://picsum.photos/300/300',
                post: 'Nombre del posteo, Hasta tres lineas de texto visible',
                date: '14:32 - 12/11/2022',
                like: true,
                comment: '',
            },
            {
                id: 2,
                image: 'https://picsum.photos/300/300',
                post: 'Nombre del posteo, Hasta tres lineas de texto visible',
                date: '14:32 - 12/11/2022',
                like: false,
                comment: 'Comentario del usuario, Hasta tres lineas de texto visible',
            },
            {
                id: 3,
                image: 'https://picsum.photos/300/300',
                post: 'Nombre del posteo, Hasta tres lineas de texto visible',
                date: '14:32 - 12/11/2022',
                like: false,
                comment: 'Comentario del usuario, Hasta tres lineas de texto visible',
            },
            {
                id: 4,
                image: 'https://picsum.photos/300/300',
                post: 'Nombre del posteo, Hasta tres lineas de texto visible',
                date: '14:32 - 12/11/2022',
                like: true,
                comment: '',
            }
        ]
    };

  

    useEffect(() => {
        
        if(users === null){
            getUsers(0, 25);
        }

    }, [users])

    useEffect(() => {
        return () => {
            setUsers(null);
        }
    }, [])

    if(users === null){
        return null;
    }

    return (
        <Wrapper {...colors}>
        
        {users &&
            <TableWallInteractions
                headCells={headCells} 
                type={"users"}
                title={""} 
                data={courses.courses} 
                getData={getUsers}
                totalRows={courses.total}
            />
        }
        
        </Wrapper>
    );
}

export default WallInteractionsTable;