import React, { Suspense } from 'react';
import CommentsContainer from "./CommentsContainer";

const CommentsPosts = (props) => {
    return (
        <CommentsContainer {...props} />
    );
};

export default CommentsPosts;


