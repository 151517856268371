import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';

const Wrapper = styled.div`

    display: flex;
    flex-direction: ${props => props.icon_side === "left" ? "row" : "row-reverse"};
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    height: 44px;
    background-color: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.background.normal})`};
    border: 1px solid ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.border.normal})`};
    border-radius: 4px;
    box-sizing: border-box;
    
    cursor: ${props => props.disabled ? "default" : "pointer"};
    >.text{
        margin: 0;
        color: ${props => `rgb(${props.disabled ? props.pancolors.white : props.textcolor.normal})`};
        font-weight: 600;
        font-size: 16px;
    }
    .icon{
        display: flex;
        margin-right: ${props => props.icon_side === "left" ? "7px" : "0px"};
        margin-left: ${props => props.icon_side === "rigth" ? "7px" : "0px"};
        svg{
            fill: ${props => `rgb(${props.disabled ? props.pancolors.white : props.textcolor.normal})`};
        }
        
    }
    :hover{

        background-color: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.background.hover})`};
        border: 1px solid ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.border.hover})`};
        >.text{
            color: ${props => `rgb(${props.disabled ? props.pancolors.white : props.textcolor.hover})`};
        }
        .icon{
            svg{
                fill: ${props => `rgb(${props.textcolor.hover})`};
                color: ${props => `rgb(${props.textcolor.hover})`};
            }
        }
    }
    
`

const ButtonCustom = (props) => {

    const { text,
            onClick,
            className,
            style_type,
            background, 
            border, 
            textcolor,
            icon,
            icon_side = "left",
            disabled } = props;

    const { panColors } = useContext(ConfigurationContext);
    
    return (
        <Wrapper 
            background={background}
            border={border}
            textcolor={textcolor} 
            onClick={disabled ? null : onClick} 
            className={className} 
            style_type={style_type}
            haveText={text ? true : false}
            disabled={disabled}
            pancolors={panColors}
            icon_side={icon_side}
        >
            
            {icon && <div className="icon">{icon}</div>}
            {text && <div className="text">{text}</div>}
            

        </Wrapper>

    )

}

export default ButtonCustom;