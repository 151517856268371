import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    
    display: flex;
    margin-right: 5px;
    min-height: 30px;
    min-width: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    svg{
        fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
    }
`

const IconCustom = (props) => {

    const { panColors } = useContext(ConfigurationContext);
    const { className, icon } = props;
            
    return (
        <WrapperStyled pancolors={panColors} className={className}>
            { icon }
        </WrapperStyled>
    )

}

export default IconCustom;