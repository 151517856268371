import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';

import DeleteElement from "../Dialogs/DeleteElement";
import ActivateElement from "../Dialogs/ActivateElement";
import DeactivateElement from "../Dialogs/DeactivateElement";

import { TrashIcon,
         AddBusinessIcon,
         DeactivateBusinessIcon } from '../../../helpers/icons';

import { BiTrash, BiPencil } from "react-icons/bi";
import { HiEye } from "react-icons/hi2";

import { ConfigurationContext } from '../../../context';

import { useNavigate, useLocation } from 'react-router-dom';

import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { onSelectAllClick, 
          order, 
          orderBy, 
          numSelected, 
          rowCount, 
          onRequestSort, 
          headCells,
          settings } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {(settings.actions.filter(col => col === 'delete')).length ?
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> : ""}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorteable ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            :
            headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const WrapperStyled = styled.div`
    .inner_avatar{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .icon{
        fill: #FFFFFF!important;
    }
    .MuiToolbar-root{
        padding-left: 15px;
    }
    .MuiTableRow-root{
        &.Mui-selected{
            background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
            :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
            }
        }
        &.MuiTableRow-hover{
            :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
            }
        }
    }

    .bt_bulk_delete{

    }
    .bt_bulk_activate{
        margin-left: 10px;
    }
    .bt_bulk_desactivate{
        margin-left: 10px;
    }
    .Mui-checked{
        color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
    }
    .MuiCheckbox-indeterminate{
        color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
    }
    
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.pancolors.red})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.pancolors.dots})`};
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
  }
`

const ToolbarStyled = styled(Toolbar)`

  &.MuiToolbar-root{
    background-color: ${props => `${props.numselected ? "rgba("+props.pancolors.dots+", 0.03)" : "transparent"}` }!important;
  }

`

function EnhancedTableToolbar(props) {

    const { numSelected,
            title,
            colors,
            panColors,
            selected,
            setSelected,
            setRows,
            getData,
            page,
            rowsPerPage,
            filters,
            clientData } = props;


    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ titleActivateText, setTitleActivateText ] = useState("");
    const [ titleDeactivateText, setTitleDeactivateText ] = useState("");
  
    const [ elementToDelete, setElementToDelete ] = useState("");
    const [ elementToActivate, setElementToActivate ] = useState("");
    const [ elementToDeactivate, setElementToDeactivate ] = useState("");
  
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ activateElementOpen, setActivateElementOpen ] = useState(false);
    const [ deactivateElementOpen, setDeactivateElementOpen ] = useState(false);
  
    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ isActivated, setIsActivated ] = useState(false);
    const [ isDeactivated, setIsDeactivated ] = useState(false);
  
    const handleDeleteRows = () => {
      const id_list = selected.map((id, index) => {
        return {_id: id};
      })
      setElementToDelete(JSON.stringify(id_list));
      setTitleDeleteText(`<span class="bolder">¿Confirmar que desea Eliminar ${selected.length} ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}${selected.length > 1 ? (clientData.name_clients !== "one_sp" ? "s" : "es") : ""}?</span>`);
      setDeleteElementOpen(true);
    }
  
    const handleActivateRows = () => {
      const id_list = selected.map((id, index) => {
        return {_id: id};
      })
      setElementToActivate(JSON.stringify(id_list));
      setTitleActivateText(`<span class="bolder">¿Confirmar que desea Activar ${selected.length} ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}${selected.length > 1 ? (clientData.name_clients !== "one_sp" ? "s" : "es") : ""}?</span>`);
      setActivateElementOpen(true);
    }
  
    const handleDesactivateRows = () => {
      const id_list = selected.map((id, index) => {
        return {_id: id};
      })
      setElementToDeactivate(JSON.stringify(id_list));
      setTitleDeactivateText(`<span class="bolder">¿Confirmar que desea Desactivar ${selected.length} ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}${selected.length > 1 ? (clientData.name_clients !== "one_sp" ? "s" : "es") : ""}?</span>`);
      setDeactivateElementOpen(true);
    }
  
    useEffect(() => {
      
      if(isDeleted){
        setRows([]);
        getData(page, rowsPerPage, filters);
        setElementToDelete(null);
        setTitleDeleteText('');
        setDeleteElementOpen(false);
        setIsDeleted(false);
        setSelected([]);
      }
  
      if(isActivated){
        setRows([]);
        getData(page, rowsPerPage, filters);
        setElementToActivate(null);
        setTitleActivateText('');
        setActivateElementOpen(false);
        setIsActivated(false);
        setSelected([]);
      }
  
      if(isDeactivated){
        setRows([]);
        getData(page, rowsPerPage, filters);
        setElementToDeactivate(null);
        setTitleDeactivateText('');
        setDeactivateElementOpen(false);
        setIsDeactivated(false);
        setSelected([]);
      }
    
      return () => {}
    }, [isDeleted, isActivated, isDeactivated])

    
  return (
    <ToolbarStyled
        {...colors}
        pancolors={panColors}
        numselected={numSelected}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >

      <DeleteElement 
        open={deleteElementOpen} 
        setOpen={setDeleteElementOpen} 
        title={titleDeleteText}
        setTitle={setTitleDeleteText}
        id={elementToDelete}
        scope="stores"
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
      />

      <ActivateElement 
        open={activateElementOpen} 
        setOpen={setActivateElementOpen} 
        title={titleActivateText}
        setTitle={setTitleActivateText}
        id={elementToActivate}
        scope="stores"
        isActivated={isActivated}
        setIsActivated={setIsActivated}
      />

      <DeactivateElement 
        open={deactivateElementOpen} 
        setOpen={setDeactivateElementOpen} 
        title={titleDeactivateText}
        setTitle={setTitleDeactivateText}
        id={elementToDeactivate}
        scope="stores"
        isDeactivated={isDeactivated}
        setIsDeactivated={setIsDeactivated}
      />

      {numSelected > 0 ? (
        <></>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          { title }
        </Typography>
      )}
         
      {numSelected > 0 ? (
        <>
        <ButtonTextCustom 
            className="bt_bulk_delete"
            text="Eliminar"
            onClick={() => handleDeleteRows()}

            icon={<TrashIcon />}
            background={{ normal: "", hover: panColors.red }}
            border={{ normal: panColors.primary, hover: panColors.primary }}
            textcolor={{ normal: panColors.red, hover: panColors.red }}
        />
        {filters.status == -2 &&
          <ButtonTextCustom 
              className="bt_bulk_activate"
              text="Activar"
              onClick={() => handleActivateRows()}
              style_type="normal"
              button_type="activate"
              
              icon={<AddBusinessIcon />}
              background={{ normal: panColors.primary, hover: panColors.primary }}
              border={{ normal: panColors.primary, hover: panColors.primary }}
              textcolor={{ normal: panColors.primary, hover: panColors.primary }}
          />
        }
        {filters.status == 1 &&
          <ButtonTextCustom 
              className="bt_bulk_desactivate"
              text="Desactivar"
              onClick={() => handleDesactivateRows()}
              style_type="normal"
              button_type="desactivate"

              icon={<DeactivateBusinessIcon />}
              background={{ normal: panColors.primary, hover: panColors.primary }}
              border={{ normal: panColors.primary, hover: panColors.primary }}
              textcolor={{ normal: panColors.primary, hover: panColors.primary }}
          />
        }
        </>
      ) : (
        <></>
        
      )}
    </ToolbarStyled>
  );
}


const TableStores = (props) => {

    const { headCells,
            title,
            data,
            getData,
            totalRows,
            filters,
            settings } = props;

  const navigate = useNavigate();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [ titleDeleteText, setTitleDeleteText ] = useState("");
  const [ elementToDelete, setElementToDelete ] = useState("");
  const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
  const [ isDeleted, setIsDeleted ] = useState(false);
  
  const { colors,
          panColors,
          clientData } = useContext(ConfigurationContext);
  
  const handleDelete = (id, fullname) => {
    setElementToDelete(JSON.stringify([{_id: id}]));
    setTitleDeleteText(`<span class="bolder">¿Confirmar Eliminar la ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}</span> ${fullname}<span class="bolder">?</span>`);
    setDeleteElementOpen(true);
  }

  useEffect(() => {

    if(isDeleted){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToDelete(null);
      setTitleDeleteText('');
      setDeleteElementOpen(false);
      setIsDeleted(false);
    }
  
    return () => {}
  }, [isDeleted])

  useEffect(() => {
      
    if(data === null){
      getData(0, rowsPerPage, filters);
    }
    
    if(data && data.length){
      setRows(data);
    }
    return () => {}

  }, [data])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage, rowsPerPage, filters);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    getData(0, parseInt(event.target.value, 10, filters));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


    const isSelected = (name) => selected.indexOf(name) !== -1;
  
  if(data === null){
    return null;
  }

  return (
    <WrapperStyled pancolors={panColors}>

        <DeleteElement 
          open={deleteElementOpen} 
          setOpen={setDeleteElementOpen} 
          title={titleDeleteText}
          setTitle={setTitleDeleteText}
          id={elementToDelete}
          scope="stores"
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />

        <EnhancedTableToolbar 
            numSelected={selected.length} 
            selected={selected}
            setSelected={setSelected}
            title={title}
            colors={colors}
            panColors={panColors}
            setRows={setRows}
            getData={getData}
            page={page}
            rowsPerPage={rowsPerPage}
            filters={filters}
            clientData={clientData}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              settings={settings}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  
                    return (
                        <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                        >

                            {(settings.actions.filter(col => col === 'delete')).length ?
                            <TableCell padding="checkbox">
                                <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId, }}
                                />
                            </TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'name')).length ?
                            <TableCell align="left">{row.name ? row.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'code')).length ?
                            <TableCell align="left">{row.code ? row.code : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'region')).length ?
                            <TableCell align="left">{row.region ? row.region.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'area')).length ?
                            <TableCell align="left">{row.area ? row.area.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'cluster')).length ?
                            <TableCell align="left">{row.cluster ? row.cluster.name : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'address')).length ?
                            <TableCell align="left">{row.address ? row.address : "- - -"}</TableCell> : ""}

                            {(settings.section_list_columns.filter(col => col.id === 'format')).length ?
                            <TableCell align="left">{row.format.length ? row.format[0].name : "- - -"}</TableCell> : ""}

                            
                            <TableCell align="center">
                                <div className="actions">
                                    <Tooltip title={"Ver más"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_view_single' onClick={() => navigate(`/stores/${row._id}/view`)}>
                                            <HiEye />
                                        </div>
                                    </Tooltip>
                                    {settings.actions.length > 1 ?
                                    <React.Fragment>
                                    <Tooltip title={"Editar"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_edit_single' onClick={() => navigate(`/stores/${row._id}/edit`)}>
                                            <BiPencil />
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={"Eliminar"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_delete_single' onClick={() => handleDelete(row._id, row.name)}>
                                            <BiTrash />
                                        </div>
                                    </Tooltip>
                                    </React.Fragment> : ""}
                                </div>
                            </TableCell>

                        </TableRow>
                    );
                  
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      
    </WrapperStyled>
  );
}

export default TableStores;