import React, { useState, useCallback } from 'react';
import { path } from 'ramda';
import connect from '../helpers/connect';

export const ConfigurationContext = React.createContext({});

export const ConfigurationProvider = ({ children }) => {

    const [ sidebarMenuSelected, setSidebarMenuSelected ] = useState(null);
    const [ haveResponseError, setHaveResponseError ] = useState(false);
    const [ haveResponseErrorMessage, setHaveResponseErrorMessage ] = useState("");
    const [ clientData, setClientData ] = useState(null);
    const [ menuData, setMenuData ] = useState(null);
    const [ userCountrySelected, setUserCountrySelected ] = useState(null);
    
    const [ colors, setColors ] = useState({
        backgroundColor: { primary: "109,105,239", secondary: "244,106,155"},
        fontColor: { primary: "10,10,10", secondary: "179,61,198"},
        borderColor: { primary: "237,191,51", secondary: "237,225,91"},
        buttonColor: { normal: "109,105,239", hover: "109,105,239", fontNormal: "109,105,239", fontHover: "109,105,239"},
        buttonIconColor: { normal: "0,119,182", hover: "25,25,25" },
        fontColorError: { normal: "240,51,51", hover: "25,25,25" },
        errorColor: { primary: "240,51,51" },
        iconColor: { primary: "61,56,213" }, 
    });
    const [ fonts, setFonts ] = useState({
        primary: "255,255,255", secondary: "109,105,239"
    });

    const [ panColors, setPanColors ] = useState({
        primary: "109,105,239", 
        secondary: "36,34,49",
        
        black: "36,34,49",
        black_overwhite: "72,77,99",
        dark_gray: "108,108,153",
        middle_gray: "186,186,194",
        light_gray: "226,228,236",
        dots: "103,97,234",
        red: "220,46,46",
        green: "18,136,51",
        yellow: "186,141,53",
        white: "255,255,255",
        light_white: "247,248,252"
    })

    const getPanelConfiguration = useCallback(async () => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/information_panel`;

        connect.get(`${requestURL}`).then(result => { 

            localStorage.setItem('client_data', JSON.stringify(path(['data', 'single'], result)));
            setClientData(path(['data', 'single'], result));

        }).catch(err => {

           

        });
        

	}, []);

    const getMenu = useCallback(async () => {
        /*
        const requestURL = `${process.env.REACT_APP_BASE_URL}/onepanel/utils/get_data`;
        connect.get(`${requestURL}`).then(res => {
            setMenuData(path(['data', 'single', 'menu', 'options'], res));
                          
        }).catch(err => {

            //setHaveResponseError(true);
            //setHaveResponseErrorMessage(err.message);

        });
        */
	}, []);
    

    return (
        <ConfigurationContext.Provider 
            value={{
                colors, setColors,
                fonts, setFonts,
                panColors, setPanColors,
                haveResponseError, setHaveResponseError,
                haveResponseErrorMessage, setHaveResponseErrorMessage,
                sidebarMenuSelected, setSidebarMenuSelected,
                userCountrySelected, setUserCountrySelected,
                clientData, setClientData,
                menuData, setMenuData,
                getPanelConfiguration,
                getMenu
            }}>
            {children}
        </ConfigurationContext.Provider>
    );
};
