import React, { Fragment, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import DeleteElement from "../../../components/elements/Dialogs/DeleteElement";
import ActivateElement from "../../../components/elements/Dialogs/ActivateElement";
import DeactivateElement from "../../../components/elements/Dialogs/DeactivateElement";

import { ExclamationIcon, 
         LocalizationAreaIcon, 
         CountryIcon,
         UserIcon,
         ClockCheckinIcon,
         ClusterIcon,
         EditIcon,
         FormatIcon,
         LatitudeIcon,
         LongitudeIcon,
         RegionIcon,
         TrashIcon,
         RetailersIcon1,
         AddBusinessIcon,
         DeactivateBusinessIcon } from '../../../helpers/icons';

         


import { AuthContext,
         ContentContext,
         ConfigurationContext } from '../../../context';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .loader{
        width: 100%;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-top: 30px;
        .bt_edit,
        .bt_deactivate,
        .bt_delete{
            margin-right: 10px;
        }
        .bt_delete{
            margin-right: 0;
        }
    }
    .content_store_data{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
            .store_data{
                margin-left: 0;
                p{
                    display: flex;
                    flex-direction: row;
                    font-size: 18px;
                    line-height: 22px;
                    color: #484D63;
                    margin: 0;
                    .text{
                        margin-left: 5px;
                    }
                }
                p.name{
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 700;
                }
                p.code{
                    font-weight: 600;
                    margin-top: 10px;
                }
                p.address{
                    margin-top: 5px;
                    font-size: 16px;
                }
                p.coid{
                    margin-top: 5px;
                }
            }
        
    }
    .content_general_data{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        width: 100%;
        .data_columns{
            display: flex;
            flex-direction: column;
            .icon{
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-right: 5px;
                width: 24px;
                svg{
                    fill: ${props => `rgb(${props.pancolors.black_overwhite})`};
                }
            }
            .data_column{
                margin-bottom: 40px;
                .title{
                    font-size: 19px;
                    font-weight: 600;
                    color: #484D63;
                    margin-bottom: 20px;
                }
                .no_aditional{
                    color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                }
            }
            .data_column:last-child{
                margin-bottom: 0;
            }
            ul.elements{
                margin: 0;
                padding: 0;
                li.element{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #767676;
                    font-size: 16px;
                    line-height: 19px;
                    list-style: none;
                    margin-bottom: 20px;
                    .text{
                        display: flex;
                        margin-left: 5px;
                    }
                }
                li.element:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .accordion_elements{
        margin-top: 50px;
        width: 100%;
    }
    .highlighted{
        display: flex;
        font-weight: 700;
    }
`

const ViewContainer = (props) => {
    
    const { store, 
            setStore,
            getStore,
            basicData } = useContext(ContentContext);

    const { panColors,
            clientData } = useContext(ConfigurationContext);
            
    const [ accordionContentShow, setAccordionContentShow ] = useState(null);

    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ isActivated, setIsActivated ] = useState(false);
    const [ isDeactivated, setIsDeactivated ] = useState(false);

    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ elementToDelete, setElementToDelete ] = useState("");
    const [ activateElementOpen, setActivateElementOpen ] = useState(false);
    const [ deactivateElementOpen, setDeactivateElementOpen ] = useState(false);

    const [ titleActivateText, setTitleActivateText ] = useState("");
    const [ titleDeactivateText, setTitleDeactivateText ] = useState("");
    const [ elementToDeactivate, setElementToDeactivate ] = useState("");
    const [ elementToActivate, setElementToActivate ] = useState("");

    const navigate = useNavigate();
    const { id_store, slug } = useParams();
    
    useEffect(() => {

        getStore(id_store);
        
      return () => {}
    }, [])

    useEffect(() => {
      return () => {
        setStore(null);
      }
    }, [])

    const handleDelete = (id, fullname) => {
        setElementToDelete(JSON.stringify([{_id: id}]));
        setTitleDeleteText(`<span class="bolder">¿Confirmar Eliminar la ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}</span> ${fullname}<span class="bolder">?</span>`);
        setDeleteElementOpen(true);
    }

    const handleActivateStore = (id, fullname) => {
        setElementToActivate(JSON.stringify([{_id: id}]));
        setTitleActivateText(`<span class="bolder">¿Confirmar Activar esta ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}</span> ${fullname}<span class="bolder">?</span>`);
        setActivateElementOpen(true);
    }

    const handleDeactivateStore = (id, fullname) => {
        setElementToDeactivate(JSON.stringify([{_id: id}]));
        setTitleDeactivateText(`<span class="bolder">¿Confirmar Desactivar esta ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}</span> ${fullname}<span class="bolder">?</span>`);
        setDeactivateElementOpen(true);
    }

    useEffect(() => {

        if(isDeleted){
            setIsDeleted(false);
            navigate(`/stores`);
        }
        return () => {}

    }, [isDeleted])

    useEffect(() => {
    
      return () => {
        setIsDeleted(false);
      }
      
    }, [])

    useEffect(() => {

        if(isActivated){
            const activated_update = {...store};
            activated_update.status = 0;
            setStore(activated_update)
            setIsActivated(false);
            setElementToActivate(null);
            setActivateElementOpen(false);
        }
        if(isDeactivated){
            const deactivated_update = {...store};
            deactivated_update.status = -2;
            setStore(deactivated_update);
            setIsDeactivated(false);
            setElementToDeactivate(null);
            setDeactivateElementOpen(false);
        }
        return () => {}
        
      }, [isDeactivated, isActivated])
    

    return (
        <WrapperStyled 
            pancolors={panColors}
            className="dashboard"
        >
            
            <DeleteElement 
                open={deleteElementOpen} 
                setOpen={setDeleteElementOpen} 
                title={titleDeleteText}
                setTitle={setTitleDeleteText}
                id={elementToDelete}
                scope="stores"
                isDeleted={isDeleted}
                setIsDeleted={setIsDeleted}
            />

            <DeactivateElement
                open={deactivateElementOpen} 
                setOpen={setDeactivateElementOpen} 
                title={titleDeactivateText}
                setTitle={setTitleDeactivateText}
                id={elementToDeactivate}
                scope="stores"
                isDeactivated={isDeactivated}
                setIsDeactivated={setIsDeactivated}
            />
 
            <ActivateElement
                open={activateElementOpen} 
                setOpen={setActivateElementOpen} 
                title={titleActivateText}
                setTitle={setTitleActivateText}
                id={elementToActivate}
                scope="stores"
                isActivated={isActivated}
                setIsActivated={setIsActivated}
            />
            
            {store === null || store === undefined || basicData === null ?
                <div className="loader">
                    <LoaderComponent />
                </div>
            :    
                Object.keys(store).length ?
                    <Fragment>
                        
                        <div className="content_store_data">
                            <div className="store_data">
                                <p className="name">{ store?.name }</p>
                                <p className="code">Código { store?.code }</p>
                                <p className="address">Dirección: { store?.address }</p>
                            </div>

                        </div>
                        
                        
                        {basicData.settings.store.actions.length > 1 ?
                        <div className="actions">
                            <ButtonCustom 
                                className="bt_edit"
                                text="Editar datos"
                                onClick={() => navigate(`/stores/${id_store}/edit`)}
                                style_type="normal"
                                
                                icon={<EditIcon />}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                            {store.status === 0 || store.status === 1 ?
                                <ButtonCustom 
                                    className="bt_deactivate"
                                    text={`Desactivar ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}`}
                                    onClick={() => handleDeactivateStore(store._id, store.name)}
                                    style_type="invert"

                                    icon={<DeactivateBusinessIcon />} 
                                    background={{ normal: panColors.white, hover: panColors.primary }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                />
                                :
                                <ButtonCustom 
                                    className="bt_deactivate"
                                    text={`Activar ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}`}
                                    onClick={() => handleActivateStore(store._id, store.name)}
                                    style_type="invert"

                                    icon={<AddBusinessIcon />}
                                    background={{ normal: panColors.white, hover: panColors.primary }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                />
                            }
                            <ButtonCustom 
                                className="bt_delete"
                                text={`Eliminar ${clientData.name_clients !== "one_sp" ? "tienda" : "estación"}`}
                                onClick={() => handleDelete(store?._id, store?.name)}
                                style_type="invert"

                                icon={<TrashIcon />}
                                background={{ normal: panColors.white, hover: panColors.red }}
                                border={{ normal: panColors.red, hover: panColors.red }}
                                textcolor={{ normal: panColors.red, hover: panColors.white }}
                            />
                        </div> : ""}

                        <div className="content_general_data">
                            
                            <div className="data_columns">
                                <div className="data_column">
                                    <div className="title">
                                        Datos
                                    </div>
                                    <ul className="elements">

                                        {basicData.settings.store.section_fields.country.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <CountryIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.country.label}:</span>
                                            <span className="text">{ store?.country ? store.country.name : "---" }</span>
                                        </li> : ""}
                                        
                                        {basicData.settings.store.section_fields.name_store.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.name_store.label}:</span>
                                            <span className="text">{ store?.name ? store.name : "---" }</span>
                                        </li> : ""}
                                        
                                        {basicData.settings.store.section_fields.code.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.code.label}:</span>
                                            <span className="text">{ store?.code ? store.code : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.maximum_distance_for_checking.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <ClockCheckinIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.maximum_distance_for_checking.label}:</span> 
                                            <span className="text">{ store?.maximum_distance_for_checking ? store.maximum_distance_for_checking : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.address.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <LatitudeIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.address.label}:</span>
                                            <span className="text">{ store?.address ? store.address : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.lat.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <LongitudeIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.lat.label}:</span>
                                            <span className="text">{ store?.lat ? store.lat : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.lng.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <LongitudeIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.lng.label}:</span> 
                                            <span className="text">{ store?.lng ? store.lng : "---" }</span>
                                        </li> : ""}
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="data_columns">
                                <div className="data_column">
                                    <div className="title">
                                        Datos
                                    </div>
                                    <ul className="elements">

                                        {basicData.settings.store.section_fields.retailer.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <RetailersIcon1 />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.retailer.label}:</span>
                                            <span className="text">{ store?.retailer ? store.retailer.name : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.area.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <LocalizationAreaIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.area.label}:</span>
                                            <span className="text">{ store?.area ? store.area.name : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.region.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <RegionIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.region.label}:</span>
                                            <span className="text">{ store?.region ? store.region.name : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.flag.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <RegionIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.flag.label}:</span>
                                            <span className="text">{ store?.flag ? store.flag.name : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.format.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <FormatIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.format.label}:</span>
                                            <span className="text">{ store?.format ? store.format.name : "---" }</span>
                                        </li> : ""}

                                        {basicData.settings.store.section_fields.cluster.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <ClusterIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.store.section_fields.cluster.label}:</span>
                                            <span className="text">{ store?.cluster ? store.cluster.name : "---" }</span>
                                        </li> : ""}
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="data_columns">
                                <div className="data_column">

                                    <div className="title">
                                        Información adicional
                                    </div>
                                    {!store?.additional.length ? 
                                        <div className='no_aditional'>No hay información adicional para mostrar.</div>
                                    :
                                        <ul className="elements">
                                            <li className="element">
                                                <span className="icon">
                                                    <ExclamationIcon />
                                                </span>
                                                <span className="highlighted">Info adicional 1:</span>
                                                <span className="text">***************************</span>
                                            </li>
                                            <li className="element">
                                                <span className="icon">
                                                    <ExclamationIcon />
                                                </span>
                                                <span className="highlighted">Info adicional 2:</span>
                                                <span className="text">***************************</span>
                                            </li>
                                            <li className="element">
                                                <span className="icon">
                                                    <ExclamationIcon />
                                                </span>
                                                <span className="highlighted">Info adicional 3:</span>
                                                <span className="text">***************************</span>
                                            </li>
                                            <li className="element">
                                                <span className="icon">
                                                    <ExclamationIcon />
                                                </span>
                                                <span className="highlighted">Info adicional 4:</span>
                                                <span className="text">***************************</span>
                                            </li>
                                            <li className="element">
                                                <span className="icon">
                                                    <ExclamationIcon />
                                                </span>
                                                <span className="highlighted">Info adicional 5:</span>
                                                <span className="text">***************************</span>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                        </div>
                    </Fragment>
                :
                    <>no hay datos</>
            }
        </WrapperStyled>

    )

}

export default ViewContainer;
