import React, { Fragment, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DateTime } from "luxon";
import Avatar from '@mui/material/Avatar';
import AccordionElement from '../../../../components/elements/Accordion';
import { MdImportantDevices,
         MdOutlineAddReaction } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";

import Devices from "../../../../components/Users/Devices";
import UserSessions from "../../../../components/Users/UserSessions";
import CoursesTable from "../../../../components/Users/CoursesTable";
import WallInteractionsTable from "../../../../components/Users/WallInteractionsTable";
import LoaderComponent from '../../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../../components/elements/ButtonCustom';
import DeleteElement from "../../../../components/elements/Dialogs/DeleteElement";
import IconCustom from '../../../../components/elements/IconCustom'; 
import ButtonTextCustom from '../../../../components/elements/ButtonTextCustom';

import { SavingsIcon,
         HistoryIcon,
         EditIcon,
         SegmentarionIcon,
         UserDesactivateIcon,
         TrashIcon,
         MatchCaseIcon,
         ListAltIcon,
         InfoIcon,
         CalendarMonthIcon,
         OtherAdmissionIcon,
         ViewHeadlineIcon } from '../../../../helpers/icons';
             
import { retrieveAreaDescription } from '../../../../helpers/functions';  

import { AuthContext,
         ContentContext,
         ConfigurationContext } from '../../../../context';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .loader{
        margin-top: 30px;
        width: 100%;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        .bt_edit,
        .bt_deactivate,
        .bt_delete{
            margin-right: 10px;
        }
        .bt_delete{
            margin-right: 0;
            margin-left: 10px;
        }
    }
    .box_container{
        margin-top: 30px;
        .highlighted{
            font-weight: 600;
        }
        .title{
            font-size: 30px;
            line-height: 35px;
            font-weight: 600;
        }
        .data_title{
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            margin-top: 30px;
        }

        .benefit_data{
            display: flex;
            flex-direction: column;
            .top_data{
                display: flex;
                flex-direction: row;
                align-items: center;
                .top_data_left{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-right: 50px;
                    width: 700px;

                    .image{
                        width: 291px;
                        margin-right: 30px;
                        img{
                            float: left;
                            width: 100%;
                        }
                    }   
                    .text{
                        width: calc(100% - 321px);
                        .title{
                            font-size: 24px;
                            line-height: 30px;
                            margin-bottom: 10px;
                            color: #484D63;
                        }
                        .description{
                            font-size: 18px;
                            line-height: 22px;
                            color: #6C6C99;
                        }
                    }
                }
                .top_data_rigth{
                    display: flex;
                    flex-direction: column;
                    .points,
                    .redeems{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .icon{
                            width: 40px;
                            margin-right: 20px;
                            svg{
                                fill: ${props => `rgb(${props.pancolors.black_overwhite})`};
                                float: left;
                                width: 100%;
                            }
                        }
                        .text{
                            color: ${props => `rgb(${props.pancolors.dark_gray})`};
                            .label{
                                font-size: 18px;
                                line-height: 18px;
                                font-weight: 600;
                                margin-bottom: 5px;
                            }
                            .value{
                                font-size: 32px;
                                line-height: 32px;
                                font-weight: 700;
                            }
                        }
                    }
                    .points{
                        margin-bottom: 30px;
                    }
                    .redeems{
                    }
                }
            }
            .bottom_data{
                width: 1100px;
                margin-top: 30px;
                .grid_data{
                    display: grid; 
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 30px;
                    color: #6C6C99;
                    .element{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        .icon{
                            padding-top: 3px;
                            margin-right: 10px;
                            svg{
                                fill: ${props => `rgb(${props.pancolors.black_overwhite})!important`};
                            }
                        }
                        >.description,
                        >.terms,
                        >.publication_date,
                        >.vigency{
                            //background-color: skyblue;
                            width: calc(100% - 32px);
                        }
                    }
                    .description{
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .terms{
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .vigency{
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .publication_date{
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

    }
    
`

const ViewContainer = (props) => {

    const { window } = props;

    const { isAuth,
            isAuthLoading,
            signIn,
            signOut } = useContext(AuthContext);
    
    const { benefit,
            setBenefit,
            getBenefit,
            basicData,
            statusBenefit } = useContext(ContentContext);

    const { panColors, } = useContext(ConfigurationContext);

    const [ validRoute, setValidRoute ] = useState(null);
    const [ accordionContentShow, setAccordionContentShow ] = useState(null);
    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ elementToDelete, setElementToDelete ] = useState("");
    const [ selectedLang, setSelectedLang ] = useState(null);

    const navigate = useNavigate();
    const { search, pathname } = useLocation()
    const { id_benefit, slug } = useParams();
    
    useEffect(() => {

        getBenefit(id_benefit);
        
      return () => {
        setBenefit(null);
      }
    }, [])

    useEffect(() => {
        if(basicData){
            setSelectedLang(basicData.language[0].prefix);
        }
      return () => {}
    }, [basicData])

    
    const handleInitForm = () => {
        /*
        if(basicData.language.length == 1){
            let firstLang = {};
            firstLang[basicData.language[0].prefix] =  dataObjectEvaluations();
            setContentByLang(firstLang);
        }
        */
    }

    const handleEdit = () => {
        navigate(`/benefits/${id_benefit}/edit`);
    }

    const handleDelete = (id, fullname) => {
        setElementToDelete(JSON.stringify([{_id: id}]));
        setTitleDeleteText(`<span class="bolder">¿Confirmar Eliminar el beneficio</span> ${fullname}<span class="bolder">?</span>`);
        setDeleteElementOpen(true);
    }

    const handleSegmentation = () => {

        navigate(`/benefits/${id_benefit}/edit`);
        //navigate(`/benefit/${id_benefit}/segmentation/edit`);

    }
    

    useEffect(() => {

        if(isDeleted){
            setIsDeleted(false);
            navigate(`/benefits`);
        }
        return () => {}

    }, [isDeleted])

    useEffect(() => {
    
      return () => {
        setIsDeleted(false);
      }
      
    }, [])
    

    return (
        <WrapperStyled 
            pancolors={panColors}
            className="dashboard"
        >
            
            <DeleteElement 
                open={deleteElementOpen} 
                setOpen={setDeleteElementOpen} 
                title={titleDeleteText}
                setTitle={setTitleDeleteText}
                id={elementToDelete}
                scope="benefit"
                isDeleted={isDeleted}
                setIsDeleted={setIsDeleted}
            />

            <div className="actions">

                <ButtonCustom 
                    className="bt_edit"
                    text="Editar Beneficio"
                    onClick={handleEdit}
                    style_type="invert"

                    icon={<EditIcon />}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

                <ButtonCustom 
                    className="bt_segmentation"
                    text="Segmentación"
                    onClick={handleSegmentation}
                    style_type="invert"

                    icon={<SegmentarionIcon />}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

                <ButtonCustom 
                    className="bt_delete"
                    text="Eliminar Beneficio"
                    onClick={() => handleDelete(benefit._id, benefit.title)}
                    style_type="invert"

                    icon={<TrashIcon />}
                    background={{ normal: panColors.white, hover: panColors.red }}
                    border={{ normal: panColors.red, hover: panColors.red }}
                    textcolor={{ normal: panColors.red, hover: panColors.white }}
                />

            </div>

            {benefit === null || benefit === undefined || selectedLang == null?
                <div className="loader">
                    <LoaderComponent />
                </div>
            :    
                <div className="box_container">
                    {/*
                    <div className='title'>{benefit.title}</div>
                    
                    */}
                    
                    <div className='benefit_data'>
                        <div className='top_data'>
                            <div className='top_data_left'>
                                <div className='image'>
                                    <img src={ benefit.translations[selectedLang].cover_image } />
                                </div>
                                <div className='text'>
                                    <div className='title'>{ benefit.translations[selectedLang].title }</div>
                                    <div className='description'>{ benefit.translations[selectedLang].description_short }</div>
                                </div>
                            </div>
                            <div className='top_data_rigth'>
                                <div className='points'>
                                    <div className='icon'>
                                        <SavingsIcon />
                                    </div>
                                    <div className='text'>
                                        <div className='label'>Costo</div>
                                        <div className='value'>{ benefit.points } pts</div>
                                    </div>
                                </div>
                                <div className='redeems'>
                                    <div className='icon'>
                                        <HistoryIcon />
                                    </div>
                                    <div className='text'>
                                        <div className='label'>Veces canjeado</div>
                                        <div className='value'>{ benefit && benefit.benefit_redeems_correct.length }</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='data_title'>
                            Datos del Beneficio
                        </div>
                        
                        <div className='bottom_data'>

                            <div className='grid_data'>
                                <div className='element'>
                                    <div className='icon'>
                                        <ViewHeadlineIcon />
                                    </div>
                                    <div className='description'>
                                        <span className='highlighted'>Descripción</span>: { benefit.translations[selectedLang].description_long }
                                    </div>
                                </div>
                                <div className='element'>
                                    <div className='icon'>
                                        <OtherAdmissionIcon />
                                    </div>
                                    <div className='terms'>
                                        <span className='highlighted'>Terminos y Condiciones</span>: { benefit.translations[selectedLang].terms_and_conditions }</div>
                                </div>
                                <div className='element'>
                                    <div className='icon'>
                                        <CalendarMonthIcon />
                                    </div>
                                    <div className='publication_date'>
                                        <div className='highlighted'>Vigencia del beneficio:</div>
                                        <div className='date_init'>Desde el: {benefit.date_init ? dayjs(benefit.date_init).tz(dayjs.tz.guess()).format('HH:mm:ss - DD/MM/YYYY') : "- - -"}</div>
                                        <div className='date_finish'>Hasta el: {benefit.date_finish ? dayjs(benefit.date_finish).tz(dayjs.tz.guess()).format('HH:mm:ss - DD/MM/YYYY') : "- - -"}</div>
                                    </div>
                                </div>
                                <div className='element'>
                                    <div className='icon'>
                                        <CalendarMonthIcon />
                                    </div>
                                    <div className='vigency'>
                                        <span className='highlighted'>Fecha y horario de Publicación:</span> {benefit.publication_date ? dayjs(benefit.publication_date).tz(dayjs.tz.guess()).format('HH:mm:ss - DD/MM/YYYY') : "- - -"}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <>{/*JSON.stringify(benefit, undefined, 2)*/}</>
                </div>
            }
        </WrapperStyled>

    )

}

export default ViewContainer;
