import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext,
         ConfigurationContext } from '../../context';

import TableSurveys from './../elements/DataTable/TableSurveys';

const WrapperStyled = styled.div`
    background-color: #fff;
`

const SurveysTable = (props) => {

    const { filters = null } = props;

    const { getSurveys,
            surveys, 
            setSurveys } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);
    
    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Titulo',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Descripción',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_init',
            numeric: false,
            disablePadding: false,
            label: 'Fecha de inicio',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_finish',
            numeric: false,
            disablePadding: false,
            label: 'Fecha de finalización',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_created',
            numeric: false,
            disablePadding: false,
            label: 'Creación',
            align: 'left',
            sorteable: true,
        },
        {
            id: '',
            numeric: false,
            disablePadding: false,
            label: 'Acciones',
            align: 'right',
            sorteable: false,
        }
    ];

    useEffect(() => {
        
        getSurveys(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setSurveys(null);
        }
    }, [])
    
    return (
        <WrapperStyled pancolors={panColors}>
        
        {surveys == null ?
            
            <LoaderComponent />
        :
            surveys.surveys.length ?
            
            <TableSurveys
                headCells={headCells} 
                type={"products"}
                title={"Productos"} 
                data={surveys.surveys} 
                getData={getSurveys}
                totalRows={surveys.total}
                filters={filters}
            />
            :
            <>No hay registros para mostrar</>
        }
        
        </WrapperStyled>
    );
}

export default SurveysTable;