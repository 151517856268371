import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import Form from '../../../components/Segmentation/Form';
import SuccessForm from '../../../components/elements/SuccessForm';
import successImage from '../../../assets/images/img_success_posts.svg';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useParams } from 'react-router-dom';

import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';

const WrapperStyled = styled.div`
    width: 100%;
    .loader{
        margin-top: 20px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const CreateContainer = (props) => {

    const navigate = useNavigate();

    const { id_course, id_module, id_assessment, id_post, id_benefit } = useParams();

    const { getAssessment,
            getCourse,
            getModule,
            setAssessment,
            assessment,
            setModule,
            module,
            setCourse,
            course,
            getPost,
            post,
            editPost,
            getBenefit,
            benefit,
            setBenefit,
            editBenefit,
            editCourse,
            editModule,
            editCreatedAssessment } = useContext(ContentContext);
            
    const { panColors } = useContext(ConfigurationContext);
                       
    const [ success, setSuccess ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    
    const [ countries, setCountries ] = useState([]);
    const [ areas, setAreas ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    const [ clusters, setClusters ] = useState([]);
    const [ retailers, setRetailers ] = useState([]);
    const [ formats, setFormats ] = useState([]);
    const [ idioms, setIdioms ] = useState([]);
    const [ departments, setDepartments ] = useState([]);
    const [ flags, setFlags ] = useState([]);
    const [ noSegmentation, setNoSegmentation ] = useState(null);
    const [ title, setTitle ] = useState("");
    const [ dataLoaded, setDataLoaded ] = useState(false);
    const [ scope, setScope ] = useState("");
    const [ backToText, setBackToText ] = useState("");

    useEffect(() => {

        if(id_post){
            setScope("post");
            setTitle("Segmentacion del Post");
            getPost(id_post);
            setBackToText("post");
        }
        if(id_assessment){
            setScope("assessment");
            setTitle("Segmentacion de la Evaluación");
            getAssessment(id_assessment);
            setBackToText("evaluación");
        }
        if(id_course){
            setScope("course");
            setTitle("Segmentacion del Curso");
            getCourse(id_course);
            setBackToText("curso");
        }
        if(id_module){
            setScope("module");
            setTitle("Segmentacion del Contenido");
            getModule(id_module);
            setBackToText("contenido");
        }
        if(id_benefit){
            setScope("benefit");
            setTitle("Segmentacion del Beneficio");
            getBenefit(id_benefit);
            setBackToText("beneficio");
        }
    
      return () => {
        
      }
    }, [id_assessment, id_course, id_module, id_post, id_benefit])

    useEffect(() => {
        
        let segmentation = null;

        if(scope === "post" && post){
            segmentation = post.segments;
        }

        if(scope === "assessment" && assessment){
            segmentation = assessment.segments;
        }
        
        if(scope === "course" && course){
            segmentation = course.segments;
        }
        
        if(scope === "module" && module){
            segmentation = module.segments;
        }
        
        if(scope === "benefit" && benefit){
            segmentation = benefit.segments;
        }

        if(segmentation){
            segmentation?.countries && setCountries(segmentation.countries);
            segmentation?.areas && setAreas(segmentation.areas);
            segmentation?.roles && setRoles(segmentation.roles);
            segmentation?.regions && setRegions(segmentation.regions);
            segmentation?.clusters && setClusters(segmentation.clusters);
            segmentation?.retailers && setRetailers(segmentation.retailers);
            segmentation?.formats && setFormats(segmentation.formats);
            segmentation?.idioms && setIdioms(segmentation.idioms);
            segmentation?.departments && setDepartments(segmentation.departments);
            segmentation?.flags && setFlags(segmentation.flags);

            setDataLoaded(true);
        }
    
      return () => {
        
      }
    }, [scope, assessment, course, module, post, benefit])
    
    const handleSubmit = () => {

        let update;
        let payload;
        let request;

        let segments = {
            countries: countries,
            areas: areas,
            roles: roles,
            regions: regions,
            clusters: clusters,
            retailers: retailers,
            formats: formats,
            idioms: idioms,
            departments: departments,
            flags: flags
        }
        if(id_course){
            update = {...course};
            update.segments = JSON.stringify(segments);

            payload = {
                course_id: id_course,//
                segments: JSON.stringify(segments)
            };
            setLoadingForm(true);
            request = editCourse(payload);
            request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setLoadingForm(false);
            })

        }
        if(id_module){

            payload = {
                content_id: id_module,
                segments: JSON.stringify(segments)
            };
            setLoadingForm(true);
            request = editModule(payload);
            request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setLoadingForm(false);
            })
            
        }
        if(id_assessment){

            payload = {
                id_assessment: id_assessment,
                segments: JSON.stringify(segments)
            };
            setLoadingForm(true);
            request = editCreatedAssessment(payload);
            request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setLoadingForm(false);
            })

        }
        if(id_post){

            payload = {
                post_id: id_post,
                segments: JSON.stringify(segments)
            };
            setLoadingForm(true);
            request = editPost(payload);
            request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setLoadingForm(false);
            })

        }
        if(id_benefit){

            payload = {
                benefit_id: id_benefit,
                segments: JSON.stringify(segments)
            };
            setLoadingForm(true);
            request = editBenefit(payload);
            request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setLoadingForm(false);
            })

        }
    }

    useEffect(() => {
        return () => {
          setCountries([]);
          setAreas([]);
          setRoles([]);
          setRegions([]);
          setClusters([]);
          setRetailers([]);
          setFormats([]);
          setIdioms([]);
          setDepartments([]);
          setFlags([]);
          setNoSegmentation([]);
          setTitle("");

          setAssessment(null);
          setModule(null);
          setCourse(null);
          setBenefit(null);

          setDataLoaded(false);
        }
      }, [])


    return (
        <WrapperStyled pancolors={panColors}>
            {!success ? 
            <>
                {dataLoaded ? /*assessment != null || course != null || module != null ?*/
                <>
                    <Form 
                        title={title}
                        setTitle={setTitle}
                        countries={countries} setCountries={setCountries}
                        areas={areas} setAreas={setAreas}
                        roles={roles} setRoles={setRoles}
                        regions={regions} setRegions={setRegions}
                        clusters={clusters} setClusters={setClusters}
                        retailers={retailers} setRetailers={setRetailers}
                        formats={formats} setFormats={setFormats}
                        idioms={idioms} setIdioms={setIdioms}
                        departments={departments} setDepartments={setDepartments}
                        flags={flags} setFlags={setFlags}
                        noSegmentation={noSegmentation} setNoSegmentation={setNoSegmentation}
                        dataLoaded={dataLoaded}
                    />

                    <div className="actions">
                        {!loadingForm ?
                        <ButtonCustom 
                            className="bt_submit"
                            text={"Guardar Segmentación"}
                            onClick={handleSubmit}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        :
                            <div className='loader'>
                                <LoaderComponent size={25} color={panColors.white} />
                            </div>
                        }
                        <ButtonTextCustom 
                            className="bt_cancel"
                            text="Cancelar"
                            onClick={() => navigate(-1)}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.red, hover: panColors.red }}
                            disabled={loadingForm}
                        />
                    </div>
                </>
                :
                <div className='loader'>
                    <LoaderComponent />
                </div>    
                }

            </>
            :
            <SuccessForm 
                image={successImage} 
                actionSuccessText={"La configuración de segmentación se editó con éxito."}
                resultSuccessText={null}
                buttonBackToListText={"Continuar la edición"}
                buttonCreateNewText={null}
                buttonCreateContentText={null}
                buttonCreateEvaluationText={null}
                buttonCreateSegmentationText={null}
                createContent={null}
                createNew={``}
                createEvaluation={``}
                createSegmentation={null}
                backTo={-1}
                setSuccess={setSuccess}
                type={"edit"}
                buttonBackToListStyle={"normal"}
            />
            }
            

        </WrapperStyled>
    );
}

export default CreateContainer;