import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from "./Sidebar";
import Header from "./Header";

const Wrapper = styled.div`
    min-height: 100%;
    height: 100%;
`
const ContentStyled = styled.div`
    width: calc(100% - 90px);
    margin-left: 90px;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 35px 30px 35px;
    >.inner{
        display: flex;
        min-height: calc(100% - 90px);
        padding-top: 75px;
        width: 100%;
        padding-bottom: 25px;
    }
`

const Layout = ({ children }) => {

    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ menuVariant, setMenuVarian ] = useState("permanent");

    return (
        <Wrapper className="layout">
            <Sidebar 
                openDrawer={openDrawer} 
                setOpenDrawer={setOpenDrawer} 
                menuVariant={menuVariant}
                setMenuVarian={setMenuVarian}
            />
            <Header 
                openDrawer={openDrawer} 
                setOpenDrawer={setOpenDrawer} 
                menuVariant={menuVariant}
                setMenuVarian={setMenuVarian}
            />
            <ContentStyled>
                <div className="inner">
                    { children }
                </div>
            </ContentStyled>
        </Wrapper>
    )
}

export default Layout
