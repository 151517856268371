import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';

import LabelCustom from '../../../components/elements/LabelCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import SelectCustom from '../../../components/elements/SelectCustom';

import { CalendarMonthBigIcon,
         FilterAltIcon } from '../../../helpers/icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 700px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 75px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
            }
            p.description{
                margin: 20px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 20px;
            .field_timezone{
                margin-top: 10px;
            }
            .field{
                width: 100%;
                .datetime_activate_at,
                .datetime_deactivate_at{
                    width: 100%;
                    .MuiOutlinedInput-notchedOutline{
                        border-color: rgba(0, 0, 0, 0.23);
                    }
                    &.error{
                        .MuiOutlinedInput-notchedOutline{
                            border-color: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                }
                &.deactivate_at{
                    margin-top: 15px;
                }
            }
        }
        .actions_dialog{
            width: 300px;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
    }
`

const ScheduleDateTimeRange = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { basicData } = useContext(ContentContext);

    const { date,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            open, 
            setOpen,
            handleSubmit,
            timezone,
            setTimezone } = props;
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [ dateError, setDateError ] = useState(false);
    const [ _startDate, set_StartDate ] = useState("");
    const [ _endDate, set_EndDate ] = useState("");

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {
        set_StartDate(startDate);
        set_EndDate(endDate);
        
      return () => {}
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormElementChange = (name, value) => {
        switch (name) {
            case 'timezone':
                setTimezone(value);
                break;
            default:
                break;
        }
    }

    const handleSubmitDate = () => {

        setDateError(false);

        if(!dayjs(date).isValid()){
            setDateError(true);
            return null;
        }

        if(!dayjs(_endDate).isAfter(dayjs(_startDate))){
            setDateError(true);
            return null;
        }
        
        setStartDate(_startDate);
        setEndDate(_endDate);

        handleSubmit();
    }
    
    if(!open){
        return null;
    }
    
    return (
        
        <DialogStyled
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
            pancolors={panColors}
        >
            <div className="inner_dialog">
            
                
                <div className="header_dialog">
                    <div className="icon">
                        <CalendarMonthBigIcon />
                    </div>
                    <p className="title">Vigencia del beneficio</p>
                    <p className="description">Planifica la duración del beneficio en una fechar determinada.</p>
                </div>

                <div className='content_dialog'>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="field">
                        <LabelCustom 
                            name="label_activate_at"
                            label="Indicar fecha de activación"
                            onChange={handleFormElementChange}
                            className="field field_activate_at"
                            styles={ formStyle }
                            label_icon={null}
                        />
                        <DateTimePicker
                            ampm={false}
                            className={`datetime_activate_at ${dateError ? " error" : ""}`}
                            disableFuture={false}
                            disablePast={true}
                            value={_startDate ? dayjs(_startDate) : null}
                            onChange={(newValue) => set_StartDate(newValue)}
                        />
                    </div>

                    <div className="field deactivate_at">
                        <LabelCustom 
                            name="label_deactivate_at"
                            label="Indicar fecha de baja"
                            onChange={handleFormElementChange}
                            className="field field_deactivate_at"
                            styles={ formStyle }
                            label_icon={null}
                        />
                        <DateTimePicker
                            ampm={false}
                            className={`datetime_deactivate_at ${dateError ? " error" : ""}`}
                            disableFuture={false}
                            disablePast={true}
                            value={_endDate ? dayjs(_endDate) : null}
                            onChange={(newValue) => set_EndDate(newValue)}
                        />
                    </div>
                    </LocalizationProvider>

                    <SelectCustom 
                        name={"timezone"}
                        label={""}
                        placeholder={"Seleccionar zona horaria ..."}
                        onChange={handleFormElementChange}
                        className={`field field_timezone`}
                        type="text"
                        value={timezone}
                        help={null}
                        isRequired={false}
                        styles={ formStyle }
                        label_icon={<FilterAltIcon />}
                        tooltip_content={null}
                        haveError={false}
                        data={basicData?.valoresUTC}
                    />

                </div>

                <div className="actions_dialog">
                    <ButtonCustom 
                        className="bt_submit"
                        text="Confirmar"
                        onClick={handleSubmitDate}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                    <ButtonCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => setOpen(false)}
                        style_type="invert"

                        icon={null}
                        background={{ normal: panColors.white, hover: panColors.primary }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.primary, hover: panColors.white }}
                    />
                </div>
            </div>
        </DialogStyled>
    );
}


export default ScheduleDateTimeRange;