import React, { Suspense } from 'react';
import ViewContainer from "./ViewContainer";

const ViewUser = (props) => {
    return (
        <ViewContainer {...props} />
    );
};

export default ViewUser;


