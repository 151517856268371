import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { ConfigurationContext } from '../../context';

import { MarkerIcon, } from '../../helpers/icons';

import Map from '../elements/Map';

const Wrapper = styled.div`
    padding-top: 35px;
    .elements{
        display: flex;
        flex-direction: column;
        .element{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #676F8F;
            margin-bottom: 25px;
            .icon{
                margin-right: 20px;
            }
            .data{
                display: flex;
                flex-direction: column;
                margin-right: 20px;
                .location{
                    font-size: 18px;
                    font-weight: 600;
                }
                .status_device{
                    display: flex;
                    flex-direction: row;
                    font-size: 16px;
                    .status{

                    }
                    .device{
                        margin-left: 5px;
                    }
                }
            }
            :last-child{
                margin-bottom: 0;
            }
        }
    }
    .map{
        margin-top: 50px;
    }

`

const UserSessions = () => {

    const { colors } = useContext(ConfigurationContext);
    const [ location, setLocation ] = useState({lat: -34.6695145, lng: -58.7117726});

    
    return (
        <Wrapper {...colors}>
            
            <div className="elements">

                <div className="element">
                    <div className="icon">
                        <MarkerIcon />
                    </div>
                    <div className="data">
                        <div className="location">
                            Buenos Aires, Argentina
                        </div>
                        <div className="status_device">
                            <div className="status">Activo Ahora</div>
                            <div className="device"> - iOS</div>
                        </div>
                    </div>
                </div>

                <div className="element">
                    <div className="icon">
                        <MarkerIcon />
                    </div>
                    <div className="data">
                        <div className="location">
                            Buenos Aires, Argentina
                        </div>
                        <div className="status_device">
                            <div className="status">Hace 24hs</div>
                            <div className="device"> - Android 11</div>
                        </div>
                    </div>
                </div>
 
                <div className="element">
                    <div className="icon">
                        <MarkerIcon />
                    </div>
                    <div className="data">
                        <div className="location">
                            Buenos Aires, Argentina
                        </div>
                        <div className="status_device">
                            <div className="status">Hace 6 días</div>
                            <div className="device"> - Android 10</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="map">
                <Map 
                    location={location} 
                />
            </div>

        
        </Wrapper>
    );
}

export default UserSessions;



