import React from "react";
import LoginContainer from "./LoginContainer";

const Login = (props) => {
  return (
      <LoginContainer {...props} />
  );
};

export default Login;
