import React, { useContext } from 'react';
import styled from 'styled-components';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ConfigurationContext } from '../../context';

const AccordionStyled = styled(Accordion)`
    margin-bottom: 15px;
    &.MuiAccordion-root{
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%);
    }
    .MuiAccordionSummary-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        
        >.title{
            font-size: 17px;
            font-weight: 600;
            margin-left: 10px;
            color: #676F8F;
        }
        >.icon{
            display: flex;
            svg{
              width: 25px!important;
              height: 25px!important;
              fill: ${props => `rgb(${props.iconColor ? props.iconColor.primary : "61,56,213"})` };
            }
        }
    }
    .MuiAccordionSummary-expandIconWrapper{
        color: ${props => `rgb(${props.iconColor ? props.iconColor.primary : "61,56,213"})` };
    }
    .MuiAccordionDetails-root{
      background-color: #F7F8FC!important;
      .accordion_top_line{
        width: 100%;
        border-top: 1px solid #e2e2e2;
      }
      
      padding: 0 16px 16px;
    }

`

const AccordionElement = (props) => {

  const { colors } = useContext(ConfigurationContext);

  const { icon, onChange, comp, title } = props;
  
  return (
      <AccordionStyled {...colors} onChange={onChange} className={"accordion"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="accordion_top_line"></div>
          { comp }
          
        </AccordionDetails>
      </AccordionStyled>
  );
}

export default AccordionElement;