import { ReactComponent as ProfileIconSVG } from '../assets/images/icon_profile.svg';
import { ReactComponent as AreaIconSVG } from '../assets/images/icon_area.svg';
import { ReactComponent as RoleIconSVG } from '../assets/images/icon_role.svg';
import { ReactComponent as EmailIconSVG } from '../assets/images/icon_email.svg';
import { ReactComponent as MobileIconSVG } from '../assets/images/icon_mobile.svg';
import { ReactComponent as UserIconSVG } from '../assets/images/icon_user.svg';
import { ReactComponent as ExclamationIconSVG } from '../assets/images/icon_exclamation.svg';
import { ReactComponent as TimezoneIconSVG } from '../assets/images/icon_timezone.svg';
import { ReactComponent as LenguageIconSVG } from '../assets/images/icon_lenguage.svg';
import { ReactComponent as LocalizationAreaIconSVG } from '../assets/images/icon_localization_area.svg';
import { ReactComponent as CountryIconSVG } from '../assets/images/icon_country.svg';
import { ReactComponent as CapIconSVG } from '../assets/images/icon_cap.svg';
import { ReactComponent as EyeIconSVG } from '../assets/images/icon_eye.svg';
import { ReactComponent as InteractionsIconSVG } from '../assets/images/icon_interactions.svg';
import { ReactComponent as MarkerIconSVG } from '../assets/images/icon_marker.svg';
import { ReactComponent as HeartIconSVG } from '../assets/images/icon_heart.svg';
import { ReactComponent as TrashIconSVG } from '../assets/images/icon_trash.svg';
import { ReactComponent as UserActivateIconSVG } from '../assets/images/icon_user_activate.svg';
import { ReactComponent as UserDesactivateIconSVG } from '../assets/images/icon_user_desactivate.svg';

import { ReactComponent as ExtrasMenuIconSVG } from '../assets/images/icon_extras_menu.svg';
import { ReactComponent as CoursesMenuIconSVG } from '../assets/images/icon_courses_menu.svg';
import { ReactComponent as LogoutMenuIconSVG } from '../assets/images/icon_logout_menu.svg';
import { ReactComponent as MetricsMenuIconSVG } from '../assets/images/icon_metrics_menu.svg';
import { ReactComponent as NotificationMenuIconSVG } from '../assets/images/icon_notification_menu.svg';
import { ReactComponent as PortfolioMenuIconSVG } from '../assets/images/icon_portfolio_menu.svg';
import { ReactComponent as UsersMenuIconSVG } from '../assets/images/icon_users_menu.svg';
import { ReactComponent as PostMenuIconSVG } from '../assets/images/icon_posts_menu.svg';
import { ReactComponent as WallMenuIconSVG } from '../assets/images/icon_wall_menu.svg';
import { ReactComponent as HamburgerSVG } from '../assets/images/icon_hamburger.svg';
import { ReactComponent as BenefitsMenuSVG } from '../assets/images/icon_benefits_menu.svg';
import { ReactComponent as SurveyMenuSVG } from '../assets/images/icon_flag_menu.svg';
import { ReactComponent as StatsMenuSVG } from '../assets/images/icon_stats_menu.svg';


import { ReactComponent as AcUnitIconSVG } from '../assets/images/icon_ac_unit.svg';
import { ReactComponent as AddBusinessIconSVG } from '../assets/images/icon_add_business.svg';
import { ReactComponent as AssignmentTurnedInIconSVG } from '../assets/images/icon_assignment_turned_in.svg';
import { ReactComponent as ChecklistIconSVG } from '../assets/images/icon_checklist.svg';
import { ReactComponent as ClockCheckinIconSVG } from '../assets/images/icon_clock_checkin.svg';
import { ReactComponent as ClockCheckoutIconSVG } from '../assets/images/icon_clock_checkout.svg';
import { ReactComponent as ClusterIconSVG } from '../assets/images/icon_cluster.svg';
import { ReactComponent as DistanceIconSVG } from '../assets/images/icon_distance.svg';
import { ReactComponent as EditIconSVG } from '../assets/images/icon_edit.svg';
import { ReactComponent as FormatIconSVG } from '../assets/images/icon_format.svg';
import { ReactComponent as LatitudeIconSVG } from '../assets/images/icon_latitude.svg';
import { ReactComponent as LongitudeIconSVG } from '../assets/images/icon_longitude.svg';
import { ReactComponent as StoreIconSVG } from '../assets/images/icon_store.svg';
import { ReactComponent as NoStoreIconSVG } from '../assets/images/icon_no_store.svg';
import { ReactComponent as PaidIconSVG } from '../assets/images/icon_paid.svg';
import { ReactComponent as PriceCheckIconSVG } from '../assets/images/icon_price_check.svg';
import { ReactComponent as RegionIconSVG } from '../assets/images/icon_region.svg';
import { ReactComponent as StoreChiefIconSVG } from '../assets/images/icon_store_chief.svg';
import { ReactComponent as MapMarkerIconSVG } from '../assets/images/map_marker.svg';
import { ReactComponent as DeactivateBusinessIconSVG } from '../assets/images/icon_deactivate_business.svg';
import { ReactComponent as CalendarMonthIconSVG } from '../assets/images/icon_calendar_month.svg';
import { ReactComponent as CalendarMonthBigIconSVG } from '../assets/images/icon_calendar_month_big.svg';
import { ReactComponent as HelpIconSVG } from '../assets/images/icon_help.svg';
import { ReactComponent as CheckIconSVG } from '../assets/images/icon_check.svg';
import { ReactComponent as ErrorIconSVG } from '../assets/images/icon_error.svg';
import { ReactComponent as ScheduleCreateIconSVG } from '../assets/images/icon_schedule_create.svg';
import { ReactComponent as CameraIconSVG } from '../assets/images/icon_camera.svg';
import { ReactComponent as DashboardIconSVG } from '../assets/images/icon_dashboard.svg';
import { ReactComponent as TitleIconSVG } from '../assets/images/icon_title.svg';
import { ReactComponent as HeadlineIconSVG } from '../assets/images/icon_headline.svg';
import { ReactComponent as ImageIconSVG } from '../assets/images/icon_image.svg';
import { ReactComponent as LanguageIconSVG } from '../assets/images/icon_lenguage.svg';
import { ReactComponent as DeleteElementIconSVG } from '../assets/images/icon_delete_element.svg';
import { ReactComponent as SegmentarionIconSVG } from '../assets/images/icon_segmentation.svg';
import { ReactComponent as ArrowCircleUpIconSVG } from '../assets/images/icon_arrow_circle_up.svg';
import { ReactComponent as FormatListNumberedIconSVG } from '../assets/images/icon_format_list_numbered.svg';
import { ReactComponent as FormatPaintIconSVG } from '../assets/images/icon_format_paint.svg';
import { ReactComponent as CountIconSVG } from '../assets/images/icon_123.svg';
import { ReactComponent as TaskIconSVG } from '../assets/images/icon_task.svg';
import { ReactComponent as DragIndicatorIconSVG } from '../assets/images/icon_drag_indicator.svg';
import { ReactComponent as DangerousIconSVG } from '../assets/images/icon_dangerous.svg';
import { ReactComponent as DoneOutlineIconSVG } from '../assets/images/icon_done_outline.svg';
import { ReactComponent as ViewHeadlineIconSVG } from '../assets/images/icon_view_headline.svg';
import { ReactComponent as RuleIconSVG } from '../assets/images/icon_rule.svg';
import { ReactComponent as BorderAllIconSVG } from '../assets/images/icon_border_all.svg';
import { ReactComponent as PanoramaIconSVG } from '../assets/images/icon_panorama.svg';
import { ReactComponent as MatchCaseIconSVG } from '../assets/images/icon_match_case.svg';
import { ReactComponent as ListAltIconSVG } from '../assets/images/icon_list_alt.svg';
import { ReactComponent as InfoIconSVG } from '../assets/images/icon_info.svg';
import { ReactComponent as RetailersIconSVG } from '../assets/images/icon_store.svg';
import { ReactComponent as DocumentScannerIconSVG } from '../assets/images/icon_document_scanner.svg';
import { ReactComponent as FavoriteBorderIconSVG } from '../assets/images/icon_favorite_border.svg';
import { ReactComponent as ModeCommentIconSVG } from '../assets/images/icon_mode_comment.svg';
import { ReactComponent as CalendarTodayIconSVG } from '../assets/images/icon_calendar_today.svg';
import { ReactComponent as FormatImageLeftIconSVG } from '../assets/images/icon_format_image_left.svg';
import { ReactComponent as ArtTrackIconSVG } from '../assets/images/icon_art_track.svg';
import { ReactComponent as CommentsBolderIconSVG } from '../assets/images/icon_comments_bolder.svg';
import { ReactComponent as FavoriteBolderIconSVG } from '../assets/images/icon_favorite_bolder.svg';
import { ReactComponent as AttachFileAddIconSVG } from '../assets/images/icon_attach_file_add.svg';
import { ReactComponent as FilterAltIconSVG } from '../assets/images/icon_filter_alt.svg';
import { ReactComponent as Region1IconSVG } from '../assets/images/icon_region1.svg';
import { ReactComponent as InHomeModeIconSVG } from '../assets/images/icon_in_home_mode.svg';
import { ReactComponent as ListCheckIconSVG } from '../assets/images/icon_list_check.svg';
import { ReactComponent as RetailersIcon1SVG } from '../assets/images/icon_retailers.svg';
import { ReactComponent as ActiveStoreIconSVG } from '../assets/images/icon_active_store.svg';
import { ReactComponent as DeactiveStoreIconSVG } from '../assets/images/icon_deactive_store.svg';
import { ReactComponent as DeleteStoreIconSVG } from '../assets/images/icon_delete_store.svg';
import { ReactComponent as ArrowRightIconSVG } from '../assets/images/icon_arrow_right.svg';
import { ReactComponent as OtherAdmissionIconSVG } from '../assets/images/icon_other_admission.svg';
import { ReactComponent as TreeStructureIconSVG } from '../assets/images/icon_tree_structure.svg';
import { ReactComponent as SellIconSVG } from '../assets/images/icon_sell.svg';
import { ReactComponent as TagIconSVG } from '../assets/images/icon_tag.svg';
import { ReactComponent as BarcodeIconSVG } from '../assets/images/icon_barcode.svg';
import { ReactComponent as BarcideScannerIconSVG } from '../assets/images/icon_barcode_scanner.svg';
import { ReactComponent as AccountFreeIconSVG } from '../assets/images/icon_account_tree.svg';
import { ReactComponent as SavingsIconSVG } from '../assets/images/icon_savings.svg';
import { ReactComponent as HistoryIconSVG } from '../assets/images/icon_history.svg';
import { ReactComponent as EditBigIconSVG } from '../assets/images/icon_edit_big.svg';
import { ReactComponent as EventAvailableBigIconSVG } from '../assets/images/icon_event_available_big.svg';
import { ReactComponent as IconInventoryIconSVG } from '../assets/images/icon_inventory.svg';
import { ReactComponent as SurveyIconSVG } from '../assets/images/icon_survey.svg';
import { ReactComponent as StockProductosSVG } from '../assets/images/icon_stock_products.svg';






export const StockProductos = (props) => { 
    return ( 
        <StockProductosSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const StatsMenu = (props) => { 
    return ( 
        <StatsMenuSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const SurveyMenu = (props) => { 
    return ( 
        <SurveyMenuSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const SurveyIcon = (props) => { 
    return ( 
        <SurveyIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const IconInventoryIcon = (props) => { 
    return ( 
        <IconInventoryIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const EditBigIcon = (props) => { 
    return ( 
        <EditBigIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const EventAvailableBigIcon = (props) => { 
    return ( 
        <EventAvailableBigIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const SavingsIcon = (props) => { 
    return ( 
        <SavingsIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const HistoryIcon = (props) => { 
    return ( 
        <HistoryIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AccountFreeIcon = (props) => { 
    return ( 
        <AccountFreeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const SellIcon = (props) => { 
    return ( 
        <SellIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const TagIcon = (props) => { 
    return ( 
        <TagIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const BarcodeIcon = (props) => { 
    return ( 
        <BarcodeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const BarcideScannerIcon = (props) => { 
    return ( 
        <BarcideScannerIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}

export const TreeStructureIcon = (props) => { 
    return ( 
        <TreeStructureIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const OtherAdmissionIcon = (props) => { 
    return ( 
        <OtherAdmissionIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ArrowRightIcon = (props) => { 
    return ( 
        <ArrowRightIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const BenefitsMenu = (props) => { 
    return ( 
        <BenefitsMenuSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DeactiveStoreIcon = (props) => { 
    return ( 
        <DeactiveStoreIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ActiveStoreIcon = (props) => { 
    return ( 
        <ActiveStoreIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DeleteStoreIcon = (props) => { 
    return ( 
        <DeleteStoreIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const RetailersIcon1 = (props) => { 
    return ( 
        <RetailersIcon1SVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ListCheckIcon = (props) => { 
    return ( 
        <ListCheckIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const InHomeModeIcon = (props) => { 
    return ( 
        <InHomeModeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const Region1Icon = (props) => { 
    return ( 
        <Region1IconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FilterAltIcon = (props) => { 
    return ( 
        <FilterAltIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AttachFileAddIcon = (props) => { 
    return ( 
        <AttachFileAddIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CommentsBolderIcon = (props) => { 
    return ( 
        <CommentsBolderIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FavoriteBolderIcon = (props) => { 
    return ( 
        <FavoriteBolderIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CalendarTodayIcon = (props) => { 
    return ( 
        <CalendarTodayIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FormatImageLeftIcon = (props) => { 
    return ( 
        <FormatImageLeftIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ArtTrackIcon = (props) => { 
    return ( 
        <ArtTrackIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}

export const ModeCommentIcon = (props) => { 
    return ( 
        <ModeCommentIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FavoriteBorderIcon = (props) => { 
    return ( 
        <FavoriteBorderIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DocumentScannerIcon = (props) => { 
    return ( 
        <DocumentScannerIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const RetailersIcon = (props) => { 
    return ( 
        <RetailersIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ListAltIcon = (props) => { 
    return ( 
        <ListAltIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const InfoIcon = (props) => { 
    return ( 
        <InfoIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const MatchCaseIcon = (props) => { 
    return ( 
        <MatchCaseIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const PanoramaIcon = (props) => { 
    return ( 
        <PanoramaIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DangerousIcon = (props) => { 
    return ( 
        <DangerousIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DoneOutlineIcon = (props) => { 
    return ( 
        <DoneOutlineIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ViewHeadlineIcon = (props) => { 
    return ( 
        <ViewHeadlineIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const RuleIcon = (props) => { 
    return ( 
        <RuleIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const BorderAllIcon = (props) => { 
    return ( 
        <BorderAllIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DragIndicatorIcon = (props) => { 
    return ( 
        <DragIndicatorIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const TaskIcon = (props) => { 
    return ( 
        <TaskIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CountIcon = (props) => { 
    return ( 
        <CountIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FormatPaintIcon = (props) => { 
    return ( 
        <FormatPaintIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FormatListNumberedIcon = (props) => { 
    return ( 
        <FormatListNumberedIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ArrowCircleUpIcon = (props) => { 
    return ( 
        <ArrowCircleUpIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const SegmentarionIcon = (props) => { 
    return ( 
        <SegmentarionIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DeleteElementIcon = (props) => { 
    return ( 
        <DeleteElementIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LanguageIcon = (props) => { 
    return ( 
        <LanguageIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ImageIcon = (props) => { 
    return ( 
        <ImageIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const HeadlineIcon = (props) => { 
    return ( 
        <HeadlineIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const TitleIcon = (props) => { 
    return ( 
        <TitleIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DashboardIcon = (props) => { 
    return ( 
        <DashboardIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CameraIcon = (props) => { 
    return ( 
        <CameraIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ScheduleCreateIcon = (props) => { 
    return ( 
        <ScheduleCreateIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ErrorIcon = (props) => { 
    return ( 
        <ErrorIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CheckIcon = (props) => { 
    return ( 
        <CheckIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const HelpIcon = (props) => { 
    return ( 
        <HelpIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CalendarMonthIcon = (props) => { 
    return ( 
        <CalendarMonthIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CalendarMonthBigIcon = (props) => { 
    return ( 
        <CalendarMonthBigIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AcUnitIcon = (props) => { 
    return ( 
        <AcUnitIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AddBusinessIcon = (props) => { 
    return ( 
        <AddBusinessIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DeactivateBusinessIcon = (props) => { 
    return ( 
        <DeactivateBusinessIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AssignmentTurnedInIcon = (props) => { 
    return ( 
        <AssignmentTurnedInIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ChecklistIcon = (props) => { 
    return ( 
        <ChecklistIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ClockCheckinIcon = (props) => { 
    return ( 
        <ClockCheckinIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ClockCheckoutIcon = (props) => { 
    return ( 
        <ClockCheckoutIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ClusterIcon = (props) => { 
    return ( 
        <ClusterIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const DistanceIcon = (props) => { 
    return ( 
        <DistanceIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const EditIcon = (props) => { 
    return ( 
        <EditIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const FormatIcon = (props) => { 
    return ( 
        <FormatIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LatitudeIcon = (props) => { 
    return ( 
        <LatitudeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LongitudeIcon = (props) => { 
    return ( 
        <LongitudeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const StoreIcon = (props) => { 
    return ( 
        <StoreIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const NoStoreIcon = (props) => { 
    return ( 
        <NoStoreIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const PaidIcon = (props) => { 
    return ( 
        <PaidIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const PriceCheckIcon = (props) => { 
    return ( 
        <PriceCheckIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const RegionIcon = (props) => { 
    return ( 
        <RegionIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const StoreChiefIcon = (props) => { 
    return ( 
        <StoreChiefIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const MapMarkerIcon = (props) => { 
    return ( 
        <MapMarkerIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}


export const Hamburger = (props) => { 
    return ( 
        <HamburgerSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ExtrasMenuIcon = (props) => { 
    return ( 
        <ExtrasMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CoursesMenuIcon = (props) => { 
    return ( 
        <CoursesMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LogoutMenuIcon = (props) => { 
    return ( 
        <LogoutMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const MetricsMenuIcon = (props) => { 
    return ( 
        <MetricsMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const NotificationMenuIcon = (props) => { 
    return ( 
        <NotificationMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const PortfolioMenuIcon = (props) => { 
    return ( 
        <PortfolioMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const UsersMenuIcon = (props) => { 
    return ( 
        <UsersMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const PostMenuIcon = (props) => { 
    return ( 
        <PostMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const WallMenuIcon = (props) => { 
    return ( 
        <WallMenuIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const UserDesactivateIcon = (props) => { 
    return ( 
        <UserDesactivateIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const UserActivateIcon = (props) => { 
    return ( 
        <UserActivateIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const TrashIcon = (props) => { 
    return ( 
        <TrashIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const HeartIcon = (props) => { 
    return ( 
        <HeartIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const MarkerIcon = (props) => { 
    return ( 
        <MarkerIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const InteractionsIcon = (props) => { 
    return ( 
        <InteractionsIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CapIcon = (props) => { 
    return ( 
        <CapIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const EyeIcon = (props) => { 
    return ( 
        <EyeIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}

export const ProfileIcon = (props) => { 
    return ( 
        <ProfileIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const AreaIcon = (props) => { 
    return ( 
        <AreaIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const RoleIcon = (props) => { 
    return ( 
        <RoleIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const EmailIcon = (props) => { 
    return ( 
        <EmailIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const MobileIcon = (props) => { 
    return ( 
        <MobileIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const UserIcon = (props) => { 
    return ( 
        <UserIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const ExclamationIcon = (props) => { 
    return ( 
        <ExclamationIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const TimezoneIcon = (props) => { 
    return ( 
        <TimezoneIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LenguageIcon = (props) => { 
    return ( 
        <LenguageIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const LocalizationAreaIcon = (props) => { 
    return ( 
        <LocalizationAreaIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}
export const CountryIcon = (props) => { 
    return ( 
        <CountryIconSVG fill={"rgb(109, 105, 239)"} className={props.className} /> 
    )
}