import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`

    font-weight: 600;
    color: ${props => `rgb(${props.panColors.red})`};
    margin-bottom: 25px;
    
`

const FormBottomMessage = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { text,
            haveError,
            className } = props;
    
    if(!haveError){
        return null;
    }
    return (
        <WrapperStyled panColors={panColors} className={className} >
            {text}
        </WrapperStyled>
    )

}

export default FormBottomMessage;