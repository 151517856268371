import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import ButtonTextCustom from '../elements/ButtonTextCustom';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .bt_action{
        padding: 0;
        margin-top: 0;
        p{
            margin: 0;
        }
    }
    .help{
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        color: ${props => `rgb(${props.panColors.dark_gray})`};
        margin-top: 5px;
    }
    .head{
        display: flex;
        flex-direction: row;
        align-items: center;
        >.icon{
            display: flex;
            margin-right: 5px;
            svg{
                fill: ${props => props.error ? `rgb(${props.panColors.red})` : `rgb(${props.panColors.black_overwhite})`};
            }
        }
        >.title{
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            color: ${props => props.error ? `rgb(${props.panColors.red})` : `rgb(${props.panColors.black_overwhite})`};
        }
    }
`

const ActionBox = (props) => {

    const { icon, 
            title, 
            helpText,
            className,
            actionButtonText,
            onClick,
            error } = props;

    const { panColors } = useContext(ConfigurationContext);


    useEffect(() => {
        return () => {}
    }, [])
    
    return (
        <WrapperStyled
            className={className}
            panColors={panColors}
            error={error}
        >

            <div className="head">
                <div className={`icon${error ? ' error' : ''}`}>
                    { icon }
                </div>
                <div className={`title${error ? ' error' : ''}`}>
                    { title }
                </div>
            </div>
            <ButtonTextCustom 
                className="bt_action"
                text={actionButtonText}
                onClick={onClick}

                icon={null}
                background={{ normal: "", hover: "" }}
                border={{ normal: "", hover: "" }}
                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
            />
            <div className="help" dangerouslySetInnerHTML={{__html:helpText}}></div>

        </WrapperStyled>

    )

}

export default ActionBox;