import React, { Suspense } from 'react';
import EditContainer from "./EditContainer";

const EditUser = (props) => {
    return (
        <EditContainer {...props} />
    );
};

export default EditUser;


