import React from 'react';
import styled from 'styled-components';

import Form from '../../../components/Survey/Form';

const Wrapper = styled.div`

    width: 100%;

    >.actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .bt_create_user{

        }
        .bt_export_user{
            margin-left: 10px;
        }
        .bt_admin_categories{
            margin-left: 10px;
        }
    }

    >.filter_actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 35px;
        .field_keywords{
            width: calc(100% - 600px);
        }
        .search{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .filter_option{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 680px;
            .label_title{
                width: 70px;
                font-size: 15px;
                margin-right: 10px;
                margin-left: 15px;
                white-space: nowrap;
                &.order_by{
                    width: 85px;
                }
            }
            .bt_send_filter{
                margin-left: 10px;
            }
            
            .field_category,
            .field_order_by{
                width: 320px;
            }
        }
        .bt_filter{
            align-self: flex-end;
            margin-left: 10px;
        }
    }
    >.content{
        margin-top: 20px;
    }
`


const CreateContainer = (props) => {


    return (
        <Wrapper className="dashboard">
            
            <Form 
                data={null}
                action="create"
            />

        </Wrapper>

    )

}

export default CreateContainer;
