import React, { useContext } from 'react';
import styled from 'styled-components';
import InputCheckboxCustom from '../elements/InputCheckboxCustom';  
import SelectCustom from '../elements/SelectCustom';

import { RegionIcon,
         CountryIcon,
         AreaIcon,
         RoleIcon,
         ClusterIcon,
         RetailersIcon,
         FormatIcon } from '../../helpers/icons';

import { getNameFromDataById } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    
    .box_form{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .box_column{
            width: 100%;
            >.title{
                color: ${props => `rgb(${props.pancolors.black})`};
                font-size: 25px;
                line-height: 30px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }
    }
    .field_no_segmentation{
        width: calc(50% - 20px);
        margin-bottom: 30px;
    }
    .box_fields{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.box_field{
            display: flex;
            flex-direction: column;
            width: calc(50% - 20px);
            margin-bottom: 25px;
            :last-child{
                margin-bottom: 0;
            }
            .label_custom{
                margin-bottom: 0;
            }
        }
    }
    .box_options{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        flex-wrap: wrap;
        .option{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border: 2px solid ${props => `rgb(${props.pancolors.dark_gray})`};
            border-radius: 8px;
            box-sizing: border-box;
            padding: 10px 15px;
            margin-bottom: 10px;
            margin-right: 10px;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            font-weight: 600;
            &.exist{
                border-color: ${props => `rgb(${props.pancolors.dots})`};
                background-color: ${props => `rgb(${props.pancolors.dots})`};
                color: #FFFFFF;
            }
            .close{
                cursor: pointer;
                :hover{
                    color: ${props => `rgb(${props.pancolors.red})`};
                }
            }
            :first-child {
                margin-left: 0;
            }
            p{
                margin: 0 0 0 5px;
            }
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const Form = (props) => {

    const { panColors } = useContext(ConfigurationContext);
    
    const { basicData } = useContext(ContentContext);
    
    const { countries, setCountries,
            areas, setAreas,
            roles, setRoles,
            regions, setRegions,
            clusters, setClusters,
            retailers, setRetailers,
            formats, setFormats,
            idioms, setIdioms,
            achievements, setAchievements,
            departments, setDepartments,
            flags, setFlags,
            noSegmentation, setNoSegmentation,
            title,
            dataLoaded } = props;


    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    
    const handleFormElementChange = (name, value) => {
        
        let result;
        let update;

        switch (name) {
            case "countries":
                result = countries.filter(e => e === value);
                if(!result.length){
                    update = [...countries];
                    update.push(value);
                    setCountries(update);
                }
                break;
            case "areas":
                result = areas.filter(e => e === value);
                if(!result.length){
                    update = [...areas];
                    update.push(value);
                    setAreas(update);
                }
                break;
            case "roles":
                result = roles.filter(e => e === value);
                if(!result.length){
                    update = [...roles];
                    update.push(value);
                    setRoles(update);
                }
                break;
            case "regions":
                result = regions.filter(e => e === value);
                if(!result.length){
                    update = [...regions];
                    update.push(value);
                    setRegions(update);
                }
                break;
            case "clusters":
                result = clusters.filter(e => e === value);
                if(!result.length){
                    update = [...clusters];
                    update.push(value);
                    setClusters(update);
                }
                break;
            case "retailers":
                result = retailers.filter(e => e === value);
                if(!result.length){
                    update = [...retailers];
                    update.push(value);
                    setRetailers(update);
                }
                break;
            case "formats":
                result = formats.filter(e => e === value);
                if(!result.length){
                    update = [...formats];
                    update.push(value);
                    setFormats(update);
                }
                break;
            case "idioms":
                result = idioms.filter(e => e === value);
                if(!result.length){
                    update = [...idioms];
                    update.push(value);
                    setIdioms(update);
                }
                break;
            case "departments":
                result = departments.filter(e => e === value);
                if(!result.length){
                    update = [...departments];
                    update.push(value);
                    setDepartments(update);
                }
                
                break;
            case "achievements":
                result = achievements.filter(e => e === value);
                if(!result.length){
                    update = [...achievements];
                    update.push(value);
                    setAchievements(update);
                }
                break;
            case "flags":
                result = flags.filter(e => e === value);
                if(!result.length){
                    update = [...flags];
                    update.push(value);
                    setFlags(update);
                }
                break;
                
            case "no_segmentation":
                if(value){
                    setCountries([]);
                    setAreas([]);
                    setRoles([]);
                    setRegions([]);
                    setClusters([]);
                    setRetailers([]);
                    setFormats([]);
                    setDepartments([]);
                    setAchievements([]);
                    setIdioms([]);
                    setFlags([]);
                }
                setNoSegmentation(!noSegmentation);
                break;
            default:
                break;
        }
        
        return null;
    }

    const handleRemoveOption = (segmentation, id) => {
        let result;
        switch (segmentation) {
            case "countries":
                result = countries.filter(e => e !== id);
                setCountries(result);
                break;
            case "areas":
                result = areas.filter(e => e !== id);
                setAreas(result);
                break;
            case "roles":
                result = roles.filter(e => e !== id);
                setRoles(result);
                break;
            case "regions":
                result = regions.filter(e => e !== id);
                setRegions(result);
                break;
            case "clusters":
                result = clusters.filter(e => e !== id);
                setClusters(result);
                break;
            case "retailers":
                result = retailers.filter(e => e !== id);
                setRetailers(result);
                break;
            case "formats":
                result = formats.filter(e => e !== id);
                setFormats(result);
                break;
            case "idioms":
                result = idioms.filter(e => e !== id);
                setIdioms(result);
                break;
            case "departments":
                result = departments.filter(e => e !== id);
                setDepartments(result);
                break;
            case "achievements":
                result = achievements.filter(e => e !== id);
                setAchievements(result);
                break;
            case "flags":
                result = flags.filter(e => e !== id);
                setFlags(result);
                break;
            default:
                break;
        }

    }
    
    
    return (
        <WrapperStyled pancolors={panColors}>
            {basicData && dataLoaded ?
            <div className="box_form">
                <div className="box_column">

                    <div className="title">
                        {title}
                    </div>

                    <InputCheckboxCustom
                        name={"no_segmentation"}
                        label={"Sin segmentación"}
                        placeholder={""}
                        onChange={handleFormElementChange}
                        className={`field field_no_segmentation`}
                        value={noSegmentation}
                        help={null}
                        isRequired={false}
                        styles={ formStyle }
                        label_icon={null}
                        labelPosition={"right"}
                        tooltip_content={"tooltip_content"}
                        haveError={false}
                    />
                    
                    <div className="box_fields">
                        

                        

                        {basicData && basicData.countries.length && 
                        <div className='box_field'>
                            <SelectCustom 
                                name={"countries"}
                                label={"Countries"}
                                placeholder={"Seleccionar uno o más Países ..."}
                                onChange={handleFormElementChange}
                                className={`field field_countries`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<CountryIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData.countries}
                            />
                            <div className='box_options'>
                                {countries?.map((country, index) => {
                                    const name = getNameFromDataById(basicData.countries, country);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-country-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("countries", country)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        }

                        <div className='box_field'>
                            <SelectCustom 
                                name={"areas"}
                                label={"Área"}
                                placeholder={"Seleccionar uno o más Áreas ..."}
                                onChange={handleFormElementChange}
                                className={`field field_areas`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<AreaIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.areas}
                            />
                            <div className='box_options'>
                                {areas?.map((area, index) => {
                                    const name = getNameFromDataById(basicData.areas, area);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-area-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("areas", area)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                        <div className='box_field'>
                            <SelectCustom 
                                name={"roles"}
                                label={"Roles"}
                                placeholder={"Seleccionar uno o más Roles ..."}
                                onChange={handleFormElementChange}
                                className={`field field_roles`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.roles}
                            />
                            <div className='box_options'>
                                {roles?.map((rol, index) => {
                                    const name = getNameFromDataById(basicData.roles, rol);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-rol-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("roles", rol)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                        {basicData && basicData.idioms.length ?
                            <div className='box_field'>
                                <SelectCustom 
                                    name={"idioms"}
                                    label={"Idiomas"}
                                    placeholder={"Seleccionar uno o más Idiomas ..."}
                                    onChange={handleFormElementChange}
                                    className={`field field_idioms`}
                                    type="text"
                                    value={""}
                                    help={null}
                                    isRequired={false}
                                    styles={ formStyle }
                                    label_icon={<RoleIcon />}
                                    tooltip_content={null}
                                    haveError={false}
                                    data={basicData?.idioms}
                                />
                                <div className='box_options'>
                                    {idioms?.map((idiom, index) => {
                                        const name = getNameFromDataById(basicData.idioms, idiom);
                                        return(
                                            <>
                                                {name ? 
                                                    <div key={`segmentation-idiom-${index}`} className={`option`}>
                                                        <div className="close" onClick={() => handleRemoveOption("idioms", idiom)}>x</div>
                                                        <p>{ name }</p>
                                                    </div>
                                                : ""}
                                            </>
                                        )
                                    })}
                                    
                                </div>
                            </div> : ""
                        }

                        <div className='box_field'>
                            <SelectCustom 
                                name={"regions"}
                                label={"Región"}
                                placeholder={"Seleccionar uno o más Regiones ..."}
                                onChange={handleFormElementChange}
                                className={`field field_regions`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RegionIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.regions}
                            />
                            <div className='box_options'>
                                {regions?.map((region, index) => {
                                    const name = getNameFromDataById(basicData.regions, region);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-region-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("regions", region)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                        <div className='box_field'>
                            <SelectCustom 
                                name={"clusters"}
                                label={"Clusters"}
                                placeholder={"Seleccionar uno o más Clusters ..."}
                                onChange={handleFormElementChange}
                                className={`field field_clusters`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<ClusterIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.clusters}
                            />
                            <div className='box_options'>
                                {clusters?.map((cluster, index) => {
                                    const name = getNameFromDataById(basicData.clusters, cluster);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-cluster-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("clusters", cluster)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>

                        <div className='box_field'>
                            <SelectCustom 
                                name={"retailers"}
                                label={"Retailers"}
                                placeholder={"Seleccionar uno o más Retailers ..."}
                                onChange={handleFormElementChange}
                                className={`field field_retailers`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RetailersIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.retailers}
                            />
                            <div className='box_options'>
                                {retailers?.map((retailer, index) => {
                                    const name = getNameFromDataById(basicData.retailers, retailer);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-retailer-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("retailers", retailer)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                        <div className='box_field'>
                            <SelectCustom 
                                name={"formats"}
                                label={"Formato"}
                                placeholder={"Seleccionar uno o más Formatos ..."}
                                onChange={handleFormElementChange}
                                className={`field field_formats`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<FormatIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.formats}
                            />
                            <div className='box_options'>
                                {formats?.map((format, index) => {
                                    const name = getNameFromDataById(basicData.formats, format);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-format-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("formats", format)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>

                        <div className='box_field'>
                            <SelectCustom 
                                name={"departments"}
                                label={"Departamentos"}
                                placeholder={"Seleccionar uno o más Departamentos ..."}
                                onChange={handleFormElementChange}
                                className={`field field_departments`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.departments}
                            />
                            <div className='box_options'>
                                {departments?.map((department, index) => {
                                    const name = getNameFromDataById(basicData.departments, department);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-department-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("departments", department)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                        <div className='box_field'>
                            <SelectCustom 
                                name={"achievements"}
                                label={"Logros"}
                                placeholder={"Seleccionar uno o más Logros ..."}
                                onChange={handleFormElementChange}
                                className={`field field_achievements`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.achievements}
                            />
                            <div className='box_options'>
                                {achievements?.map((achievement, index) => {
                                    const name = getNameFromDataById(basicData.achievements, achievement);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-achievement-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("achievements", achievement)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>

                        <div className='box_field'>
                            <SelectCustom 
                                name={"flags"}
                                label={"Banderas"}
                                placeholder={"Seleccionar uno o más Banderas ..."}
                                onChange={handleFormElementChange}
                                className={`field field_flags`}
                                type="text"
                                value={""}
                                help={null}
                                isRequired={false}
                                styles={ formStyle }
                                label_icon={<RoleIcon />}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.flags}
                            />
                            <div className='box_options'>
                                {flags?.map((flag, index) => {
                                    const name = getNameFromDataById(basicData.flags, flag);
                                    return(
                                        <>
                                            {name ? 
                                                <div key={`segmentation-achievement-${index}`} className={`option`}>
                                                    <div className="close" onClick={() => handleRemoveOption("flags", flag)}>x</div>
                                                    <p>{ name }</p>
                                                </div>
                                            : ""}
                                        </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            : ""}
            
        </WrapperStyled>
    );
}

export default React.memo(Form);