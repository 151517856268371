import React, { Suspense } from 'react';
import ViewContainer from "./ViewContainer";

const ViewStore = (props) => {
    return (
        <ViewContainer {...props} />
    );
};

export default ViewStore;


