import React from "react";
import CountrySelectContainer from "./CountrySelectContainer";

const CountrySelect = (props) => {
  return (
      <CountrySelectContainer {...props} />
  );
};

export default CountrySelect;
