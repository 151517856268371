import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import Form from '../../../components/Stores/Form';
import SuccessForm from '../../../components/elements/SuccessForm';
import successImage from '../../../assets/images/img_success_stores.svg';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

const WrapperStyled = styled.div`
    width: 100%;
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .loader{
        margin-top: 20px;
        margin-bottom: 20px;
    }
`

const CreateContainer = (props) => {

    const { id_store } = useParams();

    const { getStore,
            store,
            setStore } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);
                       
    const [ success, setSuccess ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
        
    useEffect(() => {

        getStore(id_store);
        
        return () => {}
    }, [])

   

    useEffect(() => {
        
        return () => { setStore(null); }
    }, [])


    return (
        <WrapperStyled pancolors={panColors}>
            
            {!success ? 
                store ?
                <Form 
                    data={store}
                    action="edit"
                    loadingForm={loadingForm}
                    setLoadingForm={setLoadingForm}
                    success={success}
                    setSuccess={setSuccess}
                /> 
                : 
                <div className='loader'>
                    <LoaderComponent />
                </div>
            :
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={"Tienda editada con éxito."}
                    resultSuccessText={"Ahora se puede encontrar esta tienda editada en la lista de tiendas."}
                    buttonBackToListText={"Volver a listado de tiendas"}
                    buttonCreateNewText={"Crear tienda nueva"}
                    buttonBackToListStyle={"normal"}
                    buttonCreateNewStyle={"invert"}
                    createNew={"/stores/create"}
                    backTo={"/stores"}
                    setSuccess={setSuccess}
                />
            }

        </WrapperStyled>
    );
}

export default CreateContainer;