import React from "react";
import DashboardContainer from "./DashboardContainer";

const Dashboard = (props) => {
  return (
      <DashboardContainer {...props} />
  );
};

export default Dashboard;
