import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';
import InputCustom from '../elements/InputCustom';
import InputCheckboxCustom from '../elements/InputCheckboxCustom';
import SelectCustom from '../elements/SelectCustom';                     
import ButtonTextCustom from '../elements/ButtonTextCustom';
import ModalImageMultiLang from '../elements/Modals/ModalImageMultiLang';
import Avatar from '@mui/material/Avatar';

import { ImageIcon,
         TrashIcon, } from '../../helpers/icons';

import { dataObjectMultipleChoice } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
        margin-top: 35px;
    }
    .field_title{
        margin-top: 25px;
    }
    .box_options{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        .option{
            display: flex;
            flex-direction: row;
            align-content: center;
            width: 100%;
            margin-bottom: 20px;
            .bt_delete_answer{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid ${props => `rgb(${props.pancolors.red})`};
                border-radius: 6px;
                box-sizing: border-box;
                padding: 10px 13px;
                margin-left: 15px;
                cursor: pointer;
                svg{
                    fill: ${props => `rgb(${props.pancolors.red})`};
                }
                :hover{
                    background-color: ${props => `rgb(${props.pancolors.red})`};
                    svg{
                        fill: ${props => `rgb(${props.pancolors.white})`};
                    }
                }
                &.disabled{
                    cursor: default;
                    border: 1px solid ${props => `rgb(${props.pancolors.middle_gray})`};
                    background-color: transparent!important;
                    svg{
                        fill: ${props => `rgb(${props.pancolors.middle_gray})`};
                    }
                }
            }
            .field_correct{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 40px;
                .inner{
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    input{
                        padding: 0;
                        margin: 0;
                        min-height: auto;
                    }
                }
            } 
            .field_label{
                width: ${props => props.questions == null ? "calc(100% - 40px)" : "calc(100% - 400px)"};
                margin-right: ${props => props.questions == null ? "0" : "20px"};
            }
            .defered_answer{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                width: 400px;
                .field_go_to{
                    width: 210px;
                    .label{
                        margin-bottom: 5px;
                        min-height: auto;
                    }
                }
                .field_finish{
                    display: flex;
                    align-self: flex-end;
                    margin-bottom: 32px;
                    .inner{
                        width: 155px;
                        
                        .label_finish{
                            width: calc(100% - 16px)!important;
                            margin-bottom: 0;
                        }
                    }
                    .label{
                        width: 100%;
                        label{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        
    }
    .image_reference{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        .image{
            display: flex;
            width: 150px;
            height: 150px;
            flex-direction: row;
            align-items: center;
            position: relative;
            .loader{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                z-index: 1;
            }
            .no_image{
                .icon{
                    width: 84px;
                    height: 84px;
                }
            }
            
        }
        .data{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            .bt_add_image,
            .bt_delete_image{
                height: auto;
                padding: 0;
                p{
                    margin: 0;
                }
                &.is_loading{
                    p{
                        color: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                    .icon svg{
                        fill: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                }
            }
            .bt_delete_image{
                margin-top: 10px;
            }
            .aclaration_image{
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: rgb(108, 108, 153);
                margin-top: 10px;
                &.is_loading{
                    color: ${props => `rgb(${props.pancolors.light_gray})`};
                }
            }
        }
    }
    .bt_add_answer{
        padding: 0;
        p{
            margin: 0;
        }
    }
`

const MultipleChoice = (props) => {

    const { panColors } = useContext(ConfigurationContext);
    
    const { uploadFile, 
            getByPassImageUploadRoute } = useContext(ContentContext);

    const { scope = "evaluation",
            questions,
            imageReference = false,
            response_multiple,
            questionSelected,
            setQuestionSelected,
            contentByLang, 
            setContentByLang,
            questionSelectedOrder,
            lang = null,
            setBlockAction,
            disabled = false } = props;
            
    const [ questionsList, setQuestionsList ] = useState([]);
    const [ questionImage, setQuestionImage ] = useState(null);
    const [ openModalImage, setOpenModalImage ] = useState(false);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ minAnswers, setMinAnswers ] = useState(null);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
            
    const handleFormElementChange = (name, value, index) => {

        let question_selected_update = {...questionSelected};
        let secondary_actions_update = {...question_selected_update.translations[lang].secondary_actions[index]};
        
        switch (name) {
            case "label":
                secondary_actions_update.label.value = value;
                break;
            case "correct":
                if(!response_multiple){
                    let corrects = question_selected_update.translations[lang].secondary_actions.map((q, i) => {
                        if(i !== index){
                            q.correct.value = false;
                        }else{
                            q.correct.value = true;
                        }
                        return q;
                    })
                    question_selected_update.translations[lang].secondary_actions = corrects;
                }else{
                    secondary_actions_update.correct.value = !secondary_actions_update.correct.value;
                }
                break;
            case "go_to":
                secondary_actions_update.go_to.value = value;
                break;
            case "title":
                question_selected_update.translations[lang].title.value = value;
                break;
            case "finish":
                secondary_actions_update.finish.value = !secondary_actions_update.finish.value;
                break;
            default:
                break;
        }
        
        
        question_selected_update.translations[lang].secondary_actions[index] = secondary_actions_update;
        setQuestionSelected(question_selected_update);

    }

    const handleDeleteQuestionImage = () => {
        setCroppedImage(null);
        setQuestionImage(null);
        
        let question_selected_update = {...questionSelected};
        question_selected_update.media.cropped_image = "";
        question_selected_update.media.value = "";
        setQuestionSelected(question_selected_update);

        let contentByLangUpdate = {...contentByLang};
        contentByLangUpdate[lang].questions[questionSelectedOrder-1] = question_selected_update;
        setContentByLang(contentByLangUpdate);

    }

    const handleAddAnswer = () => {

        let arr_answers = {...questionSelected};
        arr_answers.translations[lang].secondary_actions.push(dataObjectMultipleChoice(null, "evaluation_lineal"));
        setQuestionSelected(arr_answers);

    }

    const handleUploadFile = (imagen) => {
        
        let cropped_image = imagen;
        let upload_route = null;

        const payload = { name: "image-"+uuid()+".jpeg",
                          file: cropped_image };
            
        const uploadRoute = getByPassImageUploadRoute(payload);

        uploadRoute.then((res) => {
            
            upload_route = res;
            const uploaded = uploadFile(cropped_image, upload_route);

            uploaded.then(uploaded_res => {

                setBlockAction(false);
                let question_selected_update = {...questionSelected};
                question_selected_update.translations[lang].media.loading = false;
                question_selected_update.translations[lang].media.value = uploaded_res;
                setQuestionSelected(question_selected_update);
                setCroppedImage(null);

            })
        })
    }

    useEffect(() => {
      
        if(questions){
            const questions_updates = questions.map((question, index) => {
                return {_id: (index + 1), name: (index + 1)}
            })
            setQuestionsList(questions_updates);
        }
    
      return () => {}
    }, [questions])

    useEffect(() => {
        
        if(croppedImage){
            setBlockAction(true);
            handleUploadFile(croppedImage);
            let question_selected_update = {...questionSelected};
            question_selected_update.translations[lang].media.loading = true;
            question_selected_update.translations[lang].media.value = croppedImage;
            setQuestionSelected(question_selected_update);

        }
    
      return () => {} 
    }, [croppedImage])

    useEffect(() => {
      
        if(response_multiple){
            setMinAnswers(3);
        }else{
            setMinAnswers(2);
        }
    
      return () => {}
    }, [])
    

    const handleDeleteAnswer = (item, index) => {

        let arr_answers = {...questionSelected};
        const secondary_actions_removed = questionSelected.translations[lang].secondary_actions.filter((answer, i) => i !== index);
        arr_answers.translations[lang].secondary_actions = secondary_actions_removed;
        setQuestionSelected(arr_answers);
        
    }
    
    //return null;
    if(!Object.keys(questionSelected).length){
        return null;
    }

    return (
        <WrapperStyled 
            pancolors={panColors}
            questions={questions}
        >

            {openModalImage &&
                <ModalImageMultiLang 
                    openModalImage={openModalImage} 
                    setOpenModalImage={setOpenModalImage} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                    scope={"cover_image"}
                    questionSelected={questionSelected}
                    setQuestionSelected={setQuestionSelected}
                    lang={lang}
                    maxSize={""}
                />
            }

            <InputCustom 
                name={questionSelected.translations[lang].title.ref}
                label={questionSelected.translations[lang].title.label}
                placeholder={questionSelected.translations[lang].title.placeholder}
                onChange={handleFormElementChange}
                onKeyDown={null}
                className={`field field_${questionSelected.translations[lang].title.ref}${questionSelected.translations[lang].title.error ? " error" : ""}`}
                type="text"
                value={questionSelected.translations[lang].title.value}
                help={questionSelected.translations[lang].title.help}
                isRequired={questionSelected.translations[lang].title.required}
                styles={ formStyle }
                label_icon={null}
                tooltip_content={null}
                haveError={questionSelected.translations[lang].title.error}
            />
            
            {imageReference && 
                <>
                <div className="title">Agregar imagen complementaría a la pregunta</div>
                <div className="image_reference">
                    <div className="image">
                        { questionSelected.translations[lang].media.loading ? <div className="loader"><LoaderComponent /></div> : "" }
                        <Avatar
                            alt="Remy Sharp"
                            src={questionSelected.translations[lang]?.media?.value ? questionSelected.translations[lang]?.media?.value : ""}
                            sx={{ width: 150, height: 150 }}
                            variant="square"
                            className="no_image"
                        >
                            <ImageIcon className="icon" />
                        </Avatar>
                    </div>
                    <div className="data">
                        {!questionSelected.translations[lang].media.value &&
                        <ButtonTextCustom 
                            className={`bt_add_image${questionSelected.translations[lang].media.loading ? " is_loading" : ""}`}
                            text="+ Agregar/Modificar imagen"
                            onClick={() => { !questionSelected.translations[lang].media.loading && setOpenModalImage(true) }}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                        />
                        }
                        {questionSelected.translations[lang].media.value &&
                            <ButtonTextCustom 
                                className={`bt_delete_image${questionSelected.translations[lang].media.loading ? " is_loading" : ""}`}
                                text="Borrar"
                                onClick={() => { !questionSelected.translations[lang].media.loading && handleDeleteQuestionImage() }}

                                icon={<TrashIcon />}
                                background={{ normal: "", hover: "" }}
                                border={{ normal: "", hover: "" }}
                                textcolor={{ normal: panColors.red, hover: panColors.red }}
                            />
                        }
                    </div>
                    
                    
                </div>
                </>
            }

            <div className="title">Seleccionar las opciones correctas: *</div>

            <div className="box_options">
                
                {questionSelected.translations[lang]?.secondary_actions?.map((item, index) => {
                    return(
                        <div key={`option-${index}`} className="option">
                            {scope !== "survey" ?
                            <InputCheckboxCustom
                                name={item?.correct?.ref}
                                label={item?.correct?.label}
                                placeholder={item?.correct?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${item?.correct?.ref}${item?.correct?.error ? " error" : ""}`}
                                type="checkbox"
                                value={item?.correct?.value}
                                help={item?.correct?.help}
                                isRequired={item?.correct?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={item?.correct?.error}
                                index={index}
                            /> : ""}
                            <InputCustom 
                                name={item?.label?.ref}
                                label={item?.label?.label}
                                placeholder={item?.label?.placeholder}
                                onChange={handleFormElementChange}
                                className={`field field_${item?.label?.ref}${item?.label?.error ? " error" : ""}`}
                                type="text"
                                value={item?.label?.value}
                                help={item?.label?.help}
                                isRequired={item?.label?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={item?.label?.error}
                                index={index}
                            />
                            {questions &&
                                <div className='defered_answer'>
                                    <SelectCustom 
                                        name={item?.go_to?.ref}
                                        label={item?.go_to?.label}
                                        placeholder={item?.go_to?.placeholder}
                                        onChange={handleFormElementChange}
                                        className={`field field_${item?.go_to?.ref}${item?.go_to?.error ? " error" : ""}`}
                                        type="text"
                                        value={item?.go_to?.value}
                                        help={item?.go_to?.help}
                                        isRequired={item?.go_to?.required}
                                        styles={ formStyle }
                                        label_icon={null}
                                        tooltip_content={null}
                                        haveError={item?.go_to?.error}
                                        data={questionsList}
                                        index={index}
                                    />
                                    <InputCheckboxCustom
                                        name={item?.finish?.ref}
                                        label={item?.finish?.label}
                                        placeholder={item?.finish?.placeholder}
                                        onChange={handleFormElementChange}
                                        className={`field field_${item?.finish?.ref}${item?.finish?.error ? " error" : ""}`}
                                        labelPosition={"right"}
                                        value={item?.finish?.value}
                                        help={item?.finish?.help}
                                        isRequired={item?.finish?.required}
                                        styles={ formStyle }
                                        label_icon={null}
                                        tooltip_content={null}
                                        haveError={item?.finish?.error}
                                        index={index}
                                    />
                                </div>
                            }
                            
                            <div 
                                className={`bt_delete_answer${(questionSelected.translations[lang]?.secondary_actions.length > minAnswers && !disabled) ? "" : " disabled"}`} 
                                onClick={
                                    () => { 
                                        if(questionSelected.translations[lang]?.secondary_actions.length > minAnswers && !disabled){
                                            handleDeleteAnswer(item, index);
                                        }
                                    }
                                }>
                                <TrashIcon />
                            </div>
                        </div>
                    )
                })}
                
                {!disabled ?
                    <ButtonTextCustom 
                        className="bt_add_answer"
                        text="+ Agregar Respuesta extra"
                        onClick={handleAddAnswer}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                    /> 
                : ""}
                
                
            </div>

        </WrapperStyled>
    );
}

export default React.memo(MultipleChoice);