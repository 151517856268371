import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import CircularProgress from '@mui/material/CircularProgress';
import {getDevicePixelRatio} from 'use-device-pixel-ratio';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { DateTime } from "luxon";

import { CameraIcon } from '../../../helpers/icons';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import ButtonCustom from '../../../components/elements/ButtonCustom';

import 'react-image-crop/dist/ReactCrop.css';

const ModalImageProfileStyled = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 16px;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 4vw!important;
    overflow: scroll;
    margin: 0 auto;
    //width: 95vw;
    width: 600px;
    
    //height: 100%;
    display: block;
    z-index: 99999!important;
`
const ContainerModalProfileImage = styled.div`
  position: relative;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 16px;
  outline: 0!important;
  -webkit-box-shadow: 0px 0px 15px -5px #000000;
  box-shadow: 0px 0px 15px -5px #000000;

  .modal_block{
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 99;
    border-radius: 16px;
    svg{
      color: #FFD200!important;
    }
  }
  .actions{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .bt_save{
      width: calc(50% - 3px)!important;
    }
    .bt_cancel{
      width: calc(50% - 3px)!important;
    }
  }
  .content_action_browse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 1px dashed #CCCCCC;
    margin-top: 0px;
    //padding-top: 40px;
    //padding-bottom: 40px;
    input{
      display: none;
    }
  }
  .modal_imageprofile{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    .modal_imageprofile_container{
      display: flex;
      flex-direction: column;
      width: 100%;
      .modal_imageprofile_image{
        display: flex;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
      }
    }
  }
`
const ButtonBrowseProfileImage = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
    border: 1px dashed ${props => `rgb(${props.panColors.middle_gray})`};
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 10px;
    .icon{
        display: flex;
        svg{
            width: 50px;
            height: 50px;
            fill: ${props => `rgb(${props.panColors.dots})`};
        }
    }
    :hover{
        background-color: ${props => `rgba(${props.panColors.dots}, 0.05)`};
        border: 1px dashed ${props => `rgb(${props.panColors.middle_gray})`};
    }
`

const ModalImageProfile = (props) => {

    const { window, 
            openModalImageProfile, 
            setOpenModalImageProfile,
            setCroppedImage,
            setUploadRoute } = props;
    
    const { getByPassImageUploadRoute } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);
    
    
    
    const [ loading, setLoading ] = useState(false);
    const [ src, selectFile ] = useState(null);
    const [ upImg, setUpImg ] = useState();
    
    const inputFileRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);

    const [ completedCrop, setCompletedCrop ] = useState(null);
    const [ crop, setCrop ] = useState({
        unit: 'px',
        width: 250,
        minWidth: 250,
        minHeight: 250,
        maxWidth: 500,
        maxHeight: 500,
        aspect: 1 / 1
    });

    const handleGenerateThumb = () => {

        setLoading(true);
        const datetime = DateTime.fromMillis(Date.now());
        
        const croppedImg = getCroppedImg();
        const payload = { name: "profile_image-"+datetime.toMillis()+".jpeg",
                          file: croppedImg };
                          
        const uploadRoute = getByPassImageUploadRoute(payload);

        uploadRoute.then((res) => {
            setUploadRoute(res);
            setCroppedImage(croppedImg);
            setOpenModalImageProfile(false);
            selectFile(null);
            setLoading(false);
        })

    }

    const getCroppedImg = () => {

        const canvas = document.createElement("canvas");
        const scaleX = upImg.naturalWidth / upImg.width;
        const scaleY = upImg.naturalHeight / upImg.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        const pixelRatio = getDevicePixelRatio();
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
    
        ctx.drawImage( upImg,
                       crop.x * scaleX,
                       crop.y * scaleY,
                       crop.width * scaleX,
                       crop.height * scaleY,
                       0,
                       0,
                       crop.width,
                       crop.height );
    
        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;

    }

    const handleCancelImageProfile = () => {

        setOpenModalImageProfile(false);

    }

    const handleBrowseProfileImage = () => {

        inputFileRef.current.click();

    }

    const onSelectFile = (e) => {
        selectFile(URL.createObjectURL(e.target.files[0]));
        setUpImg(e.target.files[0]);
    };
    

    useEffect(() => {

        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

    }, [completedCrop]);
    
    useEffect(() => {
    
      return () => {
        selectFile(null);
      }
    }, [])
    

    return (

        <ModalImageProfileStyled
            disableAutoFocus={true}
            open={openModalImageProfile}
            onClose={() => setOpenModalImageProfile(!openModalImageProfile)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
        }}
        >
            <Fade in={openModalImageProfile}>

                <ContainerModalProfileImage>

                    {loading &&
                        <div className="modal_block">
                            <CircularProgress />
                        </div>
                    }
                    <div className="modal_imageprofile">

                        {src ?
                            <div className="modal_imageprofile_container">
                                <div className="modal_imageprofile_image">
                                    <ReactCrop
                                        src={src}
                                        onImageLoaded={setUpImg}
                                        crop={crop}
                                        onChange={(c) => setCrop(c)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                    />
                                </div>

                                <div className="actions">

                                    <ButtonCustom 
                                        className="bt_save"
                                        text="Guardar Imagen"
                                        onClick={() => handleGenerateThumb()}
                                        style_type="normal"
                                        
                                        icon={null}
                                        background={{ normal: panColors.primary, hover: panColors.white }}
                                        border={{ normal: panColors.primary, hover: panColors.primary }}
                                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                    />

                                    <ButtonCustom 
                                        className="bt_cancel"
                                        text="Cancelar"
                                        onClick={() => handleCancelImageProfile()}
                                        style_type="normal"
                                        
                                        icon={null}
                                        background={{ normal: panColors.primary, hover: panColors.white }}
                                        border={{ normal: panColors.primary, hover: panColors.primary }}
                                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                    />

                                </div>
                            </div>

                        :

                            <div className="content_action_browse">
                                <input ref={inputFileRef} type="file" accept="image/*" onChange={(e) => onSelectFile(e)} />
                                <ButtonBrowseProfileImage 
                                    panColors={panColors}
                                    onClick={() => handleBrowseProfileImage()}
                                >
                                    <div className="icon">
                                        <CameraIcon />
                                    </div>
                                </ButtonBrowseProfileImage>

                                <ButtonCustom 
                                    className="bt_cancel"
                                    text="Cerrar"
                                    onClick={() => handleCancelImageProfile()}
                                    style_type="normal"
                                    
                                    icon={null}
                                    background={{ normal: panColors.primary, hover: panColors.white }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                />

                            </div>

                        }


                    </div>
                </ContainerModalProfileImage>

            </Fade>

        </ModalImageProfileStyled>

      
    )
                
}

export default ModalImageProfile;
