import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 44px;
    color: ${props => `rgb(${props.textcolor.normal})`};
    border-radius: 4px;
    cursor: ${props => props.disabled ? "default" : "pointer"};
    >.icon{
        display: flex;
        margin-right: 7px;
        svg{
            fill: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.textcolor.normal})`};
        }
    }
    >.text{
        margin: 0;
        color: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.textcolor.normal})`};
        font-weight: 700;
        font-size: 16px;
        margin-left: 7px;
    }
    :hover{
        >.text{
                color: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.textcolor.hover})`};
                text-decoration: ${props => props.disabled ? "none" : "underline"};
                text-underline-offset: 5px;
        }
        >.icon{
            svg{
                fill: ${props => `rgb(${props.disabled ? props.pancolors.middle_gray : props.textcolor.hover})`};
            }
        }
    }
    
`

const ButtonTextCustom = (props) => {

    const { panColors,
            fonts } = useContext(ConfigurationContext);

    const { text,
            onClick,
            className,
            style_type,
            icon,
            button_type,
            background, 
            border, 
            textcolor,
            disabled } = props;
    
    return (
        <Wrapper 
            pancolors={panColors}
            background={background}
            border={border}
            textcolor={textcolor} 
            fonts={fonts} 
            onClick={onClick} 
            className={className} 
            style_type={style_type}
            button_type={button_type}
            disabled={disabled}
        >
            
            {icon && <div className="icon">{ icon }</div>}
            <p className="text">{ text }</p>

        </Wrapper>

    )

}

export default ButtonTextCustom;