import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Form from '../../../components/elements/Users/Form';

import { ContentContext } from '../../../context';

import successImage from '../../../assets/images/img_success1.svg';
import SuccessForm from '../../../components/elements/SuccessForm';     
import LoaderComponent from '../../../components/elements/LoaderComponent';
import ScheduleCreate from '../../../components/elements/Dialogs/ScheduleCreate';
import 'react-image-crop/dist/ReactCrop.css';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    .loader{
        margin-top: 20px;
        margin-bottom: 20px;
    }
`

const CreateContainer = (props) => {
    
    const { user, 
            setUser,
            getUser } = useContext(ContentContext);

    const { id } = useParams();

    const [ scheduleCreateOpen, setScheduleCreateOpen ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    useEffect(() => {

        getUser(id);
        
        return () => {}
    }, [])

    useEffect(() => {
        return () => { setUser(null); }
    }, [])

    return (
        <WrapperStyled>

            <ScheduleCreate 
                open={scheduleCreateOpen} 
                setOpen={setScheduleCreateOpen} 
            />
            {!success ? 
                user != null ?
                    <Form 
                        data={user}
                        action="edit"
                        loadingForm={loadingForm}
                        setLoadingForm={setLoadingForm}
                        setSuccess={setSuccess}
                    />

                :

                <div className='loader'>
                    <LoaderComponent />
                </div>

            :

            <SuccessForm 
                image={successImage} 
                actionSuccessText={"Usuario editado con éxito."}
                resultSuccessText={"Ahora se puede encontrar a este usuario editado en la lista de usuarios."}
                buttonBackToListText={"Volver a listado de usuarios"}
                buttonCreateNewText={null}
                buttonBackToListStyle={"normal"}
                buttonCreateNewStyle={"invert"}
                createNew={null}
                backTo={"/users"}
                setSuccess={setSuccess}
            />

            }

        </WrapperStyled>
    )
                
}

export default CreateContainer;
