import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import InputCheckboxCustom from '../InputCheckboxCustom';
import InputCustom from '../InputCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import AditionalProductElement from '../../elements/AditionalProductElement';

import { ContentContext,
         ConfigurationContext } from '../../../context';


const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 1050px;
        //min-height: 700px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .icon{
                width: 90px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            .title{
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            .subtitle{
                margin: 10px 0 0 0;
                font-size: 18px;
                line-height: 22px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
        .label_no_segmentation{
            margin-bottom: 0;
        }
        .add_aditional_information{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 75px;
            margin-top: 15px;
            width: 100%;
            .bt_add_more_info{
                display: flex;
                flex-direction: row;
                align-self: flex-start;
                padding: 0;
                margin-bottom: 5px;
            }
            .aditional_information_el{
                justify-content: space-between!important;
                margin-bottom: 10px;
                width: 100%;
                &.error{
                    border: 1px solid red;
                }
            }
            .actions{
                width: 70px!important;
            }
            .fields{
                width: calc(100% - 130px)!important;
            }
            .no_product_error{
                color: red;
            }
        }
        
        .actions{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .bt_submit{

            }
            .bt_program_activation{
                margin-left: 10px;
            }
            .bt_cancel{
                margin-left: 10px;
            }
        }
        .box_options{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 15px;
        }
        
        .general_min_max{
            border: 1px dashed rgb(221 223 232);
            box-sizing: border-box;
            border-radius: 6px;
            padding: 20px 20px;
            box-sizing: border-box;
            margin-top: 5px;
            .inner{
                display: flex;
                flex-direction: column;
                max-width: 948px;
                .fields{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                    margin-top: 20px;
                    width: 100%;
                    .field{
                        display: flex;
                        flex-direction: column;
                        width: 150px!important;
                        margin-left: 10px;

                        &.min_general,
                        &.max_general{
                            &.bigger{
                            }
                        }
                        
                        :first-child{

                            margin-left: 0;
                        }
                    }
                    >.bt_submit{
                        margin-left: 10px;
                    }
                }
                .important{
                    width: 100%;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgb(221 223 232);
                    color: rgb(72, 77, 99);
                    .red{
                        color: red; 
                    }
                }
            }
        }
        .field_min_max_general{
            margin-left: 25px;
            .label_min_max_general{
                margin-bottom: 0;
            }
        }
        .field_all_products{
            .label_all_products{
                margin-bottom: 0;
            }
        }
    }
`

const Products = (props) => {

    const [ options, setOptions ] = useState([])
    const [ minGeneralSaved, setMinGeneralSaved ] = useState({});
    const [ maxGeneralSaved, setMaxGeneralSaved ] = useState({});
    
    const [ minmaxGeneralSaved, setMinmaxGeneralSaved ] = useState({});
    const [ allProductsSaved, setAllProductsSaved ] = useState({});
    const [ errorNoProduct, setErrorNoProduct ] = useState(false);
    
    const { getEcommerceProductsSearch } = useContext(ContentContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { panColors } = useContext(ConfigurationContext);

    const { open, 
            setOpen,
            setCounterProducts,
            productList,
            setProductList,
            allProducts, setAllProducts,
            minGeneral, setMinGeneral,
            maxGeneral, setMaxGeneral,
            minmaxGeneral, setMinmaxGeneral } = props;
    
    const [ products, setProducts ] = useState([]);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const handleCancel = () => {

        const max_saved_update = {...maxGeneralSaved};
        max_saved_update.value = "";
        setMaxGeneralSaved(max_saved_update);

        const min_saved_update = {...minGeneralSaved};
        min_saved_update.value = "";
        setMinGeneralSaved(min_saved_update);

        
        const allProductsSaved_update = {...setAllProductsSaved};
        allProductsSaved_update.value = false;
        setAllProductsSaved(allProductsSaved_update);
        
        const min_max_update_saved = {...minmaxGeneralSaved};
        min_max_update_saved.value = false;
        setMinmaxGeneralSaved(min_max_update_saved);

        setErrorNoProduct(false);
        setProducts([]);
        setOpen(false);
    };


    const handleSubmit = () => {

        let error = false;

        if(minmaxGeneralSaved.value){

            const min_saved_update = {...minGeneralSaved};
            if(!parseInt(minGeneralSaved.value) > 0){
            //if(!minGeneralSaved.value.toString().length){
                error = true;
                min_saved_update.error = true;
            }else{
                min_saved_update.error = false;
            }
            setMinGeneralSaved(min_saved_update);

            const max_saved_update = {...maxGeneralSaved};
            if(!parseInt(maxGeneralSaved.value) > 0){
            //if(!maxGeneralSaved.value.toString().length){
                error = true;
                max_saved_update.error = true;
            }else{
                max_saved_update.error = false;
            }
            setMaxGeneralSaved(max_saved_update);

        }

        if(!allProductsSaved.value){
            if(!products.length){
                error = true;
                setErrorNoProduct(true);
            }else{
                
                const products_validated = products.map(p => {
                    if(!p.p_id.length || !p.min.toString().length || !p.max.toString().length){
                    //if(!p.p_id.length || !parseInt(p.min) > 0 || !parseInt(p.max) > 0){
                        p.error = true;
                        error = true;
                    }else{
                        p.error = false;
                    }
                    return p;
                })
                setProducts(products_validated)
                
                setErrorNoProduct(false);
            }

        }

        if(!error){

            let temp_prods = [];
            if(allProductsSaved.value){
                options.map((op, index) => {
                    temp_prods.push({_id: op.value, min: minGeneral.value, max: maxGeneral.value})
                })
                setProductList(temp_prods);
            }else{
                products.map((product, index) => {
                    temp_prods.push({_id: product.p_id, min: product.min, max: product.max})
                })
                setProductList(temp_prods);
            }

            setMaxGeneral(maxGeneralSaved);
            setMinGeneral(minGeneralSaved);
            setMinmaxGeneral(minmaxGeneralSaved);
            setAllProducts(allProductsSaved);

            setOpen(false);
        }

        return null;

    }

    const handleAddAditionalProduct = () => {
        setErrorNoProduct(false);
        const _uuid = uuid();
        setProducts(products => [...products, {id: _uuid, p_id: "", min: "", max: '', error: false}]);
    }

    const handleFormElementChange = (name, value) => {

        let min_saved_update = {...minGeneralSaved};
        let max_saved_update = {...maxGeneralSaved};
        let min_max_update_saved = {...minmaxGeneralSaved};

        switch (name) {
            case "min_general":
                min_saved_update.value = value;
                min_saved_update.error = false;
                setMinGeneralSaved(min_saved_update);
                break;
            case "man_general":
                max_saved_update.value = value;
                max_saved_update.error = false;
                setMaxGeneralSaved(max_saved_update);
                break;
            case "min_max_general":
                min_max_update_saved.value = value;
                setMinmaxGeneralSaved(min_max_update_saved);
                
                if(min_max_update_saved.value){

                    min_saved_update.value = "";
                    min_saved_update.error = false;
                    setMinGeneralSaved(min_saved_update);
                    
                    max_saved_update.value = "";
                    max_saved_update.error = false;
                    setMaxGeneralSaved(max_saved_update);

                }
                
                break;
            case "all_products":
                setProducts([]);

                min_saved_update.value = "";
                min_saved_update.error = false;
                setMinGeneralSaved(min_saved_update);

                max_saved_update.value = "";
                max_saved_update.error = false;
                setMaxGeneralSaved(max_saved_update);

                if(value){
                    min_max_update_saved.value = true;
                    setMinmaxGeneralSaved(min_max_update_saved);
                }else{
                    min_max_update_saved.value = false;
                    setMinmaxGeneralSaved(min_max_update_saved);
                }

                const allproducts_update = {...allProductsSaved};
                allproducts_update.value = value;
                setAllProductsSaved(allproducts_update);

                break;
            default:
                break;
        }

    }

    const handleApplyMinMax = () => {

        let error = false;
        const min_saved_update = {...minGeneralSaved};
        if(!parseInt(minGeneralSaved.value) > 0){
        //if(!minGeneralSaved.value.length){
            error = true;
            min_saved_update.error = true;
        }else{
            min_saved_update.error = false;
        }
        setMinGeneralSaved(min_saved_update);

        const max_saved_update = {...maxGeneralSaved};
        if(!parseInt(maxGeneralSaved.value) > 0){
        //if(!maxGeneralSaved.value.length){
            error = true;
            max_saved_update.error = true;
        }else{
            max_saved_update.error = false;
        }
        setMaxGeneralSaved(max_saved_update);

        if(!error){
            const products_update = [...products];
            
            products_update.map((product, index) => {
                product.min = minGeneralSaved.value;
                product.max = maxGeneralSaved.value;
                return product;
            })
            setProducts(products_update);
        }

    }

    useEffect(() => {
      
        const response = getEcommerceProductsSearch(0, 1000, "");
        response.then(res => {
            let options_arr = [];
            res.data.single.products.map((product, index) => {
                if(product.name.length || product.ean.length){
                    options_arr.push({label: `${product.name}${product.ean.length ? ' - ' + product.ean : ''}`, value: product._id});
                }
            })
            setOptions(options_arr);
        })
    
      return () => {}
    }, [])

    useEffect(() => {
        if(products){
            setCounterProducts(products.length);
        }else{
            setCounterProducts(0);
        }
    return () => {}
    }, [products])
    
    useEffect(() => {

        if(open){
            if(productList.length){
                let temp_list = [];
                productList.map((pl, index) => {
                    temp_list.push({id: uuid(), p_id: pl._id, min: pl.min, max: pl.max})
                })
                setProducts(temp_list);
            }
            setAllProductsSaved(allProducts)
            setMinmaxGeneralSaved(minmaxGeneral)
            setMinGeneralSaved(minGeneral)
            setMaxGeneralSaved(maxGeneral)
        }
    
      return () => {}
    }, [open])
    
    

    return (
        <DialogStyled
            fullScreen={fullScreen}
            open={open}
            onClose={handleCancel}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
            pancolors={panColors}
        >
            <div className="inner_dialog">

                <div className='header_dialog'>
                    <div className='title'>Productos del Beneficio</div>
                </div>
                
                <div className='box_options'>
                    <InputCheckboxCustom 
                        name={allProductsSaved.name}
                        label={allProductsSaved.label}
                        placeholder={""}
                        onChange={handleFormElementChange}
                        onKeyDown={null}
                        className={`field field_${allProductsSaved.name} ${allProductsSaved.error ? " error" : ""}`}
                        type="text"
                        value={allProductsSaved.value}
                        help={""}
                        isRequired={false}
                        styles={ formStyle }
                        label_icon={null}
                        labelPosition={"right"}
                        tooltip_content={null}
                        haveError={allProductsSaved.error ? true : false}
                    />

                    <InputCheckboxCustom 
                        name={minmaxGeneralSaved.name}
                        label={minmaxGeneralSaved.label}
                        placeholder={""}
                        onChange={handleFormElementChange}
                        onKeyDown={null}
                        className={`field field_${minmaxGeneralSaved.name} ${minmaxGeneralSaved.error ? " error" : ""}`}
                        type="text"
                        value={minmaxGeneralSaved.value}
                        help={""}
                        isRequired={false}
                        styles={ formStyle }
                        label_icon={null}
                        labelPosition={"right"}
                        tooltip_content={null}
                        disabled={allProductsSaved.value}
                        haveError={minmaxGeneralSaved.error ? true : false}
                    />
                </div>

                {minmaxGeneralSaved.value ?
                <div className='general_min_max'>
                    <div className='inner'>
                        <div className='important'>
                            <span className='red'>Importante:</span> Los valores "mínimo" y "máximo" ingresados aquí reemplazarán a los valores individuales de cualquier producto cargado.
                        </div>
                        <div className="fields">
                            <div className={`field min_general${allProducts.value ? " bigger" : ""}`}>
                                <InputCustom 
                                    name={minGeneralSaved.name}
                                    label={minGeneralSaved.label}
                                    placeholder={minGeneralSaved.placeholder}
                                    onChange={handleFormElementChange}
                                    onKeyDown={null}
                                    className={`field field_general_min ${minGeneralSaved.error ? " error" : ""}`}
                                    type="text"
                                    value={minGeneralSaved.value}
                                    help={""}
                                    isRequired={false}
                                    styles={ formStyle }
                                    label_icon={null}
                                    tooltip_content={null}
                                    haveError={minGeneralSaved.error ? true : false}
                                />
                            </div>
                            <div className={`field max_general${allProducts.value ? " bigger" : ""}`}>
                                <InputCustom 
                                    name={maxGeneralSaved.name}
                                    label={maxGeneralSaved.label}
                                    placeholder={maxGeneralSaved.placeholder}
                                    onChange={handleFormElementChange}
                                    onKeyDown={null}
                                    className={`field field_general_min ${maxGeneralSaved.error ? " error" : ""}`}
                                    type="text"
                                    value={maxGeneralSaved.value}
                                    help={""}
                                    isRequired={false}
                                    styles={ formStyle }
                                    label_icon={null}
                                    tooltip_content={null}
                                    haveError={maxGeneralSaved.error ? true : false}
                                />
                            </div>
                            {!allProductsSaved.value ? 
                            <ButtonCustom 
                                className="bt_submit"
                                text={"Aplicar"}
                                onClick={handleApplyMinMax}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            /> : ""}
                        </div>
                    </div>
                </div> : ""}
                
                <div className="add_aditional_information">
                    

                    {(!allProductsSaved.value && products) &&
                        products.map((el, i) => {
                            return(
                                <AditionalProductElement 
                                    element={el}
                                    index={i}
                                    products={products}
                                    setProducts={setProducts}
                                    className={`aditional_information_el ${el?.error ? "error" : ""}`}
                                    options={options}
                                    setOptions={setOptions}
                                    minGeneralSaved={minGeneralSaved}
                                    maxGeneralSaved={maxGeneralSaved}
                                />
                            )
                        })
                    }

                    <ButtonTextCustom 
                        className="bt_add_more_info"
                        text="+ Agregar producto"
                        onClick={() => !allProductsSaved.value && handleAddAditionalProduct()}
                        disabled={allProductsSaved.value}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                    />
                    {errorNoProduct ? 
                    <div className='no_product_error'>
                        Tenés que seleccionar al menos 1 producto.
                    </div> : ""}

                </div>

                <div className="actions">
                    <ButtonCustom 
                        className="bt_submit"
                        text={"Guardar"}
                        onClick={handleSubmit}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                    <ButtonTextCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => handleCancel()}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                    />
                </div>

            </div>
        </DialogStyled>
    );
}

export default Products;