import React, { useContext, useEffect, useState, Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import SelectCustom from '../elements/SelectCustom';
import InputCustom from '../elements/InputCustom';
import ButtonCustom from '../elements/ButtonCustom';
import TextareaCustom from '../elements/TextareaCustom';
import ActionBox from '../elements/ActionBox';
import MultipleChoice from '../Evaluations/MultipleChoice';
import OpenAnswer from '../Evaluations/OpenAnswer';
import SuccessForm from '../elements/SuccessForm';
import successImage from '../../assets/images/img_success_benefits.svg';
import LoaderComponent from '../elements/LoaderComponent';

import { useParams } from 'react-router-dom';

import ScheduleDateTimeRange from '../elements/Dialogs/ScheduleDateTimeRange';

import { TitleIcon, 
         HeadlineIcon,
         CountIcon,
         CalendarMonthIcon,
         EditIcon } from '../../helpers/icons';

import { getQuestionTypeId, 
         dataObjectSurvey,
         dataObjectOpenAnswer,
         dataObjectMultipleChoice,
         dataObjectEvaluationsQuestionBasics,
         getQuestionTypeTitle } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

dayjs.extend(utc);
dayjs.extend(timezone);

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .evaluation_questions{
        display: grid; 
        grid-template-columns: repeat(15, 1fr);
        gap: 20px 0;
        width: 100%;
        margin: 0 0 30px 0;
        .question_item{
            display: flex;
            flex-direction: row;
            align-items: center;
            .question_bullet{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                .order_number{
                    color: #FFFFFF;
                    font-size: 25px;
                }
            }
            .line{
                width: calc(100% - 50px);
                height: 2px;
                background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
            }
            &.selected{
                .question_bullet{
                    cursor: default;
                    background-color: ${props => `rgb(${props.pancolors.dots})`}!important; 
                }
            }
        }
    }
    .question_type_container{
        padding: 25px 50px 25px 50px;
        box-sizing: border-box;
        background-color: ${props => `rgb(${props.pancolors.light_white})`};
        margin-top: 20px;
        border-radius: 8px;
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon{
                display: flex;
                svg{
                    fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                }
            }
            .text{
                color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                margin-left: 7px;
            }
        }
    }



    .action_box_segmentation,
    .action_box_evaluation{
        margin-bottom: 30px;
    }
    .box_form{
        display: grid; 
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
        .box_column{
            .action_box_evaluation{
                //margin-top: 50px;
            }
            .media_slides{
                margin-top: 30px;
                .no_multimedia_content{
                    box-sizing: border-box;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    border: 1px dashed ${props => `rgb(${props.pancolors.middle_gray})`};
                    color: ${props => `rgb(${props.pancolors.dark_gray})`};
                    font-weight: 600;
                }
            }
        }
        &.show{
            display: grid;
        }
        &.hide{
            display: none;
        }
    }
    .box_evaluation{
        &.show{
            display: flex;
        }
        &.hide{
            display: none;
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            &.file_publication_date{
                margin-bottom: 7px;
            }
            :last-child{
                margin-bottom: 0;
            }
            .datetime_activate_at{
                width: 100%;
                .MuiOutlinedInput-notchedOutline{
                    border-color: rgba(0, 0, 0, 0.23);
                }
                &.error{
                    .MuiOutlinedInput-notchedOutline{
                        border: 2px solid ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        .field_timezone{
            margin-bottom: 10px;
        }
        .field_immediate_publication{
            .label_immediate_publication{
                margin-bottom: 0;
            }
        }
        .field_description_long{
            margin-bottom: 0!important;
            textarea{
                height: 160px;
            }
        }
        .field_description_short{
            margin-bottom: 0!important;
            textarea{
                height: 120px;
            }
        }
        .bottom_textarea{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            font-size: 14px;
            margin-top: 5px;
            font-weight: 700;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            &.error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .box_field_description_long,
        .box_field_description_short,
        .box_field_title{
            width: 100%;
            margin-bottom: 25px!important;

            .error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .field_send_notification{
            .label_custom{
                margin-bottom: 0;
            }
        }
        .field_test_content{
            margin-bottom: 15px;
        }
    }

    .box_labels{
        width: 100%;
        .box_add_labels{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .field_labels_add{
                width: 100%;
            }
            .bt_add_label{
                margin: 15px 0;
                padding: 0;
                height: auto;
                p{
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .box_labels_selected{
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            flex-wrap: wrap;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border: 2px solid ${props => `rgb(${props.pancolors.dark_gray})`};
                border-radius: 8px;
                box-sizing: border-box;
                padding: 10px 15px;
                margin-bottom: 10px;
                margin-right: 10px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                font-weight: 600;
                &.exist{
                    border-color: ${props => `rgb(${props.pancolors.dots})`};
                    background-color: ${props => `rgb(${props.pancolors.dots})`};
                    color: #FFFFFF;
                }
                .close{
                    cursor: pointer;
                    :hover{
                        color: ${props => `rgb(${props.pancolors.red})`};
                    }
                }
                :first-child {
                    margin-left: 0;
                }
                p{
                    margin: 0 0 0 5px;
                }
            }
        }
    }
    .box_link_destination_type{
        width: 100%;
        //margin-top: 30px;
        .field_destination_title{
            margin-top: 15px;
        }
        .field_destination_link{
            margin-top: 15px;
        }
        .field_link_destination{
            margin-top: 11px;
        }
        .field_link_goto{
            margin-top: 15px;
        }
    }
    
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 20px;
        .bt_next_question{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0 0 0 10px;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }
`

const Form = (props) => {

    const { id_survey } = useParams();

    const { panColors } = useContext(ConfigurationContext);
    
    const { basicData, 
            createSurvey,
            editSurvey,
            getSurvey,
            survey,
            setSurvey } = useContext(ContentContext);

    const { lang = null,
            action,
            contentByLang, 
            setContentByLang} = props;
    
    const [ blockAction, setBlockAction ] = useState(false);
    const [ questionSelectedOrder, setQuestionSelectedOrder ] = useState(null);
    const [ questionSelected, setQuestionSelected ] = useState(null);
    const [ startDate, setStartDate ] = useState("");
    const [ endDate, setEndDate ] = useState("");
    const [ vigencyDateError, setVigencyDateError ] = useState(false);
    const [ openScheduleDateTimeRange, setOpenScheduleDateTimeRange ] = useState(false);    
    const [ questionQuantityToload, setQuestionQuantityToload ] = useState([]);
    const [ questionQuantity, setQuestionQuantity ] = useState(null);
    const [ questionsList, setQuestionsList ] = useState([]);
    const [ timezone, setTimezone ] = useState(dayjs.tz.guess());
    const [ data, setData ] = useState(null);
    const [ inputTypeError, setInputTypeError ] = useState(false);
    const [ createEvaluationStatus, setCreateEvaluationStatus ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ vigencySuccessText, setVigencySuccessText ] = useState(""); 
    const [ secondaryActionsCloned, setSecondaryActionsCloned ] = useState(null);
    const [ vigencyDataLoaded, setVigencyDataLoaded ] = useState(true);

    const [ evaluationTypes, setEvaluationTypes ] = useState([
        {
          "_id": "single_choice",
          "name": "Opción simple"
        },
        {
          "_id": "comments",
          "name": "Campo abierto"
        }
    ]);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    useEffect(() => {
        
        
    
      return () => {
        setData(null);
        setQuestionsList([]);
        setQuestionSelected(null);
        setQuestionQuantity(null);
        setQuestionSelectedOrder(null);
        setSurvey(null);
      }
    }, [])
    
    
    useEffect(() => {
        
        setQuestionSelectedOrder(0);

        const arrQuestionQuantityToload = [];
        for(let i = 1; i <= 10; i++){
            arrQuestionQuantityToload.push({_id: i, name: i});
        }
        setQuestionQuantityToload(arrQuestionQuantityToload);

        if(action === "create" ){
            setData(dataObjectSurvey(null));

        }

        if(action === "edit" ){
            getSurvey(id_survey);
        }

        return () => {}

    }, []);

    useEffect(() => {
        if(survey && basicData){
            setQuestionQuantity(survey.questions.length);
            setData(dataObjectSurvey(survey));

            const question_arr = [];
            for(let i=0; i<survey.questions.length; i++){

                survey.questions[i].input_type = getQuestionTypeTitle(basicData.questions_type, survey.questions[i].input_type);
                let data = dataObjectEvaluationsQuestionBasics(survey.questions[i], null);

                const secondary_actions_update = survey.questions[i].secondary_actions.map((secondary, index) => {
                    switch (survey.questions[i].input_type) {
                        case "single_choice":
                            return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                            break;
                        case "comments":
                            return dataObjectOpenAnswer(secondary);
                            break;
                                
                        default:
                            break;
                    }
                    
                })

                setStartDate(dayjs(survey.date_init).tz(dayjs.tz.guess()).format());
                setEndDate(dayjs(survey.date_finish).tz(dayjs.tz.guess()).format());
                setSecondaryActionsCloned(cloneDeep(secondary_actions_update));

                data.secondary_actions = secondary_actions_update;
                question_arr.push(data);

            }
            
            setQuestionsList(question_arr);
            setQuestionSelected(question_arr[0])
        }
    
      return () => {}
    }, [survey, basicData])

    useEffect(() => {

        if(action === "create"){
            setVigencyDataLoaded(false)
        }
        if(action === "edit"){
            if(startDate.length && endDate.length){
                setVigencyDataLoaded(false)
            }
        }
    
      return () => {}
    }, [startDate, endDate])
    
    

    const handlePrev = () => {
        setCreateEvaluationStatus(false);
        const prev_question = questionSelectedOrder - 1;
        setQuestionSelectedOrder(prev_question);
        setQuestionSelected(questionsList[prev_question]);
    }

    const handleNext = async (isConfirmation = false) => {
        const validated = await goNext(isConfirmation);
        if(!isConfirmation){
            setCreateEvaluationStatus(false);
            if(questionSelectedOrder < (questionsList.length - 1)){
                const next_question = questionSelectedOrder + 1;
                setQuestionSelectedOrder(next_question);
                setQuestionSelected(questionsList[next_question]);
            }
        }else{
            handleSendSurvey();
            setCreateEvaluationStatus(true);
        }
    }

    const goNext = (isConfirmation = false) => {
        return new Promise((resolve, reject) => {

            setCreateEvaluationStatus(false);
            const questionSelected_to_validate = {...questionSelected};

            let haveError = false;
            let haveMediaQuestion = false;
            let haveMediaAnswer = 0;
                
            Object.keys(questionSelected_to_validate).map(function(key) {
                if(key === "penalty_score" || key === "score"){
                    return null;
                }
                if(key !== "secondary_actions"){
                    if(questionSelected_to_validate[key].required){
                        if(!questionSelected_to_validate[key].value){
                            questionSelected_to_validate[key].error = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate[key].error = false;
                        }
                    }
                }
                
                if(questionSelected_to_validate.input_type.value === "single_choice"){
                    if(key === "secondary_actions"){
                        questionSelected_to_validate[key].map((secondary, index) => {
                            Object.keys(secondary).map(function(sakey) {
                                if(secondary[sakey].required){
                                    if(!secondary[sakey].value.length){
                                        secondary[sakey].error = true;
                                        haveError = true;
                                    }else{
                                        secondary[sakey].error = false;
                                    }
                                }
                            })
                            return secondary;
                        })
                    }
                }

                if(questionSelected_to_validate.input_type.value === "comments"){
                    if(key === "secondary_actions"){
                        if(!questionSelected_to_validate[key].length){
                            questionSelected_to_validate["secondary_actions_error"] = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate["secondary_actions_error"] = false;
                        }
                    }
                }

            })
            
            setQuestionSelected(questionSelected_to_validate);

            if(!haveError){
                const question_ok = questionsList.map((q, index) => {
                    if(questionSelectedOrder === index){
                        q = questionSelected_to_validate;
                    }
                    return q;
                })
                setQuestionsList(question_ok);
                
                resolve({status: true, haveMediaQuestion: haveMediaQuestion, haveMediaAnswer: haveMediaAnswer});
            }else{
                setCreateEvaluationStatus(false);
                reject({status: false});
            }
            
        })
        
    }
    
    const handleFormElementChange = (name, value) => {
        let updateData = {...data};
        
        switch (name) {
            case 'name':
                updateData[name].value = value;
                break;
            case 'description':
                updateData[name].value = value;
                break;
            case 'timezone':
                setTimezone(value);
                break;
            case 'question_quantity_toload':

                const question_arr = [];
                for(let i=0; i<value; i++){
                    question_arr.push(dataObjectEvaluationsQuestionBasics(null, null));
                }
                setQuestionsList(question_arr);
                setQuestionQuantity(value);
                break;
            case 'input_type':

                const type = "evaluation_lineal";
                const update_input_type = questionsList.map((q, i) => {
                    if(i === questionSelectedOrder){

                        const questionSelectedUpdate = {...q};
                        questionSelectedUpdate.title.value = "";
                        if(value === "single_choice"){
                            questionSelectedUpdate["secondary_actions"] = [dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type)];
                        }

                        if(value === "comments"){
                            questionSelectedUpdate["secondary_actions_error"] = false;
                            questionSelectedUpdate["secondary_actions"] = [];
                        }
                        questionSelectedUpdate.input_type.value = value;

                        setQuestionSelected(questionSelectedUpdate);
                        q = questionSelectedUpdate;
                        //q.input_type.value = value;

                    }
                    return q;
                })

                setQuestionsList(update_input_type);

                break;
            default:
                //updateData[name].value = value;
                break;
        }
        
        setData(updateData);
        
        return null;
    }

    const handleSendSurvey = () => {

        setBlockAction(true);
        let haveError = false;
        let updateData = {...data};
        
        if(!startDate && !dayjs(startDate).isValid()){
            setVigencyDateError(true);
            haveError = true;
        }

        if(!endDate && !dayjs(endDate).isValid()){
            setVigencyDateError(true);
            haveError = true;
        }

        if(dayjs(startDate).isValid() && dayjs(endDate).isValid()){
            setVigencyDateError(false);
        }

        if(!data.name.value.length){
            updateData.name.error = true;
            haveError = true;
        }else{
            updateData.name.error = false;
        }   
        setData(updateData);

        if(!haveError){

            setLoadingForm(true);
            const _dataCloned = cloneDeep(questionsList);
            const _dataCloned_updated = _dataCloned.map((el, i) => {
                delete el.id_module;
                delete el.score;
                delete el.penalty_score;
                delete el.description;
                if(Object.keys(el).length){
                    Object.keys(el).map(function(key) {
                        if(key !== "secondary_actions"){
                            if(key === "input_type"){
                                el[key] = getQuestionTypeId(basicData.questions_type, el[key].value);
                            }else{
                                el[key] = el[key].value;
                            }
                        }else{
                            el.secondary_actions.map((secondary_action, i) => {
                                delete secondary_action.correct;
                                delete secondary_action.finish;
                                delete secondary_action.go_to;
                                delete secondary_action.media;
                                Object.keys(secondary_action).map(function(sakey) {
                                    secondary_action[sakey] = secondary_action[sakey].value;
                                })
                            })
                        }
                    })
                }

                return el;
            })

            let payload = {};

            let _date_finish = "";
            let _date_init = "";

            payload.questions = JSON.stringify(_dataCloned);

            if(dayjs.tz.guess() === timezone){
                _date_finish = dayjs(dayjs(endDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
                _date_init = dayjs(dayjs(startDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            }else{
                _date_finish = dayjs.tz(dayjs(endDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
                _date_init = dayjs.tz(dayjs(startDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            }

            payload.date_init = _date_init;
            payload.date_finish = _date_finish;
            payload.name = data.name.value;
            payload.description = data.description.value;
            
            if(action === "create"){

                setVigencySuccessText(`(Vigencia establecida del: ${dayjs(startDate).format('YYYY-MM-DD HH:mm')} al ${dayjs(endDate).format('YYYY-MM-DD HH:mm')})`);
                
                payload.id_post = "";
                const response = createSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }
            if(action === "edit"){

                setVigencySuccessText(`(Vigencia establecida del: ${dayjs(startDate).format('YYYY-MM-DD HH:mm')} al ${dayjs(endDate).format('YYYY-MM-DD HH:mm')})`);
                
                payload.survey_id = data._id.value;

                const response = editSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }
        }else{
            setBlockAction(false);
        }
    }

    const handleSaveVigency = () => {
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    const handleVigencyRange = () => {
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    return (
        <WrapperStyled pancolors={panColors}>

            {!success ?
                <Fragment>
                    {!vigencyDataLoaded ? 
                    <ScheduleDateTimeRange 
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate} 
                        open={openScheduleDateTimeRange} 
                        setOpen={setOpenScheduleDateTimeRange}
                        handleSubmit={handleSaveVigency}
                        timezone={timezone}
                        setTimezone={setTimezone}
                    /> : ""}
                    
                    {data ?
                    <div className="box_form">
                        <div className="box_column">
                            <div className="box_fields">
                                <div className="title">
                                    Datos de la encuesta
                                </div>

                                <div className="field box_field_name">
                                    <InputCustom 
                                        name={data?.name?.ref}
                                        label={data?.name?.label}
                                        placeholder={data?.name?.placeholder}
                                        onChange={handleFormElementChange}
                                        onKeyDown={null}
                                        className={`field field_${data?.name?.ref}${data?.name?.error ? " error" : ""}`}
                                        type="text"
                                        value={data?.name?.value}
                                        help={""}
                                        isRequired={data?.name?.required}
                                        styles={ formStyle }
                                        label_icon={<TitleIcon />}
                                        tooltip_content={data?.name?.tooltip}
                                        haveError={data?.name?.error}
                                    />
                                </div>
                                <div className="field box_field_description">
                                    <TextareaCustom 
                                        name={data?.description?.ref}
                                        label={data?.description?.label}
                                        placeholder={data?.description?.placeholder}
                                        onChange={handleFormElementChange}
                                        onKeyDown={null}
                                        className={`field field_${data?.description?.ref}${data?.description?.error ? " error" : ""}`}
                                        type="text"
                                        value={data?.description?.value}
                                        help={""}
                                        isRequired={data?.description?.required}
                                        styles={ formStyle }
                                        label_icon={<HeadlineIcon />}
                                        tooltip_content={data?.description?.tooltip}
                                        haveError={data?.description?.error}
                                    />
                                </div>

                                <ActionBox 
                                    className="action_box_segmentation"
                                    icon={<CalendarMonthIcon />}
                                    title={"Vigencia de la encuesta *"}
                                    actionButtonText={"Editar Vigencia"}
                                    helpText={`Vigencia establecida del ${(startDate && endDate) ? dayjs(startDate).format('YYYY-MM-DD HH:mm') + ' al ' + dayjs(endDate).format('YYYY-MM-DD HH:mm') : '...'}`}
                                    onClick={handleVigencyRange}
                                    error={vigencyDateError}
                                /> 
                                {action === "create" ? 
                                <SelectCustom 
                                    name={"question_quantity_toload"}
                                    label={"Cantidad de preguntas a cargar"}
                                    placeholder={"Seleccionar número de preguntas a cargar ..."}
                                    onChange={handleFormElementChange}
                                    className={`field field_question_quantity_toload${(questionQuantity !== null && questionQuantity.length === 0) ? " error" : ""}`}
                                    type="text"
                                    value={questionQuantity}
                                    help={""}
                                    isRequired={true}
                                    disabled={false}
                                    styles={ formStyle }
                                    label_icon={<CountIcon />}
                                    tooltip_content={""}
                                    haveError={(questionQuantity !== null && questionQuantity.length === 0) ? true : false}
                                    data={questionQuantityToload}
                                /> : ""}

                            </div>
                        </div>



                        <div className='evaluation_questions'>

                            {questionsList.map((item, index) => {
                                let order = index;
                                return(
                                    <div key={uuid()} className={`question_item ${questionSelectedOrder === order ? " selected" : ""}`}>
                                        <div className="question_bullet">
                                            <div className="order_number">{ order + 1 }</div>
                                        </div>
                                        {index === 0 || index % (questionsList.length-1) !== 0 ?
                                            index !== (questionsList.length-1) ?
                                            <div className="line"></div>
                                            : "" : ""
                                        }
                                    </div>
                                )
                            })}
                            
                        </div>

                        {questionsList.length ?
                            <SelectCustom 
                                name={"input_type"}
                                label={"Tipo de pregunta"}
                                placeholder={"Seleccionar tipo de pregunta ..."}
                                onChange={handleFormElementChange}
                                className={`field field_input_type${inputTypeError ? " error" : ""}`}
                                type="text"
                                value={questionsList[questionSelectedOrder].input_type.value}
                                help=""
                                isRequired={true}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={inputTypeError}
                                data={evaluationTypes}
                            /> : ""
                        }

                        {questionsList[questionSelectedOrder]?.input_type.value ?
                            <Fragment>
                            <div className="question_type_container">
                                <div className="title">
                                    <div className="icon">
                                        <EditIcon />
                                    </div>
                                    <div className="text">
                                        Editar pregunta {questionSelectedOrder+1}
                                    </div>
                                </div>
                                
                                {questionsList[questionSelectedOrder]?.input_type.value === "single_choice" &&
                                    <MultipleChoice 
                                        data={null}    
                                        questionSelected={questionSelected}
                                        setQuestionSelected={setQuestionSelected}
                                        questionSelectedOrder={questionSelectedOrder}
                                        contentByLang={null} 
                                        setContentByLang={null}
                                        lang={null}
                                        questions={null} 
                                        imageReference={false}
                                        response_multiple={false}
                                        blockAction={blockAction}
                                        setBlockAction={setBlockAction}
                                        scope={"survey"}
                                        disabled={action === "edit" ? true : false}
                                    />
                                }

                                {questionsList[questionSelectedOrder]?.input_type.value === "comments" &&
                                    <OpenAnswer 
                                        data={null}
                                        questionSelected={questionSelected}
                                        setQuestionSelected={setQuestionSelected}
                                        questionSelectedOrder={questionSelectedOrder}
                                        contentByLang={contentByLang} 
                                        setContentByLang={setContentByLang}
                                        lang={lang}
                                        //data={[dataObjectOpenAnswer()]}
                                        questions={null} 
                                        blockAction={blockAction}
                                        setBlockAction={setBlockAction}
                                        scope={"survey"}
                                        disabled={action === "edit" ? true : false}
                                    />
                                }

                            </div> 

                            
                            </Fragment>

                        : ""
                        }
                        {questionsList.length ?
                            <div className='actions'>
                                <ButtonCustom 
                                    className="bt_prev_question"
                                    text={"Pregunta Anterior"}
                                    onClick={() => !blockAction && handlePrev()}
                                    style_type="normal"
                                    
                                    icon={null}
                                    background={{ normal: panColors.primary, hover: panColors.white }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                    disabled={!blockAction ? (questionSelectedOrder === 0 ? true : false) : true}
                                />
                                {!loadingForm ?
                                    questionSelectedOrder === (questionsList.length - 1) ?
                                        //action !== "edit" &&
                                        <ButtonCustom 
                                            className="bt_next_question"
                                            text={"Guardar Encuesta"}
                                            onClick={() => !blockAction && handleNext(true)}
                                            //onClick={() => handleNext(true)}
                                            style_type="normal"
                                            
                                            icon={null}
                                            background={{ normal: panColors.primary, hover: panColors.white }}
                                            border={{ normal: panColors.primary, hover: panColors.primary }}
                                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                            disabled={!blockAction ?  questionsList[questionSelectedOrder]?.input_type?.value ? false : true  : blockAction}
                                        />

                                        :
                                        <ButtonCustom 
                                            className="bt_next_question"
                                            text={"Pregunta Siguiente"}
                                            onClick={() => !blockAction && handleNext()}
                                            style_type="normal"
                                            
                                            icon={null}
                                            background={{ normal: panColors.primary, hover: panColors.white }}
                                            border={{ normal: panColors.primary, hover: panColors.primary }}
                                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                            disabled={!blockAction ?  questionsList[questionSelectedOrder]?.input_type?.value ? false : true  : blockAction}
                                        />
                                    :
                                    <div className='loader'>
                                        <LoaderComponent size={25} color={panColors.white} />
                                    </div>
                                }


                            </div>
                        :""}
                    </div> : "CARGANDO"}

                </Fragment>
            : 
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={"Se ha guardado los datos de la encuesta con éxito."}
                    resultSuccessText={`Ahora se puede encontrar esta encuesta en la lista de encuestas ${vigencySuccessText}.`}
                    buttonBackToListText={"Volver a listado de encuestas"}
                    buttonCreateNewText={"Crear encuesta nuevo"}
                    buttonCreateContentText={null}
                    buttonCreateEvaluationText={null}
                    buttonBackToListStyle={"invert"}
                    buttonCreateNewStyle={"invert"}
                    buttonCreateContentStyle={"normal"}
                    createContent={null}
                    createEvaluation={null}
                    createNew={"/survey/create"}
                    backTo={"/survey"}
                    setSuccess={setSuccess}
                    type={"create"}
                />
            }
            
        </WrapperStyled>
    );
}

export default React.memo(Form);