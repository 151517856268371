import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext } from '../../../context';
import LabelCustom from '../../../components/elements/LabelCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';

import { ScheduleCreateIcon } from '../../../helpers/icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 700px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 75px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
            }
            p.description{
                margin: 20px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                    .MuiOutlinedInput-notchedOutline{
                        border-color: rgba(0, 0, 0, 0.23);
                    }
                    &.error{
                        .MuiOutlinedInput-notchedOutline{
                            border-color: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                }
            }
        }
        .actions_dialog{
            width: 300px;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
    }
`

const ScheduleCreate = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { date,
            setDate,
            open, 
            setOpen,
            handleSubmit } = props;
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [ dateError, setDateError ] = useState(false);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormElementChange = () => {

    }

    const handleSubmitDate = () => {
        setDateError(false);
        if(!dayjs(date).isValid()){
            setDateError(true);
            return null;
        }
        handleSubmit();
    }

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="xl"
                pancolors={panColors}
            >
                <div className="inner_dialog">
                
                    
                    <div className="header_dialog">
                        <div className="icon">
                            <ScheduleCreateIcon />
                        </div>
                        <p className="title">Programar activación</p>
                        <p className="description">Planificar la desactivación y reactivación por licencia o vacaciones del usuario en una fechar determinada</p>
                    </div>

                    <div className='content_dialog'>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="field">
                            <LabelCustom 
                                name="label_activate_at"
                                label="Indicar fecha de activación"
                                onChange={handleFormElementChange}
                                className="field field_activate_at"
                                styles={ formStyle }
                                label_icon={null}
                            />
                            <DateTimePicker
                                ampm={false}
                                className={`datetime_activate_at ${dateError ? " error" : ""}`}
                                disableFuture={false}
                                disablePast={true}
                                value={dayjs(date)}
                                onChange={(newValue) => setDate(newValue)}
                            />
                        </div>
                        </LocalizationProvider>

                    </div>

                    <div className="actions_dialog">
                        <ButtonCustom 
                            className="bt_submit"
                            text="Confirmar"
                            onClick={handleSubmitDate}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        <ButtonCustom 
                            className="bt_cancel"
                            text="Cancelar"
                            onClick={() => setOpen(false)}
                            style_type="invert"

                            icon={null}
                            background={{ normal: panColors.white, hover: panColors.primary }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        />
                    </div>
                </div>
            </DialogStyled>
    );
}


export default ScheduleCreate;