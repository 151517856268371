import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ButtonCustom from '../../components/elements/ButtonCustom';
import MessageAlert from '../../components/elements/MessageAlert';

import { AuthContext } from '../../context';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    >.container{
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: #FFFFFF;
        width: 600px;
        box-sizing: border-box;
        padding: 30px;
        .bt_login{
            width: 250px;
            margin-top: 20px;
        }
        >.form{
            width: 100%;
            margin-top: 35px;
            >.field{
                margin-bottom: 15px;
                label{
                    font-size: 16px;   
                    margin-bottom: 10px;    
                }
            }
        }
        >.logo{
            width: 280px;
            img{
                float: left;
                width: 100%;
            }
        }
        >.title{
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            margin-top: 25px;
        }
        
    }

`

const LoginContainer = () => {

    const { signIn } = useContext(AuthContext);

    const [ formData, setFormData ] = useState({username: {value: null, name:"username", required: true, error: false},
                                                password: {value: null, name:"password", required: true, error: false}});

    const [ formError, setFormError ] = useState({status: false, severity: "", message: ""});

    const handleForm = () => {
        
        let haveError = false;

        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {
                if(formData[key].required){
                    if(!formData[key].value){
                        haveError = true;
                        updateFormData[key].error = true;
                    }else{
                        updateFormData[key].error = false;
                    }

                }
            })

            if(haveError){
                setFormError({status: true, severity: "error", message: "Hay un error con los datos ingresados"});
                setFormData(updateFormData);
                return null;
            }else{
                const response = signIn(formData.username.value,
                                        formData.password.value);
                response.then(res => {
                    if(res !== undefined){
                        if(!res.status){
                            setFormError({status: true, severity: "error", message: "Usuario y/o contraseña incorrectos"});
                        }
                    }
                })
            }
        }

    }

    const handleFormElementChange = (e, value) => {
        let updateFormData = {...formData};
        updateFormData[e.target.name].error = false;
        updateFormData[e.target.name].value = e.target.value.length ? e.target.value : null;
        setFormData(updateFormData);
        
        return null;
    }

    

    return (
        <Wrapper>
            <MessageAlert 
                formError={formError}
                setFormError={setFormError}
            />

            <div className="container">

                <div className="logo">
                    <img src="images/logo_panel.svg" />
                </div>

                <div className='title'>
                    Login
                </div>

                <div className="form">
                
                    <div className={`field${formData.username.error ? " error" : ""}`}>
                        <label htmlFor={formData.username.name}>{formData.username.required ? "*" : ""}Usuario:</label>
                        <input 
                            type="text" 
                            onChange={handleFormElementChange} 
                            name={formData.username.name} 
                            id={formData.username.name}
                        />
                    </div>

                    <div className={`field${formData.password.error ? " error" : ""}`}>
                        <label htmlFor={formData.password.name}>{formData.username.required ? "*" : ""}Contraseña:</label>
                        <input 
                            type="password" 
                            onChange={handleFormElementChange} 
                            name={formData.password.name} 
                            id={formData.password.name} 
                        />
                    </div>

                </div>

                <ButtonCustom 
                    className="bt_login"
                    text="Iniciar Sesión"
                    onClick={handleForm}
                    style_type="invert"

                    icon={null}
                    background={{ normal: "103,97,234", hover: "255, 255, 255" }}
                    border={{ normal: "103, 97, 234", hover: "103, 97, 234" }}
                    textcolor={{ normal: "255, 255, 255", hover: "103, 97, 234" }}
                />
                
            </div>

        </Wrapper>

    )

}

export default LoginContainer;
