import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const WrapperStyled = styled.div`
    display: flex;
    svg{
        display: flex;
        color: ${props => `rgb(${props.color})`};
    }
`

export default function LoaderComponent(props) {

    const { className,
            size = 40,
            color = "103, 97, 234" } = props;
    
    return (
        <WrapperStyled 
            color={color}
            className={className}
        >
            <CircularProgress size={size} />
        </WrapperStyled>
    )
}