import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ContentContext,
         ConfigurationContext } from '../../context';
    
import TableCourses from './../elements/DataTable/TableCourses';


const Wrapper = styled.div`
`

const UsersTable = () => {

    const { getUsers,
            users, 
            setUsers } = useContext(ContentContext);

    const { colors } = useContext(ConfigurationContext);

    const headCells = [
        {
            id: 'image',
            numeric: false,
            disablePadding: false,
            label: 'Imágen',
            align: 'left',
        },
        {
            id: 'course',
            numeric: false,
            disablePadding: false,
            label: 'Curso',
            align: 'left',
        },
        {
            id: 'module',
            numeric: false,
            disablePadding: false,
            label: 'Módulo',
            align: 'left',
        },
        {
            id: 'date',
            numeric: true,
            disablePadding: false,
            label: 'Fecha de Interacción',
            align: 'left',
        },
        {
            id: 'qualification',
            numeric: false,
            disablePadding: false,
            label: 'Calificación',
            align: 'left',
        }
    ];

    const courses = {
        total: 200,
        courses: [
            {
                id: 1,
                image: 'https://picsum.photos/300/300',
                course: 'Nombre del posteo,Hasta 3 lineas de texto',
                module: 'Nombre del módulo, Hasta 2 lineas',
                date: '14:32 - 12/11/2022',
                qualification: 10
            },
            {
                id: 2,
                image: 'https://picsum.photos/300/300',
                course: 'Nombre del posteo,Hasta 3 lineas de texto',
                module: 'Nombre del módulo, Hasta 2 lineas',
                date: '14:32 - 12/11/2022',
                qualification: 30
            },
            {
                id: 3,
                image: 'https://picsum.photos/300/300',
                course: 'Nombre del posteo,Hasta 3 lineas de texto',
                module: 'Nombre del módulo, Hasta 2 lineas',
                date: '14:32 - 12/11/2022',
                qualification: 40
            },
            {
                id: 4,
                image: 'https://picsum.photos/300/300',
                course: 'Nombre del posteo,Hasta 3 lineas de texto',
                module: 'Nombre del módulo, Hasta 2 lineas',
                date: '14:32 - 12/11/2022',
                qualification: 70
            },
            {
                id: 5,
                image: 'https://picsum.photos/300/300',
                course: 'Nombre del posteo,Hasta 3 lineas de texto',
                module: 'Nombre del módulo, Hasta 2 lineas',
                date: '14:32 - 12/11/2022',
                qualification: 90
            }
        ]
    };

  

    useEffect(() => {
        
        if(users === null){
            getUsers(0, 25);
        }

    }, [users])

    useEffect(() => {
        return () => {
            setUsers(null);
        }
    }, [])

    if(users === null){
        return null;
    }

    return (
        <Wrapper {...colors}>
        
        {users &&
            <TableCourses
                headCells={headCells} 
                type={"users"}
                title={""} 
                data={courses.courses} 
                getData={getUsers}
                totalRows={courses.total}
            />
        }
        
        </Wrapper>
    );
}

export default UsersTable;