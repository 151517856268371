import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';

import { DeleteElementIcon,
         DeactiveStoreIcon } from '../../../helpers/icons';

                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 400px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 75px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            p.aclaration{
                margin: 10px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
    }
`

const ActivateElement = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { statusUser,
            statusStores } = useContext(ContentContext);
            
    const { id,
            open, 
            setOpen,
            title,
            scope,
            setIsDeactivated } = props;
    
    const [ isLoading, setIsLoading ] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitToDeactivate = () => {
        let payload = {};
        let response;

        switch (scope) {
            case "users":
                payload = {
                    users_id: id,
                    status: -2
                };
                setIsLoading(true);
                response = statusUser(payload);
                response.then((res) => {
                    setIsDeactivated(true);
                })
                
                break;
            case "stores":
                payload = {
                    stores_id: id,
                    status: -2
                };
                setIsLoading(true);
                response = statusStores(payload);
                response.then((res) => {
                    setIsDeactivated(true);
                })
                
                break;
        
            default:
                break;
        }
    }

    useEffect(() => {
      return () => {
        if(!open){
            setIsLoading(false);
        }
      }
    }, [open])
    

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="xs"
                pancolors={panColors}
            >
                <div className="inner_dialog">
                
                    
                    <div className="header_dialog">
                        <div className="icon">
                            {scope == "stores" && 
                                <DeactiveStoreIcon />
                            }
                            {scope == "users" && 
                                <DeleteElementIcon />
                            }
                        </div>
                        <p className="title" dangerouslySetInnerHTML={{__html:title}}></p>
                    </div>

                    {isLoading ? 
                        <div className='loader'>
                            <LoaderComponent />
                        </div>
                    :
                        <div className="actions_dialog">
                            <ButtonCustom 
                                className="bt_submit"
                                text={`Licenciar`}
                                onClick={handleSubmitToDeactivate}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.red, hover: panColors.white }}
                                border={{ normal: panColors.red, hover: panColors.red }}
                                textcolor={{ normal: panColors.white, hover: panColors.red }}
                            />
                            <ButtonCustom 
                                className="bt_cancel"
                                text="Cancelar"
                                onClick={() => setOpen(false)}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                            />
                        </div>
                    }
                </div>
            </DialogStyled>
    );
}


export default ActivateElement;