import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { ConfigurationContext, ContentContext } from '../../../context';

import { useNavigate } from 'react-router-dom';

import { HiMagnifyingGlass } from "react-icons/hi2";

import PostsCommentsTable from '../../../components/Posts/PostsCommentsTable';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import InputCustom from '../../../components/elements/InputCustom';
import SelectCustom from '../../../components/elements/SelectCustom';

import { validateKeywords } from '../../../helpers/functions';

const WrapperStyled = styled.div`
    width: 100%;

  >.actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .bt_create_user{

    }
    .bt_export_user{
        margin-left: 10px;
    }
    .bt_go_to_benefits_admin{
        margin-left: 10px;
    }
  }
  >.filter_actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;//space-between;
    margin-top: 0;
    width: 100%;
    .field_keywords{
        width: calc(100% - 770px);
        margin-right: 15px;
    }
    .filter_option{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        //width: 33vw;
        .label_title{
            font-size: 15px;
            //margin-right: 0;
            white-space: nowrap;
            margin-right: 15px;
            &.order_by{
                //margin-left: 15px;
            }
        }
        .field_posts{
            width: 250px;
            margin-right: 15px;
            //width: 12vw;
            //margin-left: 15px;
        }
        .field_status{
            //width: 8vw;
            //margin-left: 15px;
        }
        .field_benefits{
            //width: 14vw;
            //margin-left: 15px;
        }
        .field_order_by{
            width: 200px;
            //width: 11vw;
            margin-right: 15px;
        }
    }
    .bt_filter{
        //margin-left: 15px;
        align-self: flex-end;
    }
  }
  >.content{
    margin-top: 20px;
  }
`

const CampaignsContainer = () => {

    const navigate = useNavigate();

    const { panColors } = useContext(ConfigurationContext);
    const { basicData,
            getFilterData } = useContext(ContentContext);
    
    const [ posts, setPosts ] = useState(null);
    const [ filters, setFilters ] = useState({date_order: -1, text_filter: "", post_id: ""});
    const [ dateOrder, setDateOrder ] = useState("-1");
    const [ status, setStatus ] = useState("all");
    const [ postId, setPostId ] = useState("");
    
    const [ orderBy, setOrderBy ] = useState("date|-1");
    const [ keywords, setKeywords ] = useState({ value: "", 
                                                 required: false, 
                                                 disabled: false, 
                                                 ref: "keywords", 
                                                 label: "Palabra / Frase Clave", 
                                                 placeholder: "Buscar....",
                                                 help: "",
                                                 error: false})

    const [ filtersError, setFiltersError ] = useState(false);

    const statusList = [{_id: "all", name: "Ver Todos"},
                        {_id: "0", name: "Pendiente"}, 
                        {_id: "1", name: "Aceptado"},
                        {_id: "2", name: "Entregado"},
                        {_id: "-1", name: "Cancelado"}]; 

    const alphabeticalDateOrderList = [{_id: "date|-1", name: "Fecha más reciente"}, 
                                       {_id: "date|1", name: "Fecha más antigua"}];    
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const statusRef = useRef(status);
    const keywordsRef = useRef(keywords);
    
    

    const handleCreate = () => {
        navigate(`/benefits/create`);
    }

    const handleFormElementChange = (name, value) => {

        let valid = true;

        switch (name) {
           
            case "keywords":

                valid = validateKeywords(value);
                let updateKeywords = {...keywords};
                if(!valid){
                    setFiltersError(true);
                    updateKeywords.error = true;
                }else{
                    setFiltersError(false);
                    updateKeywords.error = false;
                }
                updateKeywords.value = value;
                setKeywords(updateKeywords);
                keywordsRef.current = value;
                
                break;
            case "posts":
                setPostId(value);
                break;
            case "status":
                setStatus(value);
                statusRef.current = value;
                break;
            case "order_by":
                setOrderBy(value);
                const orderBy_splitted = value.split("|");
                if(orderBy_splitted[0] === "date"){
                    setDateOrder(orderBy_splitted[1]);
                }
                break;
            
                
            default:
                break;
            
        }
    }

    const handleKeyDown = (e) => {
        
        const update_filters = {...filters};
        update_filters.post_id = postId;
        update_filters.date_order = dateOrder;
        update_filters.text_filter = keywords.value;
        //return null;
        setFilters(update_filters);
        
    }
    /*
    useEffect(() => {
      
        if(basicData){
            const update_filters = {...filters};
            update_filters.post_id = postId;
            update_filters.date_order = dateOrder;
            update_filters.text_filter = keywords.value;
            setFilters(update_filters);
        }
    
      return () => {}
    }, [basicData])
    */
    useEffect(() => {

        const result = getFilterData("comments");
        result.then(res => {
            let posts_arr = [];
            res.posts.map((post, index) => {
                posts_arr.push({_id: post._id, name: post.title});
            })
            setPosts(posts_arr);
            //setPostId(res.posts[0]._id);
            /*
            const update_filters = {...filters};
            update_filters.status = status;
            update_filters.post_id = postId;//res.posts[0]._id;
            update_filters.date_order = dateOrder;
            update_filters.text_filter = keywords.value;
            setFilters(update_filters);
            */
        })
    
      return () => {}
    }, [])
    
    
    if(!filters.post_id){
        //return null;
    }
    
    return (
        <WrapperStyled>

            <div className="filter_actions">

                <InputCustom 
                    name={keywords.ref}
                    label={""}//keywords.label
                    placeholder={keywords?.placeholder}
                    onChange={handleFormElementChange}
                    onKeyDown={null}
                    onKeyUp={null}
                    className={`field field_${keywords.ref}${keywords.error ? " error" : ""}`}
                    type="text"
                    value={keywords.value}
                    help={keywords.help}
                    isRequired={keywords.required}
                    styles={
                        {background_color: `rgba(${panColors.light_white}, 1)`,
                         border_color: `rgba(${panColors.light_white}, 1)`}
                    }
                    icon={<HiMagnifyingGlass />}
                    tooltip_content={keywords.tooltip}
                    haveError={keywords.error}
                />

                <div className="filter_option"> 

                    <div className="label_title order_by">
                        Filtrar por:
                    </div>

                    <SelectCustom 
                        name={"posts"}
                        label={""}//Estado
                        placeholder={"Seleccionar post..."}
                        onChange={handleFormElementChange}
                        className={`field field_posts`}
                        type="text"
                        value={postId}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={posts}
                    />
                  
                    <div className="label_title order_by">
                        Ordenar por:
                    </div>
                    <SelectCustom 
                        name={"order_by"}
                        label={""}
                        placeholder={null}
                        onChange={handleFormElementChange}
                        className={`field field_order_by`}
                        type="text"
                        value={orderBy}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={alphabeticalDateOrderList}
                    />

                </div>
                <ButtonCustom 
                    className="bt_filter"
                    text="Filtrar"
                    onClick={handleKeyDown}
                    style_type="normal"

                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

            </div>

            <div className="content">
                <PostsCommentsTable filters={filters} /> 
            </div>
        
        </WrapperStyled>
    );
}

export default CampaignsContainer;