import React, { useContext } from 'react';
import styled from 'styled-components';
import LabelCustom from '../elements/LabelCustom';
import { ConfigurationContext } from '../../context';

const WrapperStyled = styled.div`
    margin-bottom: 5px;
    >.inner{
        position: relative;
        display: flex;
        flex-direction: ${props => props.label_position == "left" ? "row" : "row-reverse"}!important;
        justify-content: ${props => props.label_position == "left" ? "flex-start" : "flex-end"}!important;
        align-items: center;
        input{
            position: relative;
            background-color: ${props => props.styles.background_color};
            border: 1px solid ${props => props.styles.border_color};
            border-radius: 6px;
            padding-left: ${props => props.icon ? "40px" : "10px" };
            padding-right: ${props => props.icon ? "40px" : "10px" };
            padding-top: 11px;
            padding-bottom: 11px;
            color: #484D63;
            font-size: 16px;
            width: 18px;
            height: 18px;
        }
        label{
            display: flex;
            align-items: center;
            margin-left: ${props => props.label_position == "left" ? "0" : "10px"};
            margin-right: ${props => props.label_position == "right" ? "0" : "10px"};
        }
    }
    >.help{
        font-size: 14px;
        margin-top: 5px;
        font-weight: 700;
        color: ${props => `rgb(${props.panColors.dark_gray})`};
        &.error{
            color: ${props => `rgb(${props.panColors.red})`};
        }
    }
    &.error{
        input{
            outline: 2px solid ${props => `rgb(${props.panColors.red})`};
            border: 2px solid ${props => `rgb(${props.panColors.red})`};
            :focus{
                outline: 2px solid ${props => `rgb(${props.panColors.red})`};
                border: 2px solid ${props => `rgb(${props.panColors.red})`};
            }
        }
    }
`

const InputCheckboxCustom = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { name,
            label,
            onChange,
            className,
            label_icon,
            value,
            help,
            styles = null,
            disabled = false,
            isRequired = false,
            tooltip_content = null,
            haveError = null,
            labelPosition = "left",
            index = null } = props;
    
    return (
        <WrapperStyled 
            panColors={panColors}
            styles={styles}
            className={className} 
            label_position={labelPosition}
            haveError={haveError}
        >

            <div className="inner">
                { label ? 
                    
                    <LabelCustom 
                        name={name}
                        label={label}
                        styles={styles}
                        label_icon={label_icon}
                        isRequired={isRequired}
                        tooltip_content={tooltip_content}
                    />
                : "" }
                <input 
                    type="checkbox" 
                    checked={value ? true : false}
                    name={name} 
                    id={name} 
                    onChange={(e) => onChange ? onChange(name, e.target.checked, index) : null}
                    disabled={disabled}
                />
            </div>

            { help ? <div className={`help${haveError ? " error" : ""}`}>{help}</div> : "" }

        </WrapperStyled>

    )

}

export default InputCheckboxCustom;