import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

`


const DashboardContainer = (props) => {
    

    

    return (
        <Wrapper className="dashboard">


            




1Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>
Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>
Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>
Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>Dashboard<br/><br/>

        </Wrapper>

    )

}

export default DashboardContainer;
