import React from "react";
import SurveyContainer from "./SurveyContainer";

const Survey = (props) => {
  return (
      <SurveyContainer {...props} />
  );
};

export default Survey;
