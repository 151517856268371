import { Fragment, useContext, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import './App.css';

import { AuthContext,
         ConfigurationContext, 
         ContentProvider } from './context';
         
import OverlayWaiting from './components/Overlays/Waiting';
import Login from './pages/Login';
import ProtectedRoutes from './ProtectedRoutes';
import MessageAlertHttp from './components/elements/MessageAlertHttp';
import Layout from './components/Layout';

function App() {

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { haveResponseError,
          setHaveResponseError,
          haveResponseErrorMessage,
          setHaveResponseErrorMessage,
          getPanelConfiguration,
          clientData,
          setClientData } = useContext(ConfigurationContext);

  const { isAuth,
          isAuthLoading,
          signOut } = useContext(AuthContext);
  
  useEffect(() => {
    
    if(isAuth !== null){
      if(isAuth === false){
        navigate("/");
      }else{
         getPanelConfiguration();
        
      }
    }
  
    return () => {}
  }, [isAuth])

  useEffect(() => {
    
    if(clientData){
      
      if(clientData.status === -1){
        setHaveResponseError(true);
        setHaveResponseErrorMessage("Acceso denegado");
        setClientData(null);
        signOut();
      }

      if(clientData.status === 1){
        if(pathname === "/"){
          navigate("/country")
        }
      }
      
    }

    return () => {}
  }, [clientData])
  
  useEffect(() => {
    
    if(pathname === "/logout"){
      signOut();
    }
  
    return () => {}
  }, [pathname])
  
  
 

  return (
    <Fragment>
      
      {isAuthLoading && <OverlayWaiting />}

      {
        (!isAuthLoading && clientData != null && clientData.status !== -1 && isAuth) ?
          
            <ContentProvider>
              <Layout>
                <ProtectedRoutes />
              </Layout>  
            </ContentProvider>
          
        :
          <Routes>
            <Route path="/" element={<Login />} exact />
          </Routes>
      }
      <MessageAlertHttp 
        haveResponseError={haveResponseError} 
        setHaveResponseError={setHaveResponseError}
        haveResponseErrorMessage={haveResponseErrorMessage} 
        setHaveResponseErrorMessage={setHaveResponseErrorMessage}
      />
    </Fragment>

    
  );
}

export default App;
