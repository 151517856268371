import React from "react";
import CampaignsContainer from "./CampaignsContainer";

const Campaigns = (props) => {
  return (
      <CampaignsContainer {...props} />
  );
};

export default Campaigns;
