import React from "react";
import UsersContainer from "./UsersContainer";

const Users = (props) => {
  return (
      <UsersContainer {...props} />
  );
};

export default Users;
