import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ContentContext,
         ConfigurationContext } from '../../context';
    
import TableDevices from './../elements/DataTable/TableDevices';


const Wrapper = styled.div`
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.fontColorError.normal})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.fontColorError.hover})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.buttonIconColor.normal})`};
      :hover{
        color: ${props => `rgb(${props.buttonIconColor.hover})`};
      }
    }
  }
`

const Devices = () => {

    const { getUserDevices,
            devices, 
            setDevices } = useContext(ContentContext);

    const { colors } = useContext(ConfigurationContext);

    const headCells = [
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Title',
            align: 'left',
        },
        {
            id: 'reactions',
            numeric: false,
            disablePadding: false,
            label: 'Price',
            align: 'left',
        },
        {
            id: 'body',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            align: 'left',
        }
    ];

  

    useEffect(() => {
        
        if(devices === null){
            getUserDevices(0, 5);
        }

    }, [devices])

    useEffect(() => {
        return () => {
            setDevices(null);
        }
    }, [])

    if(devices === null){
        return null;
    }

    return (
        <Wrapper {...colors}>
        
        {devices &&
            <TableDevices 
                headCells={headCells} 
                type={"devices"}
                title={"Datos de dispositivos"} 
                data={devices.posts} 
                getData={getUserDevices}
                totalRows={devices.total}
            />
        }
        
        </Wrapper>
    );
}

export default Devices;